import './index.css'
import { useContext, useEffect, useState } from 'react'
import http from 'CommonJS/http'
import { toast } from 'Components/Toast/toast'
import Loading from 'Components/Loading'
import { DataItem, IconBgMap, IconMap, valueType, HomeCollege } from './type'
import { ReactComponent as CompleteApplication } from 'assets/images/complete-application.svg'
import { ReactComponent as TotalApplication } from 'assets/images/total-application.svg'
import { Link } from 'react-router-dom'
import { Autocomplete, createFilterOptions, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { AppContext } from 'Context/AppContext'
import { AppContextType } from 'Context/AppTypes'
import { isStudyAbroadAdvisor } from 'CommonJS/roleHelper'
import { UserContext } from 'Context/UserContext'

export default function VisaStudentDashboard() {
  const { data: loginUser } = useContext(UserContext)
  const [data, setValue] = useState<Partial<valueType>>({
    instituteId: '',
    programId: '',
    sessionId: '',
    sendingInstitution: '',
    yearId: '',
    homeCollegeId: ''
  })

  interface SendingInstitutionOptionsType {
    text?: string
    value?: string
  }

  const [sendingInstitutionOptions, setSendingInstitution] = useState<SendingInstitutionOptionsType[]>([])
  const [visaStats, setData] = useState<any>([])
  const [homeCollege, setHomeCollege] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [filterQueryParams, setFilterQueryParams] = useState('')

  const iconBgMap: IconBgMap = {
    md_settings_applications: 'bg-blue-600/15',
    go_shield_check: 'bg-green-600/15'
  }
  const iconMap: IconMap = {
    md_settings_applications: <TotalApplication className='fill-blue-600 w-14' />,
    go_shield_check: <CompleteApplication className='fill-green-600 w-14' />
  }

  const {
    institutes,
    sessions,
    programs,
    years,
    homeColleges,
    visaPortalSetting,
    getInstitute,
    getPrograms,
    getSession,
    getYears,
    getHomeCollege,
    getVisaPortalSetting
  } = useContext(AppContext) as AppContextType

  useEffect(() => {
    const init = async () => {
      await setLoading(true)
      if (Object.keys(visaPortalSetting ?? {})?.length === 0) {
        getVisaPortalSetting()
      }
      if (institutes?.length === 0) getInstitute()
      if (programs?.length === 0) getPrograms()
      if (sessions?.length === 0) getSession()
      if (years?.length === 0) getYears()
      if (!isStudyAbroadAdvisor(loginUser.roles) && homeColleges?.length === 0) getHomeCollege()
      await setLoading(false)

      setVisaColorTheme()
    }
    init()
  }, [])

  useEffect(() => {
    setVisaColorTheme()
  }, [visaPortalSetting])

  function setVisaColorTheme() {
    if (visaPortalSetting) {
      document.documentElement.style.setProperty('--theme-color', visaPortalSetting.themeColor)
      document.documentElement.style.setProperty('--font-color', visaPortalSetting.fontColor)
      document.documentElement.style.setProperty('--menu-color', '#F5F5DC')
    }
  }

  useEffect(() => {
    const init = async () => {
      let filterableData = {}
      Object.keys(data).forEach((i) => {
        const val = (data as Array<string>)[i as any]
        if (val) {
          filterableData = {
            ...(filterableData as any),
            [i]: val
          }
        }
        return val ? { [i]: val } : null
      })
      const newQueryParams = new URLSearchParams(filterableData as any)
      setFilterQueryParams(newQueryParams.toString())
      await getVisaDashboard()
    }
    init()
  }, [data])

  const getVisaDashboard = () => {
    const paramsStringObject: Record<string, string> = {}
    if (data) {
      setLoading(true)
      Object.entries(data).forEach(([key, value]) => {
        if (value !== undefined && value !== null && value !== '') {
          paramsStringObject[key] = String(value)
        }
      })
    }
    http
      .fetch({ path: 'visaStats', options: { params: paramsStringObject } })
      .catch(() => {
        toast('Internal server error.', 'error')
        setLoading(false)
      })
      .then((response) => {
        setLoading(false)
        if (response && response.status === 200) {
          const rspData = response.data.data
          if (Object.keys(rspData).includes('sendingInstitutionOptions') && rspData?.sendingInstitutionOptions?.length > 0) {
            setSendingInstitution(rspData.sendingInstitutionOptions)
          }
          delete rspData?.sendingInstitutionOptions
          setHomeCollege(Object.values(rspData.homeCollege))
          setData(Object.values(rspData.cards))
        }
      })
  }

  const getItem = (item: DataItem): any => {
    const filterUrl = '/admin/visa-student'
    let filterSearchStr = ''
    if (item.filter) {
      filterSearchStr += `?${item.filter.key}=${item.filter.value}${filterQueryParams ? '&' + filterQueryParams : ''}`
    } else {
      filterSearchStr += `${item.visaPortalStatus ? '?visa_portal_status=' + item.visaPortalStatus + '&' + filterQueryParams : '?' + filterQueryParams}`
    }
    const newQueryParams = new URLSearchParams(filterSearchStr)

    if (item.instituteId) {
      newQueryParams.set('instituteId', String(item.instituteId))
    }

    return `${filterUrl}?${newQueryParams.toString()}`
  }
  return (
    <div className='px-7 w-full lg:px-4 py-5 relative' data-cy='dashboard-visa-page'>
      <h1 className='text-3xl font-bold mb-7 md:mb-4'>Visa Processing Admin Dashboard</h1>
      {loading && <Loading />}
      <div className='flex flex-wrap gap-4 justify-end mb-5 md:flex-col md:gap-1'>
        <FormControl sx={{ m: 1, minWidth: 80 }} className='!min-w-44'>
          <InputLabel id='visa-dashboard-sending-institution-filter-label'>Sending Institution</InputLabel>
          <Select
            labelId='visa-dashboard-sending-institution-filter-label'
            id='visa-dashboard-sending-institution-filter'
            data-cy='sending-institution'
            value={data?.sendingInstitution}
            onChange={(e) => {
              setValue((prevValue) => ({
                ...prevValue,
                sendingInstitution: e.target.value
              }))
            }}
            autoWidth
            label='Sending Institution'
          >
            <MenuItem value=''>None</MenuItem>
            {sendingInstitutionOptions?.map((option) => (
              <MenuItem key={option?.value} value={option?.value}>
                {option?.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 80 }} className='!min-w-36'>
          <InputLabel id='visa-dashboard-institute-filter-label'>Institutes</InputLabel>
          <Select
            labelId='visa-dashboard-institute-filter-label'
            id='visa-dashboard-institute-filter'
            data-cy='institutes'
            value={data?.instituteId}
            onChange={(e) => {
              setValue((prevValue) => ({
                ...prevValue,
                instituteId: e.target.value
              }))
            }}
            autoWidth
            label='Institutes'
          >
            <MenuItem value=''> None</MenuItem>
            {institutes?.map((institute) => (
              <MenuItem key={institute.id} value={institute.id}>
                {institute.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 80 }} className='!min-w-36'>
          <InputLabel id='visa-dashboard-program-filter-label'>Programs</InputLabel>
          <Select
            labelId='visa-dashboard-program-filter-label'
            id='visa-dashboard-program-filter'
            data-cy='programs'
            value={data?.programId}
            onChange={(e) => {
              setValue((prevValue) => ({
                ...prevValue,
                programId: e.target.value
              }))
            }}
            autoWidth
            label='Programs'
          >
            <MenuItem value=''> None</MenuItem>
            {programs?.map((institute) => (
              <MenuItem key={institute.id} value={institute.id}>
                {institute.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 80 }} className='!min-w-36'>
          <InputLabel id='visa-dashboard-session-filter-label'>Sessions</InputLabel>
          <Select
            labelId='visa-dashboard-session-filter-label'
            id='visa-dashboard-session-filter'
            data-cy='sessions'
            value={data?.sessionId}
            onChange={(e) => {
              setValue((prevValue) => ({
                ...prevValue,
                sessionId: e.target.value
              }))
            }}
            autoWidth
            label='Sessions'
          >
            <MenuItem value=''> None</MenuItem>
            {sessions?.map((institute) => (
              <MenuItem key={institute.id} value={institute.id}>
                {institute.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 80 }} className='!min-w-36'>
          <InputLabel id='visa-dashboard-year-filter-label'>Year</InputLabel>
          <Select
            labelId='visa-dashboard-year-filter-label'
            id='visa-dashboard-year-filter'
            data-cy='years'
            value={data?.yearId}
            onChange={(e) => {
              setValue((prevValue) => ({
                ...prevValue,
                yearId: e.target.value
              }))
            }}
            autoWidth
            label='year'
          >
            <MenuItem value=''>None</MenuItem>
            {years?.map((year) => (
              <MenuItem key={year.id} value={year.id}>
                {year.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {!isStudyAbroadAdvisor(loginUser.roles) && (
          <Autocomplete
            sx={{ m: 1, minWidth: 80 }}
            className='!min-w-60'
            id='visa-dashboard-homecollege-filter'
            data-cy='homecollege'
            options={homeColleges}
            getOptionLabel={(option: any) => option.name || ''}
            value={homeColleges.find((college) => college.id === data?.homeCollegeId) || null}
            disableCloseOnSelect={true}
            onChange={(event, newValue) => {
              setValue((prevValue) => ({
                ...prevValue,
                homeCollegeId: newValue ? newValue.id : ''
              }))
            }}
            filterOptions={createFilterOptions({
              limit: 100,
              trim: true
            })}
            componentsProps={{ popper: { id: 'course-schedule-home-college-popup' } }}
            renderInput={(params) => <TextField {...params} label='Home University' />}
          />
        )}
      </div>
      <div className='grid  grid-cols-2 gap-4 md:grid-cols-1 py-0 px-4 min-h-[calc(100vh-275px)] md:min-w-[auto]'>
        <div className='grid grid-cols-2 lg:grid-cols-2 md:grid-cols-1 gap-7'>
          {visaStats.length === 0 && (
            <>
              {[...Array(4)].map((item, index: number) => (
                <div key={index}>
                  <div className='profile_card animate-pulse text-center'>
                    <div className='icon h-11 w-11 bg-primary/15'></div>
                    <div className='number h-9 w-14 rounded-sm bg-primary/15 mx-auto'></div>
                    <h3 className='title rounded-sm h-8 w-80 bg-primary/15 mx-auto'></h3>
                  </div>
                </div>
              ))}
            </>
          )}
          {visaStats.map((item: DataItem, index: number) => (
            <Link to={getItem(item)} key={index} className='visa_dashboard_card text-center flex flex-col justify-center items-center'>
              {item?.icon?.startsWith('http') ? (
                <img src={item.icon} alt={item.name} className='image object-contain' />
              ) : (
                <div className={`icon ${iconBgMap[item.icon]}`}>{iconMap[item?.icon]}</div>
              )}
              <div className='number'>{item.total}</div>
              <h3 className='title'>{item.name}</h3>
            </Link>
          ))}
        </div>
        <div className='max-h-[calc(100vh-275px)] overflow-y-auto'>
          <table className='min-w-full bg-white border-spacing-0'>
            <thead>
              <tr>
                <th className='w-1/2 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>University</th>
                <th className='w-1/4 px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider'># of Apps</th>
              </tr>
            </thead>
            <tbody className='bg-white divide-y divide-gray-200'>
              {!loading && (!homeCollege || homeCollege.length === 0) && (
                <tr>
                  <td colSpan={2}>No University</td>
                </tr>
              )}
              {homeCollege.map((college: HomeCollege, index: number) => (
                <tr key={index}>
                  <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-900'>{college.name}</td>
                  <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-right'>{college.studentsCount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
