import { AxiosResponse } from 'axios'
import http from 'CommonJS/http'
import { PermissionEnum } from 'CommonJS/PermissionEnum'
import { userCan, userCanAny } from 'CommonJS/PermissionHelper'
import { isSuperAdmin } from 'CommonJS/roleHelper'
import Loading from 'Components/Loading'
import { StudentViewData } from 'Components/StudentView/type'
import { toast } from 'Components/Toast/toast'
import { UserContext } from 'Context/UserContext'
import { useContext, useEffect, useState } from 'react'
import { MdClose } from 'react-icons/md'
import { NavLink, useNavigate } from 'react-router-dom'

interface ViewProps {
  setOpenView: React.Dispatch<React.SetStateAction<boolean>>
  show?: boolean
}
export default function View({ setOpenView, show }: ViewProps) {
  const navigate = useNavigate()
  const { data: user } = useContext(UserContext)
  const [loading, setLoading] = useState<boolean>(true)
  const [globalStudentView, setGlobalStudentView] = useState<StudentViewData[]>([])
  const [studentView, setStudentView] = useState<StudentViewData[]>([])

  useEffect(() => {
    const init = async () => {
      if (studentView?.length === 0) {
        try {
          await http
            .fetch({ path: `student/view?page=no${isSuperAdmin(user.roles) ? '&withGlobal=true' : ''}` })
            .catch(({ response }) => {
              toast(response.data.message ?? 'Internal Server Error.', 'error')
              setLoading(false)
            })
            .then((response: void | AxiosResponse<{ studentViews: StudentViewData[] }>) => {
              setLoading(false)
              if (response && response.status === 200) {
                const studentViews = response.data.studentViews
                setGlobalStudentView(studentViews.filter((sView) => sView.isGlobal))
                setStudentView(studentViews.filter((sView) => !sView.isGlobal))
              }
            })
        } catch (error) {
          setLoading(false)
          toast('An error occurred', 'error')
        }
      }
    }
    if (show === true) {
      init()
    }
  }, [show])

  const handleCreateView = () => {
    http
      .fetch({ method: 'post', path: `student/view` })
      .catch(() => {
        toast('Internal Server Error.', 'error')
      })
      .then((response: any) => {
        if (response && response.status === 200) {
          toast(response?.data?.message || 'Successfully student view created', 'success')
          navigate(0)
        }
      })
  }

  const handleChangeView = (id: string) => {
    http
      .fetch({ method: 'put', path: `student/view/${id}/changeView` })
      .catch(() => {
        toast('Internal Server Error.', 'error')
      })
      .then((response: any) => {
        if (response && response.status === 200) {
          toast(response?.data?.message || 'Successfully student view change', 'success')
          navigate(0)
        }
      })
  }

  const handleCreateGlobalView = () => {
    if (!isSuperAdmin(user.roles)) {
      toast('Your are not authorized to perform this action', 'error')
      return
    }

    http
      .fetch({ method: 'post', path: `/setting/global-view/create?makeActive=${true}` })
      .catch(({ response }) => {
        toast(response?.data?.message || 'Internal server error', 'error')
      })
      .then(async (response: any) => {
        setLoading(false)
        if (response && response.status === 200) {
          toast(response?.data?.message || 'Successfully created new global view', 'success')
          navigate(0)
        }
      })
  }

  function UserViewLinks() {
    return (
      <>
        {userCanAny(user, [PermissionEnum.STUDENT_LIST_MANAGE_VIEW, PermissionEnum.STUDENT_LIST_CREATE_NEW_VIEW]) && (
          <div className='flex gap-2 items-center'>
            {userCan(user, PermissionEnum.STUDENT_LIST_MANAGE_VIEW) && (
              <NavLink
                to='/admin/student/view'
                className='text-secondary cursor-pointer hover:underline underline-offset-2'
                data-cy='student-view-manage'
              >
                Manage
              </NavLink>
            )}
            {userCan(user, PermissionEnum.STUDENT_LIST_CREATE_NEW_VIEW) && (
              <>
                <span className='text-secondary'>{'/'}</span>
                <button
                  className='text-secondary cursor-pointer hover:underline underline-offset-2'
                  onClick={handleCreateView}
                  data-cy='student-create-new'
                >
                  Create New
                </button>
              </>
            )}
          </div>
        )}
      </>
    )
  }

  return (
    <div
      className={`fixed right-0 bg-white p-3 rounded-sm w-1/5 h-[calc(100vh-77px)] lg:h-[calc(100vh-45px)] top-20 shadow-lg md:w-9/12 z-50 transition-all duration-300 ease-in-out lg:w-72 lg:top-12 ${show ? '' : 'translate-x-full'}`}
    >
      {loading && <Loading className='!absolute' />}
      <div className='flex flex-col mb-3 border-b gap-1'>
        <div className='flex justify-between items-center overflow-auto'>
          <h1 className='font-semibold'>Views</h1>
          <MdClose size={'20px'} className=' cursor-pointer' onClick={() => setOpenView(false)} />
        </div>
      </div>
      <div className='overflow-auto h-[calc(100vh-210px)] md:pr-2'>
        <div className='flex flex-col gap-4'>
          {isSuperAdmin(user.roles) && (
            <div className='flex flex-col gap-1.5'>
              <div className='flex gap-2 items-center'>
                <div className='font-bold text-lg'>Global Views</div>
                <div className='flex gap-2 items-center'>
                  <NavLink to='/admin/student/global-view' className='text-secondary cursor-pointer hover:underline underline-offset-2'>
                    Manage
                  </NavLink>
                  <span className='text-secondary'>{'/'}</span>
                  <button className='text-secondary cursor-pointer hover:underline underline-offset-2' onClick={handleCreateGlobalView}>
                    Create New
                  </button>
                </div>
              </div>
              {!globalStudentView || (globalStudentView.length === 0 && <div className='p-1 border-b'>No Global Student View Found</div>)}
              {globalStudentView?.map((item, index) => {
                return (
                  <div key={index} className='p-1 border-b'>
                    <div className='flex gap-4 items-center'>
                      <h1 className='text-base text-gray-600 truncate max-w-64'>{item.title}</h1>
                      {item.userId === user.id && item.isDefault === true ? (
                        <div>Active</div>
                      ) : (
                        <button
                          type='button'
                          className='text-secondary cursor-pointer hover:underline underline-offset-2'
                          onClick={() => handleChangeView(item.id)}
                        >
                          Change
                        </button>
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
          )}
          <div className='flex flex-col gap-1.5'>
            <div className='flex gap-2 items-center'>
              <div className='font-bold text-lg'>Your Views</div>
              <UserViewLinks />
            </div>
            {studentView?.map((item, index) => {
              return (
                <div key={index} className='p-1 border-b'>
                  <div className='flex gap-4 items-center'>
                    <h1 className='text-base text-gray-600 truncate max-w-64'>{item.title}</h1>
                    {item.isDefault === true ? (
                      <div>Active</div>
                    ) : (
                      <button
                        type='button'
                        className='text-secondary cursor-pointer hover:underline underline-offset-2'
                        onClick={() => handleChangeView(item.id)}
                      >
                        Change
                      </button>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
