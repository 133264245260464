import { Autocomplete, Switch, TextField } from '@mui/material'
import http from 'CommonJS/http'
import AdminSetting from 'Components/AdminSetting'
import Loading from 'Components/Loading'
import { useCallback, useEffect, useRef, useState } from 'react'
import { DndProvider, XYCoord, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { BsQuestionCircleFill } from 'react-icons/bs'
import Tooltip from '@mui/material/Tooltip'
import { toast } from 'Components/Toast/toast'
import update from 'immutability-helper'
import { IoMove } from 'react-icons/io5'
import { TableVirtuoso, VirtuosoHandle } from 'react-virtuoso'
import { AxiosResponse } from 'axios'

export interface DragItem {
  type: string
  id: string
  index?: number
}
export default function FieldFilter() {
  interface FieldData {
    id: string
    label: string
    createdAt: string
    fieldName: string
    filterColumnOrder?: number | string
    isFilterable: boolean
    isDefault: boolean
    isDisplayInStudentView: boolean
    isDisplayInVisaDashboardView: boolean
    index?: number | string
  }
  interface FieldDataResponseType {
    fields: FieldData[]
  }
  interface FieldDataUpdateResponseType {
    message: string
    success: boolean
  }

  const [fields, setFields] = useState<FieldData[]>([])
  const [enabledFields, setEnableFields] = useState<FieldData[]>([])
  const [loading, setLoading] = useState(false)
  const listVirtuosoRef = useRef<VirtuosoHandle>(null)
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const [filteredEnabledFields, setFilteredEnabledFields] = useState<FieldData[]>([])
  const [filteredFields, setFilteredFields] = useState<FieldData[]>([])
  const [search, setSearch] = useState<string>('')
  const [searchEnable, setSearchEnable] = useState<string>('')

  useEffect(() => {
    getFieldsData()
  }, [])

  const getFieldsData = async () => {
    await setLoading(true)
    await http
      .fetch({ path: '/setting/fields' })
      .catch(() => {
        toast('Internal Server Error.', 'error')
        setLoading(false)
      })
      .then((response: void | AxiosResponse<FieldDataResponseType>) => {
        setLoading(false)
        if (response && response.status === 200) {
          const fields = response.data.fields.filter(
            (n: FieldData) => n.isDisplayInStudentView == false && n.isDisplayInVisaDashboardView == false
          )
          setFields(fields)
          setFilteredFields(fields)

          const enabledFields = response.data.fields.filter(
            (n: FieldData) => n.isDisplayInStudentView == true || n.isDisplayInVisaDashboardView == true
          )
          setEnableFields(enabledFields)
          setFilteredEnabledFields(enabledFields)

          if (search) filterData(search, false, fields)
          if (searchEnable) filterData(searchEnable, true, enabledFields)
        }
      })
    await setLoading(false)
  }

  const handleChange = (newField: FieldData, refreshTable: boolean = true) => {
    const newData = newField
    http
      .fetch({ method: `put`, path: `/setting/fields/${newField?.id}`, data: newData })
      .catch((error: any) => {
        toast(error.response?.data?.message || 'Internal server error', 'error')
      })
      .then((response: void | AxiosResponse<FieldDataUpdateResponseType>) => {
        setLoading(false)
        if (response && response.status === 200) {
          toast(response?.data?.message || 'Successfully updated data', 'success')
          if (refreshTable) {
            getFieldsData()
          }
        }
      })
  }

  const handleOrderBulkUpdate = () => {
    setLoading(true)
    const newData = enabledFields.map((item) => {
      return {
        id: item.id,
        fieldName: item.fieldName,
        filterColumnOrder: item.filterColumnOrder
      }
    })

    return http
      .fetch({ method: 'put', path: '/setting/fields', data: { fields: newData, page: 'settings' } })
      .catch((error: any) => {
        toast(error.response?.data?.message || 'Internal server error', 'error')
        setLoading(false)
      })
      .then((response: void | AxiosResponse<FieldDataUpdateResponseType>) => {
        setLoading(false)
        if (response && response.status === 200) {
          toast(response?.data?.message || 'Successfully updated data', 'success')
        }
      })
  }

  const TableRowContentTemplate = (field: FieldData) => {
    return (
      <>
        {(field.isDisplayInStudentView === true || field.isDisplayInVisaDashboardView === true) && (
          <td className={`w-3 ${isSearching ? 'cursor-not-allowed' : 'cursor-move'}`}>
            <IoMove />
          </td>
        )}
        <td>
          <input
            type='text'
            className='form-input'
            defaultValue={field?.label}
            placeholder={`Enter ${field?.label}`}
            onBlur={(e) => {
              const updatedField = { ...field, label: e.target.value }
              setLoading(true)
              handleChange(updatedField)
            }}
            name={'label'}
          />
        </td>
        <td className='text-center'>{field?.fieldName ?? '-'}</td>
        <td className='text-center'>
          <Switch
            checked={field?.isFilterable}
            inputProps={{ 'aria-label': 'controlled' }}
            disabled={
              !field.isDisplayInStudentView ||
              !field.isDisplayInVisaDashboardView ||
              field.fieldName === 'profile_image' ||
              field.fieldName === 'action' ||
              field.fieldName === 'assign_advisor'
            }
            onChange={(e) => {
              const updatedField = { ...field, isFilterable: e.target.checked }
              setLoading(true)
              handleChange(updatedField)
            }}
          />
        </td>
        <td className='text-center'>
          <Switch
            checked={field?.isDefault}
            inputProps={{ 'aria-label': 'controlled' }}
            disabled={!field.isDisplayInStudentView}
            onChange={(e) => {
              const updatedField = { ...field, isDefault: e.target.checked }
              delete updatedField.filterColumnOrder
              setLoading(true)
              handleChange(updatedField)
            }}
          />
        </td>
        <td className='text-center'>
          <Switch
            checked={field?.isDisplayInStudentView}
            inputProps={{ 'aria-label': 'controlled' }}
            onChange={(e) => {
              const updatedField = { ...field, isDisplayInStudentView: e.target.checked }
              delete updatedField.filterColumnOrder
              setLoading(true)
              handleChange(updatedField)
            }}
          />
        </td>
        <td className='text-center'>
          <Switch
            checked={field?.isDisplayInVisaDashboardView}
            inputProps={{ 'aria-label': 'controlled' }}
            onChange={(e) => {
              const updatedField = { ...field, isDisplayInVisaDashboardView: e.target.checked }
              delete updatedField.filterColumnOrder
              setLoading(true)
              handleChange(updatedField)
            }}
          />
        </td>
      </>
    )
  }

  const moveCard = useCallback(async (dragIndex: any, hoverIndex: any) => {
    // need to call array setter here
    let newOrderCopy = fields?.filter((n) => n?.isDisplayInStudentView == true || n?.isDisplayInVisaDashboardView == true)
    await setEnableFields((prevCards) => {
      newOrderCopy = update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]]
        ]
      })?.filter((n) => n?.isDisplayInStudentView == true || n?.isDisplayInVisaDashboardView == true)
      return newOrderCopy
    })
    await setFilteredEnabledFields((prevCards) => {
      newOrderCopy = update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]]
        ]
      })?.filter((n) => n?.isDisplayInStudentView == true || n?.isDisplayInVisaDashboardView == true)
      return newOrderCopy
    })

    const min = Math.min(dragIndex, hoverIndex)
    const max = Math.max(dragIndex, hoverIndex)
    for (let i = min; i <= max; i++) {
      try {
        newOrderCopy[i].filterColumnOrder = i
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
    }
    setEnableFields(newOrderCopy)
    setFilteredEnabledFields(newOrderCopy)
  }, [])

  const TableRow = (field: FieldData) => {
    const ref = useRef<HTMLTableRowElement>(null)
    const [{ isDragging }, drag] = useDrag({
      type: 'ROW',
      canDrag: () => !isSearching,
      item: () => {
        return { type: 'ROW', id: field.id, index: field.index }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    })

    const [{ handlerId }, drop] = useDrop({
      accept: 'ROW',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      drop: (droppedItem: { type: string; id: string }) => {
        // `Item with ID ${droppedItem.id} dropped on row with ID ${field.filterColumnOrder}`
        // const filteredObject = fields?.find((item) => item.id == droppedItem.id)
        handleOrderBulkUpdate()
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        handlerId: monitor.getHandlerId()
      }),
      hover(item: any, monitor) {
        if (!ref.current) {
          return
        }

        const dragIndex = item.index
        const hoverIndex = field.index as number

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return
        }

        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect()

        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

        // Determine mouse position
        const clientOffset = monitor.getClientOffset()

        // Get pixels to the top
        const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return
        }

        // Time to actually perform the action
        moveCard(dragIndex, hoverIndex)

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex
      }
    })
    const opacity = isDragging ? 0 : 1
    drag(drop(ref))

    return (
      <tr ref={ref} data-handler-id={handlerId} style={{ zIndex: 100, opacity, cursor: 'grab' }}>
        <TableRowContentTemplate {...field} />
      </tr>
    )
  }

  const filterData = (value: string = '', inEnabledFields = false, fieldList: FieldData[] = []) => {
    if (inEnabledFields) setSearchEnable(value)
    else setSearch(value)

    const lowercasedValue = value.toLowerCase()
    const isSearching = !!value

    setIsSearching(isSearching)

    if (!isSearching) {
      if (inEnabledFields) {
        setFilteredEnabledFields(enabledFields)
      } else {
        setFilteredFields(fields)
      }
      return
    }

    let filteredData: FieldData[] = []

    if (inEnabledFields) {
      filteredData = fieldList?.filter((item) => item.label.toLowerCase().includes(lowercasedValue))
      setFilteredEnabledFields(filteredData)
    } else {
      filteredData = fieldList?.filter((item) => item.label.toLowerCase().includes(lowercasedValue))
      setFilteredFields(filteredData)
    }
  }

  return (
    <>
      {loading && <Loading />}
      <div className='flex w-full min-h-screen md:flex-wrap md:min-h-full lg:flex-col'>
        <AdminSetting />
        <div className='px-7 py-5 w-full lg:px-4 min-w-[calc(100%-15rem)]' data-cy='email-template-page'>
          <div className='flex gap-3 md:gap-1 md:justify-between items-center'>
            <h1 className='md:font-2xl text-3xl font-bold decoration-1'>Students Fields Filter</h1>
            <Tooltip
              title='When you change the information in a Label box, it will be saved automatically once you click outside the box.'
              classes={{ popper: '[&_div]:text-base' }}
              enterTouchDelay={1}
              placement='bottom'
              arrow
            >
              <span>
                <BsQuestionCircleFill className='size-5 md:size-7 mt-1 text-slate-400 cursor-pointer' />
              </span>
            </Tooltip>
          </div>

          <div className='overflow-auto mt-5'>
            <div className='flex justify-between items-center'>
              <div className='flex gap-3 md:gap-1 md:justify-between items-center'>
                <h3 className='md:font-xl text-2xl font-bold decoration-1'>Default Visible Fields</h3>
                <Tooltip
                  title='Only these fields will be visible in the student listing by default. (can be customized from the "Change Display Columns")'
                  classes={{ popper: '[&_div]:text-base' }}
                  enterTouchDelay={1}
                  placement='bottom'
                  arrow
                >
                  <span>
                    <BsQuestionCircleFill className='size-5 md:size-7 mt-1 text-slate-400 cursor-pointer' />
                  </span>
                </Tooltip>
              </div>
              <div className='w-56 p-2'>
                <Autocomplete
                  freeSolo
                  options={[]}
                  renderInput={(params) => <TextField {...params} label='Search' />}
                  onInputChange={(e, value) => filterData(value, true, enabledFields)}
                />
              </div>
            </div>
            <table className='w-full mt-1 lg:w-max' data-cy='student-fields-list-enabled-table'>
              <thead>
                <tr className='[&>*]:bg-primary [&>*]:text-white [&>*]:tracking-wider [&>*]:py-3 [&>*]:border-slate-500 [&>*]:text-center'>
                  <th>
                    <IoMove />
                  </th>
                  <th>Label</th>
                  <th>Field Mapping Key</th>
                  <th>Filterable?</th>
                  <th>Default?</th>
                  <th>Display In Admission Dashboard View?</th>
                  <th>Display In Visa Dashboard View?</th>
                </tr>
              </thead>
              <tbody>
                <DndProvider debugMode={true} backend={HTML5Backend}>
                  {filteredEnabledFields?.length === 0 || !filteredEnabledFields ? (
                    <>
                      <tr>
                        <td colSpan={6}>No Fields Found</td>
                      </tr>
                    </>
                  ) : (
                    <>
                      {filteredEnabledFields
                        ?.sort(function (a, b) {
                          return ((Number(a.filterColumnOrder) as number) ?? 0) - ((Number(b.filterColumnOrder) as number) ?? 0)
                        })
                        .map((field, index) => <TableRow key={field.id} index={index} {...field} />)}
                    </>
                  )}
                </DndProvider>
              </tbody>
            </table>
          </div>
          <div className='overflow-auto mt-5 mb-3'>
            <div className='flex justify-between items-center'>
              <div className='flex gap-3 md:gap-1 md:justify-between items-center'>
                <h3 className='md:font-xl text-2xl font-bold decoration-1'>Default Hidden Fields</h3>
                <Tooltip
                  title='These fields will not be visible in the student listing by default. (can be customized from the "Change Display Columns")'
                  classes={{ popper: '[&_div]:text-base' }}
                  enterTouchDelay={1}
                  placement='bottom'
                  arrow
                >
                  <span>
                    <BsQuestionCircleFill className='size-5 md:size-7 mt-1 text-slate-400 cursor-pointer' />
                  </span>
                </Tooltip>
              </div>
              <div className='w-56 p-2'>
                <Autocomplete
                  freeSolo
                  options={[]}
                  renderInput={(params) => <TextField {...params} label='Search' />}
                  onInputChange={(e, value) => filterData(value, false, fields)}
                />
              </div>
            </div>

            {fields?.length === 0 || !fields ? (
              <>
                <table className='w-full'>
                  <thead>
                    <tr className='[&>*]:bg-primary [&>*]:text-white [&>*]:tracking-wider [&>*]:py-3 [&>*]:border-slate-500 [&>*]:text-left'>
                      <th>Label</th>
                      <th>Field Mapping Key</th>
                      <th>Filterable?</th>
                      <th>Default?</th>
                      <th>Display in Admission Dashboard View?</th>
                      <th>Display In Visa Dashboard View?</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan={3}>No Fields Found</td>
                    </tr>
                  </tbody>
                </table>
              </>
            ) : (
              <TableVirtuoso
                ref={listVirtuosoRef}
                className='!h-[calc(100vh-35vh)] [&_table]:w-full overflow-x-hidden'
                totalCount={filteredFields.length}
                data={filteredFields?.sort(function (a, b) {
                  return ((Number(a.filterColumnOrder) as number) ?? 0) - ((Number(b.filterColumnOrder) as number) ?? 0)
                })}
                fixedHeaderContent={() => (
                  <tr className='[&>*]:bg-primary [&>*]:text-white [&>*]:tracking-wider [&>*]:py-3 [&>*]:border-slate-500 [&>*]:text-center'>
                    <th>Label</th>
                    <th>Field Mapping Key</th>
                    <th>Filterable?</th>
                    <th>Default?</th>
                    <th>Display in Admission Dashboard View?</th>
                    <th>Display In Visa Dashboard View?</th>
                  </tr>
                )}
                itemContent={(index, field) => <TableRowContentTemplate index={index} {...{ ...field, filterColumnOrder: '-' }} />}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}
