import { useContext } from 'react'
import Label from '../Label'
import { DetailPropsType } from '../type'
import { subStepContext } from '../context'
import { Controller } from 'react-hook-form'
import Description from '../Description'

function PhoneType({ item, control, isRenderConditionalComponent, isViewOnly }: DetailPropsType) {
  const {
    state: { currentPage }
  } = useContext(subStepContext)

  if (isRenderConditionalComponent === false || currentPage !== item?.pageNumber || item?.visibility === 'hidden') return null

  return (
    <div className='mt-2'>
      <div className='flex justify-start md:flex-col'>
        <Label structure={item} />

        <div className='flex flex-col w-[70%] mb-6'>
          <div className='gap-1 md:mb-3 md:w-full'>
            <Controller
              control={control}
              name={item?.id?.toString()}
              rules={isViewOnly ? {} : { required: { value: item?.isRequired, message: 'This field is required.' } }}
              render={({ field: { ref, value, onChange }, fieldState: { error } }) => {
                return (
                  <div>
                    <input
                      className='form-input'
                      type={item?.inputType}
                      ref={ref}
                      value={value ?? ''}
                      onChange={onChange}
                      disabled={isViewOnly}
                    />
                    {error && <p className='text-left text-red-500 text-sm'>{error?.message}</p>}
                  </div>
                )
              }}
            />
          </div>
          <Description structure={item} />
        </div>
      </div>
    </div>
  )
}

export default PhoneType
