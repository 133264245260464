import { DetailPropsType } from '../type'
import Label from '../Label'
import { useContext, useEffect, useState } from 'react'
import { subStepContext } from '../context'
import Description from '../Description'

export default function SurveyType({ item /* , control */, isRenderConditionalComponent, watch /* , isViewOnly */ }: DetailPropsType) {
  const {
    state: { currentPage }
  } = useContext(subStepContext)

  const [renderValue, setRenderValue] = useState<string[] | null>(null)

  if (isRenderConditionalComponent === false || currentPage !== item?.pageNumber || item?.visibility === 'hidden') return null

  useEffect(() => {
    const val = watch(item?.id?.toString())
    if (
      val &&
      String(val ?? '')
        .trim()
        .startsWith('[') &&
      String(val).trim().endsWith(']')
    ) {
      try {
        const dataArr = JSON.parse(String(val ?? '').trim())
        setRenderValue(dataArr)
      } catch (e) {
        //
      }
    }
  }, [])

  return (
    <div className='mt-2'>
      <div className='flex justify-start md:flex-col'>
        <Label structure={item} />
        <div className='flex flex-col w-[70%] gap-1'>
          {renderValue && renderValue?.length > 0 ? (
            <ul className='list-disc opacity-75 cursor-not-allowed' title='View only'>
              {renderValue.map((val, index) => (
                <li className='text-base' key={`survey_item_${index}`}>
                  {val}
                </li>
              ))}
            </ul>
          ) : (
            <>{'-'}</>
          )}
          <Description structure={item} />
        </div>
      </div>
    </div>
  )
}
