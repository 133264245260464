import { useContext, useEffect, useState } from 'react'
import Loading from 'Components/Loading'
import AdminSetting from '../index'
import { RolePermissionContext } from 'Context/RolePermission/RolePermissionContext'
import { RolePermissionTypes, RoleType } from 'Context/RolePermission/RolePermissionTypes'
import { toTitleCase } from 'CommonJS/common'
import { FaEdit, FaPencilAlt } from 'react-icons/fa'
import { Superadmin } from 'CommonJS/roleHelper'
import { FaX } from 'react-icons/fa6'
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material'
import http from 'CommonJS/http'
import { toast } from 'Components/Toast/toast'
import { AxiosResponse } from 'axios'

export default function RolePermissions() {
  interface EditRoleType extends Omit<RoleType, 'permissions'> {
    permissions: Array<number>
  }

  const { roles, permissions, getRoles } = useContext(RolePermissionContext) as RolePermissionTypes
  const [loading, setLoading] = useState<boolean>(false)
  const [editRole, setEditRole] = useState<EditRoleType | null>(null)

  useEffect(() => {
    const init = async () => {
      if (!roles || roles?.length === 0) {
        setLoading(true)
        await getRoles()
        setLoading(false)
      }
    }
    init()
  }, [])

  /**
   *
   * @param {string} name - ex: student_list.show_filters
   * @returns {string} - ex: Student List → Show Filters
   */
  const parsePermissionText = (name: string) => {
    const tempName = String(name)

    return tempName
      .replaceAll(/_/g, ' ')
      .split('.')
      .join(' ' + '\u2192' + ' ')
  }

  const handleSave = () => {
    setLoading(true)

    http
      .fetch({ method: 'post', path: `setting/roles/${editRole?.id}`, data: { permissions: editRole?.permissions } })
      .then(async (response: void | AxiosResponse<{ success: boolean; message: string }>) => {
        if (response && response.status === 200) {
          toast(response.data?.message, 'success')
          setEditRole(null)
          await getRoles()
          setLoading(false)
        }
      })
      .catch(({ response }) => {
        setLoading(false)
        toast(response?.data.message ?? 'Internal server error.', 'error')
      })
  }

  return (
    <>
      {loading && <Loading />}
      <div className='flex w-full min-h-[calc(100vh-77px)] lg:flex-wrap lg:min-h-full' data-cy='roles-permission-page'>
        <AdminSetting />
        <div className='w-full p-6 md:px-4'>
          <div className='flex justify-between items-center min-h-12 mb-7 lg:mb-5'>
            <h1 className='text-3xl font-bold uppercase'>Roles Permission</h1>
          </div>

          <div className='overflow-auto'>
            <table className='w-full mt-5 lg:w-max' data-cy='roles-permission-table'>
              <thead>
                <tr className='[&>*]:bg-primary [&>*]:text-white [&>*]:tracking-wider [&>*]:py-3 [&>*]:border-slate-500 [&>*]:text-left'>
                  <th className='w-[30%]'>Role</th>
                  <th>Permissions</th>
                  <th className='w-[30%]'>Action</th>
                </tr>
              </thead>
              <tbody>
                {!roles || roles.length === 0 ? (
                  <>
                    <tr>
                      <td colSpan={6}>No Roles Found</td>
                    </tr>
                  </>
                ) : (
                  <>
                    {roles?.map((role) => (
                      <tr key={role.id}>
                        <td>{toTitleCase(role.name, '_')}</td>
                        <td>
                          {role.name === Superadmin ? (
                            <span>
                              Permissions are not applicable to <span className='font-bold'>{toTitleCase(role.name)}</span>.<br />
                              <span className='font-bold'>{toTitleCase(role.name)}</span> users have full, uninterrupted access.
                            </span>
                          ) : (
                            <>
                              {editRole?.id && editRole?.id === role.id ? (
                                <FormControl sx={{ m: 1, minWidth: '160px' }}>
                                  <InputLabel id='student-views-permissions-label'>Permissions</InputLabel>
                                  <Select
                                    className='min-w-[569px] max-w-[569px] role-dropdown-tag-container-parent'
                                    data-cy='select-role-permissions'
                                    labelId='student-views-permissions-label'
                                    id='student-views-permissions'
                                    multiple
                                    autoWidth
                                    value={editRole.permissions}
                                    onChange={(event) => {
                                      const value = event.target?.value as number[]
                                      setEditRole({
                                        ...editRole,
                                        permissions: value as number[]
                                      })
                                    }}
                                    input={<OutlinedInput label='Permissions' />}
                                    renderValue={(selected) => {
                                      const selectedNames = permissions
                                        ?.filter((permission) => selected.includes(permission.id))
                                        .map((permission) => parsePermissionText(permission.name))
                                      return (
                                        <>
                                          {selectedNames
                                            ? selectedNames.map((rName) => (
                                                <span key={rName} className='px-3 py-2 text-sm rounded-md bg-primary text-white capitalize'>
                                                  {rName}
                                                </span>
                                              ))
                                            : ''}
                                        </>
                                      )
                                    }}
                                  >
                                    {permissions?.map((permission) => {
                                      return (
                                        <MenuItem key={`permission_${permission.id}`} value={permission.id}>
                                          <Checkbox checked={editRole.permissions?.indexOf(permission?.id) > -1} />
                                          <ListItemText className='capitalize' primary={parsePermissionText(permission.name)} />
                                        </MenuItem>
                                      )
                                    })}
                                  </Select>
                                </FormControl>
                              ) : (
                                <div className='flex flex-wrap gap-y-2 gap-x-3'>
                                  {!role.permissions || role.permissions.length === 0 ? (
                                    <span className='font-bold'>No permission allowed</span>
                                  ) : (
                                    <>
                                      {role.permissions?.map((permission) => (
                                        <span
                                          key={`${role.id}_${permission.id}`}
                                          className='px-3 py-2 text-sm rounded-md bg-primary text-white capitalize'
                                        >
                                          {parsePermissionText(permission.name)}
                                        </span>
                                      ))}
                                    </>
                                  )}
                                </div>
                              )}
                            </>
                          )}
                        </td>
                        <td>
                          {role.name === Superadmin ? (
                            <span className='font-bold'>No action available</span>
                          ) : (
                            <div className='flex gap-5'>
                              {editRole?.id && editRole?.id === role.id ? (
                                <>
                                  <button
                                    className='btn btn-primary flex items-center gap-x-3'
                                    data-cy='role-permissions-save'
                                    onClick={() => {
                                      handleSave()
                                    }}
                                  >
                                    Save <FaPencilAlt size={14} />
                                  </button>
                                  <button
                                    className='btn btn-secondary flex items-center gap-x-3'
                                    data-cy='role-permissions-cancel'
                                    onClick={() => {
                                      setEditRole(null)
                                    }}
                                  >
                                    Cancel <FaX size={14} />
                                  </button>
                                </>
                              ) : (
                                <button
                                  className='btn btn-primary flex items-center gap-x-3'
                                  data-cy='role-permissions-edit'
                                  onClick={() => {
                                    setEditRole({
                                      ...role,
                                      permissions: role.permissions.map((p) => p.id)
                                    })
                                  }}
                                >
                                  Edit <FaEdit />
                                </button>
                              )}
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}
