import { useContext, useEffect, useState } from 'react'
import Description from '../Description'
import Label from '../Label'
import { DetailPropsType } from '../type'
import { subStepContext } from '../context'
import { Controller } from 'react-hook-form'
import countries from '../../../CommonJS/countries.json'
import Select from '@mui/material/Select'
import { MenuItem } from '@mui/material'

function AddressType({ item, control, isRenderConditionalComponent, isViewOnly }: DetailPropsType) {
  const {
    state: { currentPage }
  } = useContext(subStepContext)

  if (isRenderConditionalComponent === false || currentPage !== item?.pageNumber || item?.visibility === 'hidden') return null

  const [defaultCountry, setDefaultCountry] = useState<string>('')

  useEffect(() => {
    if (item?.defaultCountry && String(item?.defaultCountry).trim() !== '') {
      const defaultCountryCodeObj = countries.find((c) => c.label === item.defaultCountry)
      if (defaultCountryCodeObj && defaultCountryCodeObj?.value) {
        setDefaultCountry(defaultCountryCodeObj?.value)
      }
    }
  }, [item])

  return (
    <div className='mt-2'>
      <div className='flex justify-start md:flex-col'>
        <Label structure={item} />

        <div className='flex justify-start w-[70%] gap-x-1 md:w-full md:min-w-full'>
          <div className='grid grid-cols-3 gap-2	w-[-webkit-fill-available] md:grid-cols-1'>
            {item?.inputs?.map((fields: any, index: number) => {
              if (fields?.isHidden) return null
              else {
                return (
                  <div key={index} className='mb-6 md:mb-3'>
                    <label className='block uppercase tracking-wide text-gray-500 text-sm mb-2' htmlFor='grid-first-name'>
                      {fields.label}
                    </label>
                    <Controller
                      control={control}
                      name={fields?.id?.toString()}
                      rules={isViewOnly ? {} : { required: { value: item?.isRequired, message: 'This field is required.' } }}
                      render={({ field: { ref, value, onChange }, fieldState: { error } }) => {
                        return (
                          <div>
                            {item.adminLabel === 'mail_address' && fields.name === 'mailing_address_country' ? (
                              <Select
                                className='w-2/4 [&>div]:!py-[0.85rem] [&>div]:!px-4'
                                value={value ?? defaultCountry}
                                onChange={onChange}
                                ref={ref}
                                disabled={isViewOnly}
                                placeholder={item?.placeholder ? item?.placeholder : ''}
                                displayEmpty={true}
                              >
                                {countries?.map((choice: any, index: number) => (
                                  <MenuItem key={index} value={choice.value}>
                                    {choice.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            ) : (
                              <input
                                id={fields?.id}
                                className='form-input'
                                type='text'
                                ref={ref}
                                onChange={onChange}
                                value={value ?? ''}
                                disabled={isViewOnly}
                              />
                            )}
                            {error && <p className='text-left text-red-500 text-sm'>{error?.message}</p>}
                          </div>
                        )
                      }}
                    />
                  </div>
                )
              }
            })}
          </div>
          <Description structure={item} />
        </div>
      </div>
    </div>
  )
}

export default AddressType
