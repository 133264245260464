import { useEffect } from 'react'
import AddressType from './Fields/AddressType'
import CheckboxType from './Fields/CheckboxType'
import DateType from './Fields/DateType'
import EmailType from './Fields/EmailType'
import FileUploadType from './Fields/FileUploadType'
import HtmlType from './Fields/HtmlType'
import NameType from './Fields/NameType'
import PageType from './Fields/PageType'
import PhoneType from './Fields/PhoneType'
import RadioType from './Fields/RadioType'
import Sectiontype from './Fields/Sectiontype'
import SelectType from './Fields/SelectType'
import SignatureType from './Fields/SignatureType'
import TextType from './Fields/TextType'
import TextareaType from './Fields/TextareaType'
import TimeType from './Fields/TimeType'
import SurveyType from './Fields/SurveyType'

interface Props {
  item: any
  index: number
  control: any
  watch: any
  setValue: any
  getValues: (name: string) => unknown
  isViewOnly: boolean | undefined
  setError: (name: string, value: { type: string; message: string }) => void
  studentId: string | number
  handleSubmit: any
  onNext: (event: React.MouseEvent<HTMLElement>) => void
  onPrevious: () => void
  setOpenModal: any
  isRenderConditionalComponent: any
  isFieldViewOnly: any
}

function FormItem({
  item,
  index,
  control,
  watch,
  setValue,
  getValues,
  setError,
  studentId,
  handleSubmit,
  onNext,
  onPrevious,
  setOpenModal,
  isRenderConditionalComponent,
  isFieldViewOnly
}: Props) {
  useEffect(() => {
    const watchedValue = item ? watch(item?.id?.toString(), '') : ''
    if (!isRenderConditionalComponent && watchedValue !== '' && watchedValue !== undefined) setValue(item?.id?.toString(), '')
  }, [item, isRenderConditionalComponent])

  return (
    <div key={index} className='mt-5 md:mt-3'>
      {item?.type === 'html' && <HtmlType item={item} isRenderConditionalComponent={isRenderConditionalComponent} />}
      {item?.type === 'page' && (
        <PageType
          item={item}
          isRenderConditionalComponent={isRenderConditionalComponent}
          onNext={handleSubmit(onNext)}
          onPrevious={() => onPrevious()}
          isViewOnly={isFieldViewOnly}
          setOpenModal={setOpenModal}
        />
      )}
      {item?.type === 'section' && <Sectiontype item={item} isRenderConditionalComponent={isRenderConditionalComponent} />}
      {item?.type === 'name' && (
        <NameType item={item} control={control} isRenderConditionalComponent={isRenderConditionalComponent} isViewOnly={isFieldViewOnly} />
      )}
      {item?.type === 'phone' && (
        <PhoneType item={item} control={control} isRenderConditionalComponent={isRenderConditionalComponent} isViewOnly={isFieldViewOnly} />
      )}
      {item?.type === 'checkbox' && (
        <CheckboxType
          item={item}
          control={control}
          isRenderConditionalComponent={isRenderConditionalComponent}
          watch={watch}
          setValue={setValue}
          isViewOnly={isFieldViewOnly}
        />
      )}
      {item?.type === 'date' && (
        <DateType
          item={item}
          control={control}
          isRenderConditionalComponent={isRenderConditionalComponent}
          watch={watch}
          isViewOnly={isFieldViewOnly}
          setValue={setValue}
        />
      )}
      {item?.type === 'radio' && (
        <RadioType item={item} control={control} isRenderConditionalComponent={isRenderConditionalComponent} isViewOnly={isFieldViewOnly} />
      )}
      {item?.type === 'address' && (
        <AddressType
          item={item}
          control={control}
          isRenderConditionalComponent={isRenderConditionalComponent}
          isViewOnly={isFieldViewOnly}
        />
      )}
      {item?.type === 'fileupload' && (
        <FileUploadType
          item={item}
          control={control}
          isRenderConditionalComponent={isRenderConditionalComponent}
          setValue={setValue}
          studentId={studentId}
          isViewOnly={isFieldViewOnly}
        />
      )}
      {item?.type === 'text' && (
        <TextType item={item} control={control} isRenderConditionalComponent={isRenderConditionalComponent} isViewOnly={isFieldViewOnly} />
      )}
      {item?.type === 'email' && (
        <EmailType
          item={item}
          isRenderConditionalComponent={isRenderConditionalComponent}
          watch={watch}
          control={control}
          isViewOnly={isFieldViewOnly}
        />
      )}
      {item?.type === 'select' && (
        <SelectType
          item={item}
          control={control}
          isRenderConditionalComponent={isRenderConditionalComponent}
          isViewOnly={isFieldViewOnly}
        />
      )}
      {item?.type === 'textarea' && (
        <TextareaType
          item={item}
          control={control}
          isRenderConditionalComponent={isRenderConditionalComponent}
          isViewOnly={isFieldViewOnly}
        />
      )}
      {item?.type === 'signature' && (
        <SignatureType
          item={item}
          control={control}
          isRenderConditionalComponent={isRenderConditionalComponent}
          watch={watch}
          setError={setError}
          setValue={setValue}
          getValues={getValues}
          isViewOnly={isFieldViewOnly}
        />
      )}
      {item?.type === 'time' && (
        <TimeType
          item={item}
          control={control}
          isRenderConditionalComponent={isRenderConditionalComponent}
          watch={watch}
          isViewOnly={isFieldViewOnly}
          setValue={setValue}
        />
      )}
      {item?.type === 'survey' && (
        <SurveyType
          item={item}
          control={control}
          isRenderConditionalComponent={isRenderConditionalComponent}
          watch={watch}
          isViewOnly={isFieldViewOnly}
          setValue={setValue}
        />
      )}
    </div>
  )
}

export default FormItem
