import { Badge, Box, Collapse, Fade, List } from '@mui/material'
import { AxiosResponse } from 'axios'
import { initConnection, registerNotificationEvent, sendBrowserNotification } from 'CommonJS/echo'
import http from 'CommonJS/http'
import Loading from 'Components/Loading'
import { toast } from 'Components/Toast/toast'
import { UserContext } from 'Context/UserContext'
import { useContext, useEffect, useState } from 'react'
import { FaBell } from 'react-icons/fa'
import NotificationType from './type'
import NotificationItem from './item'
import { TransitionGroup } from 'react-transition-group'

export default function Notification() {
  const { data: loggedUser } = useContext(UserContext)
  const [loading, setLoading] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [shouldRender, setShouldRender] = useState<boolean>(false)
  const [notifications, setNotifications] = useState<NotificationType[]>()
  const [channel] = useState('app.notification.' + loggedUser.id)

  useEffect(() => {
    const init = async () => {
      await loadNotifications()
      await initConnection()
    }

    init()
    registerNotificationEvent(channel, (data: NotificationType) => {
      const keysToCheck = ['id', 'title', 'readAt', 'createdAt']

      if ('action' in data && data.action) {
        return handleCustomAction(data)
      }

      const allKeysExist = keysToCheck.every((key) => {
        let inData = true
        if (data?.data) {
          inData = key in data.data
        }
        return key in data || inData
      })
      if (allKeysExist) {
        setNotifications((currentNotifications) => {
          if (data?.data?.title) {
            sendBrowserNotification(data?.data?.title)
          }
          const notifyData = [data, ...(currentNotifications ?? [])]
          if (data?.total !== notifyData?.length) {
            // In-case new notification triggered in-between, user
            // websocket auth and initial loaded notifications.
            setTimeout(() => {
              loadNotifications()
            }, 500)
            return currentNotifications
          }

          return notifyData
        })
      } else {
        loadNotifications()
      }
    })
  }, [])

  function handleCustomAction(request: NotificationType) {
    if (request.action) {
      loadNotifications()
    }
  }

  async function loadNotifications() {
    setLoading(true)
    http
      .fetch({ path: 'notifications' })
      .then((response: void | AxiosResponse<{ notifications: NotificationType[] }>) => {
        if (response && response.status === 200) {
          setNotifications(response.data.notifications)
        }
        setLoading(false)
      })
      .catch(({ response }) => {
        setLoading(false)
        toast(response?.data.message ?? 'Internal server error.', 'error')
      })
  }

  function onUpdate(notification: NotificationType) {
    setNotifications((oldNotification) => {
      const updatedNotification = oldNotification?.map((n) => {
        return n.id === notification.id ? notification : n
      })

      return updatedNotification
    })
    setLoading(false)
  }

  function onDelete(id: NotificationType['id']) {
    const filteredNotification = notifications?.filter((n) => n.id !== id)
    setNotifications(filteredNotification)
    setLoading(false)
  }

  return (
    <div className='relative md:static size-6'>
      {loading && <Loading className='!absolute !bg-transparent [&_.spinner]:!size-6' />}
      <div
        role='button'
        onClick={() => {
          setShouldRender(!isOpen)
          setTimeout(() => setIsOpen(!isOpen))
        }}
      >
        <Badge
          badgeContent={notifications?.filter((n) => n.readAt == null)?.length ?? 0}
          max={10}
          className='select-none'
          invisible={notifications?.length === 0}
          slotProps={{ badge: { className: 'bg-secondary' } }}
        >
          <FaBell size={'1.5rem'} className={`${isOpen ? 'text-secondary' : 'text-gray-100'}`} />
        </Badge>
      </div>

      <Box>
        {shouldRender && (
          <Fade in={isOpen} onExited={() => setShouldRender(false)} timeout={300}>
            <div className='absolute top-[50px] bg-white right-0 border border-slate-200 shadow-2xl rounded-md min-w-[30rem] max-w-[30rem] max-h-96 overflow-y-auto md:min-w-[95vw] md:max-w-[95vw]'>
              <div className='px-4 py-2 bg-primary text-white text-lg'>Notifications</div>
              {loading && <Loading className='!absolute [&_.spinner]:!size-4' />}
              {notifications && notifications.length > 0 ? (
                <List sx={{ padding: 0 }}>
                  <TransitionGroup>
                    {notifications.map((notification) => (
                      <Collapse key={notification.id}>
                        <NotificationItem
                          key={notification.id}
                          notification={notification}
                          setLoading={(val) => setLoading(val)}
                          onUpdate={onUpdate}
                          onDelete={onDelete}
                        />
                      </Collapse>
                    ))}
                  </TransitionGroup>
                </List>
              ) : (
                <div className='text-lg px-2 leading-[69px]'>You are all caught up.</div>
              )}
            </div>
          </Fade>
        )}
      </Box>
    </div>
  )
}
