import { NavLink, useLocation } from 'react-router-dom'
import { FaArrowRight } from 'react-icons/fa'
import DashboardIcon from 'Components/DashboardIcon'
import { isSuperAdmin, isAdmin } from 'CommonJS/roleHelper'
import { useContext } from 'react'
import { UserContext } from 'Context/UserContext'
import { FaArrowUpRightFromSquare } from 'react-icons/fa6'

export default function AdminSetting() {
  const uri = useLocation().pathname
  const { data } = useContext(UserContext)

  return (
    <>
      <div className='w-60 min-w-60 py-4 bg-slate-100 shadow-lg bottom-0 lg:w-full lg:min-w-full lg:pb-0 sticky top-[77px] max-h-[calc(100vh-77px)] md:static md:max-h-full overflow-y-auto'>
        <ul className='text-md [&_a]:p-4 [&_a:hover]:!bg-primary [&_li:hover]:!text-white [&_a.active>svg]:block [&_a.active]:bg-primary [&_a.active]:!text-white [&_a.active]:shadow-sm'>
          <DashboardIcon />
          <h3 className='font-semibold p-3 pl-5'>SETTINGS </h3>
          {isSuperAdmin(data?.roles) ? (
            <>
              <li className='cursor-pointer [&>a]:!pl-9'>
                <NavLink to='/admin/setting/basic' className='flex justify-between items-center'>
                  Basic Settings
                  <FaArrowRight className='hidden' />
                </NavLink>
              </li>
              <li className='cursor-pointer [&>a]:!pl-9'>
                <NavLink to='/admin/setting/mail' className='flex justify-between items-center'>
                  Mail Settings
                  <FaArrowRight className='hidden' />
                </NavLink>
              </li>
              <li className='[&>a]:!pl-9'>
                <NavLink to={'/admin/setting/form'} className='flex justify-between items-center'>
                  Form Settings
                  <FaArrowRight className='hidden' />
                </NavLink>
              </li>
              <li className='[&>a]:!pl-9'>
                <NavLink
                  to={'/admin/setting/template'}
                  className={`flex justify-between items-center ${uri.startsWith('/admin/setting/template') ? 'active' : ''}`}
                >
                  Email Templates
                  <FaArrowRight className='hidden' />
                </NavLink>
              </li>
              <li className='[&>a]:!pl-9'>
                <NavLink
                  to={'/admin/setting/emergency-banner'}
                  className={`flex justify-between items-center ${uri.startsWith('/admin/setting/emergency-banner') ? 'active' : ''}`}
                >
                  Banner Notice
                  <FaArrowRight className='hidden' />
                </NavLink>
              </li>
              <li className='[&>a]:!pl-9'>
                <NavLink
                  to={'/admin/setting/roles-permissions'}
                  className={`flex justify-between items-center ${uri.startsWith('/admin/setting/roles-permissions') ? 'active' : ''}`}
                >
                  Roles Permissions
                  <FaArrowRight className='hidden' />
                </NavLink>
              </li>
              <li className='[&>a]:!pl-9'>
                <NavLink
                  to={'/admin/setting/field-filter'}
                  className={`flex justify-between items-center ${uri.startsWith('/admin/setting/field-filter') ? 'active' : ''}`}
                >
                  Students Field Filter
                  <FaArrowRight className='hidden' />
                </NavLink>
              </li>
              <li className='[&>a]:!pl-9'>
                <NavLink to={'/admin/student/global-view'} className='flex justify-between items-center'>
                  Manage Global View
                  <FaArrowUpRightFromSquare />
                </NavLink>
              </li>
            </>
          ) : (
            <>
              {isAdmin(data?.roles) && (
                <>
                  <li className='[&>a]:!pl-9'>
                    <NavLink
                      to={'/admin/setting/template'}
                      className={`flex justify-between items-center ${uri.startsWith('/admin/setting/template') ? 'active' : ''}`}
                    >
                      Email Templates
                      <FaArrowRight className='hidden' />
                    </NavLink>
                  </li>
                  <li className='[&>a]:!pl-9'>
                    <NavLink
                      to={'/admin/setting/emergency-banner'}
                      className={`flex justify-between items-center ${uri.startsWith('/admin/setting/emergency-banner') ? 'active' : ''}`}
                    >
                      Banner Notice
                      <FaArrowRight className='hidden' />
                    </NavLink>
                  </li>
                </>
              )}
            </>
          )}
        </ul>
      </div>
    </>
  )
}
