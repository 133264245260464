export interface ApplicationStatusFilterType {
  value: string
  label: string
}
export const applicationStatusFilters: ApplicationStatusFilterType[] = [
  { value: 'new', label: 'New Application' },
  { value: 'accepted', label: 'Accepted Application' },
  { value: 'conditionally_accepted', label: 'Conditionally Accepted Application' },
  { value: 'committed', label: 'Committed Application' },
  { value: 'completed', label: 'Completed Application' },
  { value: 'withdrawn', label: 'Withdrawn Application' },
  { value: 'processing', label: 'Processing Application' }
]
