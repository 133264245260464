import http from 'CommonJS/http'
import Loading from 'Components/Loading'
import { AppContext } from 'Context/AppContext'
import { AppContextType } from 'Context/AppTypes'
import { useContext, useEffect, useState } from 'react'
import { toast } from 'Components/Toast/toast'
// import TinyEditor from 'Components/TinyEditor'
import CKEditors from 'Components/CKEditor'
import { FaEdit, FaEye } from 'react-icons/fa'
import ImageSelector from 'Components/ImageSelector'
import { AxiosResponse } from 'axios'

export default function Institute() {
  const [loading, setLoading] = useState(false)
  const [showEditor, setShowEditor] = useState<boolean>(false)
  const [letterValue, setLetterValue] = useState<string>('')
  const [type, setType] = useState<LetterEditType>({ key: '', id: 0 })
  const [label, setLabel] = useState<string>('')
  const [view, setView] = useState<boolean>(false)
  const { institutes, templateVariables, uploadFile, getInstitute, getTemplatesVariables } = useContext(AppContext) as AppContextType

  useEffect(() => {
    setLoading(true)
    const init = async () => {
      if (institutes?.length === 0) await getInstitute()
      if (templateVariables?.length === 0) getTemplatesVariables()
      await setLoading(false)
    }
    init()
  }, [])

  const changeFileState = async (file: File, instituteId: number | string) => {
    try {
      await setLoading(true)
      const fileRes = await uploadFile(file)
      if (fileRes) {
        const payload = {
          image_path: fileRes?.document?.id
        }
        await handleSubmitApi(instituteId, payload)
      }

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const handleSubmitApi = async (instituteId: number | string, payload: object): Promise<AxiosResponse | void> => {
    return http
      .fetch({ method: 'put', path: `setting/institute/${instituteId}`, data: payload })
      .catch((error) => {
        toast(error?.response?.data?.message, 'error')
        setLoading(false)
      })
      .then((response) => {
        if (response && response.status === 200) {
          getInstitute()
          toast(response?.data?.message, 'success')
        }
        setLoading(false)
      })
  }

  type LetterEditType = {
    key: string
    id: number
  }

  const saveLetter = async (letterContent: string, letter: LetterEditType) => {
    const payload = {
      [letter.key]: letterContent
    }
    setLoading(true)
    await handleSubmitApi(letter.id, payload)
    setLoading(false)
  }

  const handleEditCLick = (value: string, label: string, type: LetterEditType) => {
    setShowEditor(true)
    setLetterValue(value)
    setType(type)
    setLabel(label)
    setView(false)
  }

  type LettersType = 'acceptanceLetter' | 'visaLetter'
  return (
    <>
      {loading && <Loading />}
      <div className='flex w-full min-h-screen md:flex-wrap md:min-h-[auto] flex-wrap'>
        {loading && <Loading />}
        {showEditor && (
          <div id='institute-letter-view-edit' className='w-full'>
            <CKEditors
              data-cy='tiny-text'
              initialValue={letterValue}
              type={type}
              label={label}
              callSubmit={saveLetter}
              isView={view}
              variable={templateVariables}
              reSize={true}
              back={() => {
                setShowEditor(false)
              }}
              classes={{
                submitBtnBlock: '!border-0',
                viewBlock: '!h-[73vh]'
              }}
            />
          </div>
        )}
        <div className={`w-full ${showEditor === true ? 'hidden invisible opacity-0' : ''}`}>
          <div className='flex justify-between items-center min-h-12 mb-7 md:mb-5'>
            <h1 className='text-3xl font-bold uppercase md:text-2xl'>Institutes</h1>
          </div>

          <div className='w-full gap-y-4 flex flex-col md:w-full overflow-x-auto'>
            <table className='table-auto lg:w-max'>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Acceptance Letter</th>
                  <th>Visa Letter</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {institutes?.map((institute, index) => (
                  <tr key={`institute_${institute.id}_${index}`}>
                    <td>{institute?.id}</td>
                    <td>{institute?.name}</td>
                    {Object.entries({ acceptanceLetter: 'Acceptance Letter', visaLetter: 'Visa Letter' }).map(([item, label]) => (
                      <td key={`${item}_${index}`}>
                        <div className='flex gap-3'>
                          <button
                            data-cy='institute-view-btn'
                            className='text-sm btn-primary uppercase flex gap-3 items-center'
                            onClick={() => {
                              handleEditCLick(institute?.[item as LettersType], institute.name + ' ' + label, {
                                key: item,
                                id: institute.id
                              })
                              setView(true)
                            }}
                          >
                            View
                            <FaEye />
                          </button>
                          <button
                            data-cy='institute-edit-btn'
                            className='btn-primary uppercase flex gap-3 items-center'
                            onClick={() => {
                              handleEditCLick(institute?.[item as LettersType], institute.name + ' ' + label, {
                                key: item,
                                id: institute.id
                              })
                              setView(false)
                            }}
                          >
                            Edit
                            <FaEdit />
                          </button>
                        </div>
                      </td>
                    ))}
                    <td>
                      <ImageSelector
                        size={'60px'}
                        imageSrc={institute?.imagePath}
                        changeFileState={changeFileState}
                        id={institute.id}
                        key={`${institute.id}-${institute.imagePath}`}
                      />
                    </td>
                  </tr>
                ))}
                {institutes?.length === 0 && (
                  <tr>
                    <td colSpan={5} className='text-center'>
                      No Institutes Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}
