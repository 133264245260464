export enum PermissionEnum {
  /**
   * (Student listing page -> `Show Filters` button)
   */
  STUDENT_LIST_SHOW_FILTERS = 'student_list.show_filters',

  /**
   * (Student listing page -> `Change Filter Fields` button)
   */
  STUDENT_LIST_CHANGE_FILTER_FIELDS = 'student_list.change_filter_fields',

  /**
   * (Student listing page -> `Change Display Columns` button)
   */
  STUDENT_LIST_CHANGE_DISPLAY_COLUMNS = 'student_list.change_display_columns',

  /**
   * (Student listing page -> `Your Students` checkbox)
   */
  STUDENT_LIST_YOUR_STUDENTS = 'student_list.your_students',

  /**
   * (Student listing page -> `Show Archived Students` checkbox)
   */
  STUDENT_LIST_SHOW_ARCHIVED_STUDENTS = 'student_list.show_archived_students',

  /**
   * (Student listing page -> View popup -> `Manage View` link)
   */
  STUDENT_LIST_MANAGE_VIEW = 'student_list.manage_view',

  /**
   * (Student listing page -> View popup -> `Create New View` link)
   */
  STUDENT_LIST_CREATE_NEW_VIEW = 'student_list.create_new_view'
}
