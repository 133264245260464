export enum FormSubStepSlugEnum {
  INITIAL_REGISTRATION_FORM = 'initial_registration_form',
  PERSONAL_INFORMATION = 'personal_information',
  PROGRAM_COURSE_SELECTION_ISI_FLORENCE = 'program_course_selection_isi_florence',
  PROGRAM_COURSE_SELECTION_THE_UMBRA_INSTITUTE_ISI_PERUGIA = 'program_course_selection_the_umbra_institute_isi_perugia',
  TRANSCRIPT = 'transcript',
  HOUSING_REQUEST = 'housing_request',
  ACCEPTANCE_LETTER = 'acceptance_letter',
  CONFIRM_ENROLLMENT = 'confirm_enrollment',
  PARTICIPATION_AGREEMENTS = 'participation_agreements',
  HEALTH_INFORMATION_FORM = 'health_information_form',
  ACCOMMODATIONS_DOCUMENTS = 'accommodations_documents',
  DEPOSIT_BILLING = 'deposit_billing',
  PASSPORT_SCAN = 'passport_scan',
  INSURANCE_PORTAL = 'insurance_portal',
  VISA_LETTER = 'visa_letter',
  VISA_PAGE_SCAN = 'visa_page_scan',
  ARRIVAL_INFORMATION_ISI_FLORENCE = 'arrival_information_isi_florence',
  ARRIVAL_INFORMATION_THE_UMBRA_INSTITUTE_ISI_PERUGIA = 'arrival_information_the_umbra_institute_isi_perugia',
  PRE_DEPARTURE_GUIDES = 'pre_departure_guides',
  PRE_DEPARTURE_COURSE = 'pre_departure_course',
  SMARTPHONE_APP = 'smartphone_app',
  COURSE_SCHEDULE = 'course_schedule',
  VISA_APPLICATION_DOCUMENTS = 'visa_application_documents'
}
