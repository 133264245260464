import { API_BASE_URL, getToken } from 'CommonJS/common'
import * as Ck5P from 'ckeditor5'

export const CKEditorPlugins = [
  Ck5P.Autoformat,
  Ck5P.BlockQuote,
  Ck5P.Bold,
  Ck5P.CloudServices,
  Ck5P.Essentials,
  Ck5P.Heading,
  Ck5P.Image,
  Ck5P.ImageCaption,
  Ck5P.ImageResize,
  Ck5P.ImageStyle,
  Ck5P.ImageToolbar,
  Ck5P.ImageUpload,
  Ck5P.Base64UploadAdapter,
  Ck5P.Indent,
  Ck5P.IndentBlock,
  Ck5P.Italic,
  Ck5P.List,
  Ck5P.MediaEmbed,
  Ck5P.Mention,
  Ck5P.Paragraph,
  Ck5P.PasteFromOffice,
  Ck5P.PictureEditing,
  Ck5P.Table,
  Ck5P.TableColumnResize,
  Ck5P.TableToolbar,
  Ck5P.TextTransformation,
  Ck5P.Underline,
  Ck5P.Strikethrough,
  Ck5P.PageBreak,
  Ck5P.FindAndReplace,
  Ck5P.WordCount,
  Ck5P.Code,
  Ck5P.LinkImage,
  Ck5P.AutoLink,
  Ck5P.SpecialCharacters,
  Ck5P.SpecialCharactersEssentials,
  Ck5P.Alignment,
  Ck5P.CodeBlock,
  Ck5P.SimpleUploadAdapter,
  Ck5P.SourceEditing,
  Ck5P.ListProperties,
  Ck5P.Font
]

export const CKEditorToolbar = [
  'undo',
  'redo',
  '|',
  'heading',
  '|',
  'fontSize',
  'fontFamily',
  'fontColor',
  'fontBackgroundColor',
  'bold',
  'italic',
  'underline',
  'strikeThrough',
  'specialCharacters',
  'pageBreak',
  'alignment',
  '|',
  'link',
  'imageUpload',
  'insertTable',
  'blockQuote',
  'mediaEmbed',
  '|',
  'bulletedList',
  'numberedList',
  '|',
  'findAndReplace',
  'code',
  'codeBlock',
  'sourceEditing'
]
export const CKEditorAlignment: Ck5P.AlignmentConfig = {
  options: ['left', 'center', 'right', 'justify']
}

export const CKEditorImage: Ck5P.ImageConfig = {
  resizeUnit: 'px',
  resizeOptions: [
    {
      name: 'resizeImage:original',
      label: 'Default image width',
      value: null
    },
    {
      name: 'resizeImage:50',
      label: '50% page width',
      value: '50'
    },
    {
      name: 'resizeImage:75',
      label: '75% page width',
      value: '75'
    }
  ],
  toolbar: [
    'imageTextAlternative',
    'toggleImageCaption',
    '|',
    'imageStyle:inline',
    'imageStyle:wrapText',
    'imageStyle:breakText',
    '|',
    'resizeImage'
  ]
}

export const CKEditorHeading: Ck5P.HeadingConfig = {
  options: [
    {
      model: 'paragraph',
      title: 'Paragraph',
      class: 'ck-heading_paragraph'
    },
    {
      model: 'heading1',
      view: 'h1',
      title: 'Heading 1',
      class: 'ck-heading_heading1'
    },
    {
      model: 'heading2',
      view: 'h2',
      title: 'Heading 2',
      class: 'ck-heading_heading2'
    },
    {
      model: 'heading3',
      view: 'h3',
      title: 'Heading 3',
      class: 'ck-heading_heading3'
    },
    {
      model: 'heading4',
      view: 'h4',
      title: 'Heading 4',
      class: 'ck-heading_heading4'
    },
    {
      model: 'heading5',
      view: 'h4',
      title: 'Heading 5',
      class: 'ck-heading_heading5'
    },
    {
      model: 'heading6',
      view: 'h6',
      title: 'Heading 6',
      class: 'ck-heading_heading6'
    }
  ]
}

export const CKEditorLink = {
  addTargetToExternalLinks: true,
  defaultProtocol: 'https://'
}

export const CKEditorTable = {
  contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
}
export const CKEditorMenuBar = {
  isVisible: true
}

export const CKEditorConfig: Ck5P.EditorConfig = {
  plugins: CKEditorPlugins,
  alignment: CKEditorAlignment,
  toolbar: CKEditorToolbar,
  heading: CKEditorHeading,
  image: CKEditorImage,
  link: CKEditorLink,
  table: CKEditorTable,
  menuBar: CKEditorMenuBar,
  simpleUpload: {
    uploadUrl: API_BASE_URL + '/fileUpload?editorUpload=true',
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + getToken()
    }
  },
  list: {
    properties: {
      styles: true,
      startIndex: true,
      reversed: true
    }
  },
  fontFamily: {
    options: [
      'Georgia, serif',
      'Arial, Helvetica, sans-serif',
      'Courier New, Courier, monospace',
      'Lucida Sans Unicode, Lucida Grande, sans-serif',
      'Tahoma, Geneva, sans-serif',
      'Times New Roman, Times, serif',
      'Trebuchet MS, Helvetica, sans-serif',
      'Verdana, Geneva, sans-serif'
    ],
    supportAllValues: true
  }
}
