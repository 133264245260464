interface LabelPropsType {
  structure: {
    isRequired?: boolean
    label?: string
    [key: string]: any
  }
  classes?: string
}

function Label({ structure, classes }: LabelPropsType) {
  if (structure.labelPlacement !== 'hidden_label') {
    return (
      <div className={`flex justify-start w-[30%] md:w-full ${classes ? classes : ''}`}>
        <h1 className='form-label'>{structure?.label}</h1>
        {structure?.isRequired && <div className='ml-1 text-red-700'>*</div>}
      </div>
    )
  }
  return null
}

export default Label
