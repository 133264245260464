import AdminSetting from '.'
import { Tabs, Tab, Box } from '@mui/material'
import { useState } from 'react'
import BasicSettings from './BasicSettings'
import Institute from './BasicSettings/Institute'
import Session from './BasicSettings/Session'
import Program from './BasicSettings/Program'
import ConsulateJurisdiction from './BasicSettings/ConsulateJurisdiction'
import AltConsulateJurisdiction from './BasicSettings/AltConsulateJurisdiction'
import BatchVisaProcess from './BasicSettings/BatchVisaProcess'
import StatusInfo from './BasicSettings/StatusInfo'

interface BasicSettingTabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function BasicSettingTabPanel(props: BasicSettingTabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ pt: 3, pl: 4 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

export default function BasicSettingTab() {
  const [tabIndex, setTabIndex] = useState<number>(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue)
  }

  return (
    <>
      <div className='flex w-full min-h-[calc(100vh-77px)] lg:flex-wrap lg:min-h-full' data-cy='basic-setting-page'>
        <AdminSetting />
        <div className='w-full p-4 md:px-2'>
          <Box sx={{ width: '100%' }}>
            <Box>
              <Tabs
                value={tabIndex}
                className='tab-list'
                onChange={handleChange}
                aria-label='basic setting tabs'
                variant='scrollable'
                allowScrollButtonsMobile
                sx={{ pl: 3 }}
              >
                <Tab label='Basic Setting' {...a11yProps(0)} data-cy='basic-setting' />
                <Tab label='Institutes' {...a11yProps(1)} data-cy='institutes' />
                <Tab label='Sessions' {...a11yProps(2)} data-cy='sessions' />
                <Tab label='Academic Programs' {...a11yProps(3)} data-cy='academic-programs' />
                <Tab label='Consulate Jurisdiction' {...a11yProps(4)} data-cy='consulate-jurisdiction' />
                <Tab label='Alt Consulate Jurisdiction' {...a11yProps(5)} data-cy='alt-consulate-jurisdiction' />
                <Tab label='Batch Visa Process' {...a11yProps(6)} data-cy='batch-visa-process' />
                <Tab label='Status Info' {...a11yProps(7)} data-cy='status-info' />
              </Tabs>
            </Box>
            <BasicSettingTabPanel value={tabIndex} index={0}>
              <BasicSettings />
            </BasicSettingTabPanel>
            <BasicSettingTabPanel value={tabIndex} index={1}>
              <Institute />
            </BasicSettingTabPanel>
            <BasicSettingTabPanel value={tabIndex} index={2}>
              <Session />
            </BasicSettingTabPanel>
            <BasicSettingTabPanel value={tabIndex} index={3}>
              <Program />
            </BasicSettingTabPanel>
            <BasicSettingTabPanel value={tabIndex} index={4}>
              <ConsulateJurisdiction />
            </BasicSettingTabPanel>
            <BasicSettingTabPanel value={tabIndex} index={5}>
              <AltConsulateJurisdiction />
            </BasicSettingTabPanel>
            <BasicSettingTabPanel value={tabIndex} index={6}>
              <BatchVisaProcess />
            </BasicSettingTabPanel>
            <BasicSettingTabPanel value={tabIndex} index={7}>
              <StatusInfo />
            </BasicSettingTabPanel>
          </Box>
        </div>
      </div>
    </>
  )
}
