interface FormDocumentViewerProp {
  src: string
  srcDoc?: string
  title: string
  isPDF: boolean
}

export default function FormDocumentViewer({ src, srcDoc, title, isPDF }: FormDocumentViewerProp) {
  return (
    <>
      {isPDF ? (
        <iframe src={src} srcDoc={srcDoc} title={title} className='size-full min-h-full min-w-full'></iframe>
      ) : (
        <img src={src} title={title} className='size-full min-h-full min-w-full object-contain' />
      )}
    </>
  )
}
