import http from 'CommonJS/http'
import { useContext, useEffect, useState } from 'react'
import { FaTrashAlt } from 'react-icons/fa'
import ReactJsPagination from 'react-js-pagination'
import './index.css'
import ConfirmationAlert from 'Components/ConfirmationAlert'
import Loading from 'Components/Loading'
import { toast } from 'Components/Toast/toast'
import { StudentViewData, StudentViews } from './type'
import { Link } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'
import { forVisaPortalStudentListParam } from 'CommonJS/common'
import { AxiosResponse } from 'axios'
import { AppContext } from 'Context/AppContext'
import { AppContextType } from 'Context/AppTypes'

export default function StudentView() {
  const [studentViews, setStudentViews] = useState<StudentViews>()
  const [visaStudentViews, setVisaStudentViews] = useState<StudentViews>()
  const [isConfirmAlertOpen, setConfirmAlertOpen] = useState<boolean>(false)
  const [deleteStudentView, setDeleteStudentView] = useState<StudentViewData | null>(null)
  const [isLoading, setLoading] = useState<boolean>(false)
  const { visaPortalSetting, getVisaPortalSetting } = useContext(AppContext) as AppContextType

  useEffect(() => {
    if (Object.keys(visaPortalSetting ?? {})?.length === 0) {
      getVisaPortalSetting()
    }
    getStudentViewData()
    getStudentViewData(1, true)
  }, [])

  useEffect(() => {
    if (visaPortalSetting) {
      document.documentElement.style.setProperty('--local-theme-color', visaPortalSetting.themeColor)
      document.documentElement.style.setProperty('--local-font-color', visaPortalSetting.fontColor)
    }
  }, [visaPortalSetting])

  const getStudentViewData = async (pageNumber = 1, visaPortalView: boolean = false) => {
    setLoading(true)
    await http
      .fetch({ path: `student/view?page=${pageNumber}`, options: { params: forVisaPortalStudentListParam(visaPortalView) } })
      .catch(() => {
        toast('Internal Server Error.', 'error')
        setLoading(false)
      })
      .then((response: void | AxiosResponse<{ studentViews: StudentViews }>) => {
        setLoading(false)
        if (response && response.status === 200) {
          if (visaPortalView) {
            setVisaStudentViews(response.data.studentViews)
          } else {
            setStudentViews(response.data.studentViews)
          }
        }
      })
  }

  const handleDeleteStudentView = async () => {
    if (!deleteStudentView) {
      toast('Something went wrong please try again', 'error')
      return
    }
    await setLoading(true)
    await http
      .fetch({ method: 'delete', path: `student/view/${deleteStudentView.id}` })
      .catch((error) => {
        toast(error?.data?.message ?? 'Internal server error', 'error')
        setLoading(false)
        setConfirmAlertOpen(false)
        setDeleteStudentView(null)
      })
      .then((response: any) => {
        setLoading(false)
        if (response && response.status === 200) {
          toast(response?.data?.message || 'Successfully deleted student view', 'success')
          getStudentViewData(1, deleteStudentView.isVisaDashboard)
          setConfirmAlertOpen(false)
          setDeleteStudentView(null)
        }
      })
    await setLoading(false)
  }

  const handleChange = (newField: StudentViewData, refreshTable: boolean = true) => {
    const newData = newField
    http
      .fetch({ method: `put`, path: `/student/view/${newField?.id}`, data: newData })
      .catch((error: any) => {
        toast(error.response?.data?.message || 'Internal server error', 'error')
      })
      .then((response: any) => {
        setLoading(false)
        if (response && response.status === 200) {
          toast(response?.data?.message || 'Successfully updated data', 'success')
          if (refreshTable) {
            getStudentViewData()
          }
        }
      })
  }
  return (
    <>
      {isLoading && <Loading />}
      <div className='px-7 py-5 w-full lg:px-4 min-w-[calc(100%-15rem)]' data-cy='student-view-page'>
        <div className='flex justify-between items-center'>
          <h1 className='text-3xl font-bold decoration-1'>Admission Student View</h1>
          <Link to='/admin/student'>
            <button className='btn-primary flex items-center gap-x-2' data-cy='go-back'>
              <IoIosArrowBack />
              Back
            </button>
          </Link>
        </div>
        <div className='overflow-auto'>
          <table className='w-full mt-5 lg:w-max' data-cy='student-view-table'>
            <thead>
              <tr className='[&>*]:bg-primary [&>*]:text-white [&>*]:tracking-wider [&>*]:py-3 [&>*]:border-slate-500 [&>*]:text-left'>
                <th>#</th>
                <th>Title</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {studentViews?.data.length === 0 || !studentViews?.data ? (
                <>
                  <tr>
                    <td colSpan={3}>No Admission Student View Found</td>
                  </tr>
                </>
              ) : (
                <>
                  {studentViews?.data.map((studentView, index) => (
                    <tr key={studentView.id}>
                      <td data-key={studentView.id}>
                        <strong>{(studentViews?.currentPage - 1) * studentViews?.perPage + index + 1}</strong>
                      </td>
                      <td>
                        <input
                          type='text'
                          className='form-input'
                          data-cy='student-view-title'
                          defaultValue={studentView?.title}
                          placeholder={`Enter ${studentView?.title}`}
                          onBlur={(e) => {
                            const updatedField = { ...studentView, title: e.target.value }
                            setLoading(true)
                            handleChange(updatedField)
                          }}
                          name={'title'}
                        />
                      </td>
                      <td>
                        <div className='flex gap-5 items-center'>
                          <button
                            className='btn btn-danger flex items-center gap-x-3'
                            data-cy='student-view-delete'
                            onClick={() => {
                              setDeleteStudentView(studentView)
                              setConfirmAlertOpen(true)
                            }}
                          >
                            Delete <FaTrashAlt size={14} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
        {Boolean(studentViews?.data?.length ?? 0 > 0) && (
          <div className='mr-10 [&>ul]:flex [&>ul]:gap-3 [&>*_a]:py-2 [&>*_a]:px-4 [&>*_a]:lg:py-1 [&>*_a]:lg:px-3 [&>*_a]:inline-block mt-4 [&>*_a]:border [&>*_a:hover]:bg-primary [&>*_a:hover]:text-white [&>*_li.active>a]:bg-primary [&>*_li.active>a]:text-white'>
            <ReactJsPagination
              activePage={studentViews?.currentPage || 1}
              itemsCountPerPage={studentViews?.perPage}
              totalItemsCount={studentViews?.total || 0}
              pageRangeDisplayed={studentViews?.to}
              onChange={(pageNumber) => {
                getStudentViewData(pageNumber)
              }}
            />
          </div>
        )}
        <div className='flex justify-between items-center mt-3 md:mt-6'>
          <h1 className='text-3xl font-bold decoration-1'>Visa Student View</h1>
        </div>
        <div className='overflow-auto'>
          <table className='w-full mt-5 lg:w-max' data-cy='visa-student-table'>
            <thead>
              <tr className='[&>*]:bg-[var(--local-theme-color)] [&>*]:text-white [&>*]:tracking-wider [&>*]:py-3 [&>*]:border-slate-500 [&>*]:text-left'>
                <th>#</th>
                <th>Title</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {visaStudentViews?.data.length === 0 || !visaStudentViews?.data ? (
                <>
                  <tr>
                    <td colSpan={3}>No Visa Student View Found</td>
                  </tr>
                </>
              ) : (
                <>
                  {visaStudentViews?.data.map((studentView, index) => (
                    <tr key={studentView.id}>
                      <td data-key={studentView.id}>
                        <strong>{(visaStudentViews?.currentPage - 1) * visaStudentViews?.perPage + index + 1}</strong>
                      </td>
                      <td>
                        <input
                          type='text'
                          className='form-input'
                          data-cy='visa-student-title'
                          defaultValue={studentView?.title}
                          placeholder={`Enter ${studentView?.title}`}
                          onBlur={(e) => {
                            const updatedField = { ...studentView, title: e.target.value }
                            setLoading(true)
                            handleChange(updatedField)
                          }}
                          name={'title'}
                        />
                      </td>
                      <td>
                        <div className='flex gap-5 items-center'>
                          <button
                            className='btn btn-danger flex items-center gap-x-3'
                            data-cy='visa-student-delete'
                            onClick={() => {
                              setDeleteStudentView(studentView)
                              setConfirmAlertOpen(true)
                            }}
                          >
                            Delete <FaTrashAlt size={14} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
        {Boolean(visaStudentViews?.data?.length ?? 0 > 0) && (
          <div className='mr-10 [&>ul]:flex [&>ul]:gap-3 [&>*_a]:py-2 [&>*_a]:px-4 [&>*_a]:lg:py-1 [&>*_a]:lg:px-3 [&>*_a]:inline-block mt-4 [&>*_a]:border [&>*_a:hover]:bg-[var(--local-theme-color)] [&>*_a:hover]:text-white [&>*_li.active>a]:bg-[var(--local-theme-color)] [&>*_li.active>a]:text-white'>
            <ReactJsPagination
              activePage={visaStudentViews?.currentPage || 1}
              itemsCountPerPage={visaStudentViews?.perPage}
              totalItemsCount={visaStudentViews?.total || 0}
              pageRangeDisplayed={visaStudentViews?.to}
              onChange={(pageNumber) => {
                getStudentViewData(pageNumber, true)
              }}
            />
          </div>
        )}
        <ConfirmationAlert
          title='Are you sure you perform this action ?'
          isConfirmModalOpen={isConfirmAlertOpen}
          setConfirmModalOpen={(val: boolean) => {
            setConfirmAlertOpen(val)
            if (!val) setDeleteStudentView(null)
          }}
          ButtonText={'Yes I am sure'}
          actionName='Delete student view'
          onAction={handleDeleteStudentView}
        />
      </div>
    </>
  )
}
