import { useMemo } from 'react'
import { Link } from 'react-router-dom'

interface ExternalLink {
  link: string
  target?: string
  label?: string
  className?: string
  noLinkClickCallBack?: (link: string) => void
}

export function ExternalLink({ link, target = '_blank', label = 'View', className = '', noLinkClickCallBack }: ExternalLink) {
  const redirectLink = useMemo(() => {
    if (link) {
      return /^(https?:\/\/)/.test(link || '') ? link || '' : '//' + link
    }
    return ''
  }, [link])

  return (
    <Link
      to={redirectLink}
      className={`text-blue-700 mx-auto ${className ? className : ''} ${!redirectLink ? 'cursor-not-allowed' : ''}`}
      target={target}
      onClick={(e) => {
        if (!redirectLink) {
          noLinkClickCallBack && noLinkClickCallBack(link)
          e.preventDefault()
        }
      }}
      title={redirectLink ? redirectLink : 'No Link'}
    >
      {label}
    </Link>
  )
}
