import { useContext, useEffect, useState } from 'react'
import http from 'CommonJS/http'
import { AppContext } from 'Context/AppContext'
import { AppContextType } from 'Context/AppTypes'
import { toast } from 'Components/Toast/toast'
import { FaEdit, FaEye, FaSave } from 'react-icons/fa'
// import TinyEditor from 'Components/TinyEditor'
import CKEditors from 'Components/CKEditor'
import Loading from 'Components/Loading'

export default function BasicSettings() {
  const [showEditor, setShowEditor] = useState<boolean>(false)
  const [intialValue, setIntialValue] = useState<string>('')
  const [type, setType] = useState<string>('')
  const [label, setLabel] = useState<string>('')
  const [view, setView] = useState<boolean>(false)
  const [data, setData] = useState<Data>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingSetting, setLoadingSetting] = useState<boolean>(false)
  const { templateVariables } = useContext(AppContext) as AppContextType

  interface DataItem {
    label: string
    type: string
    value: string
  }

  interface Data {
    [key: string]: DataItem
  }

  const submit = (content: string, settingType: string) => {
    setLoading(true)
    const payload = {
      [settingType]: content
    }
    http
      .fetch({ method: 'post', path: 'setting', data: payload })
      .catch((error) => {
        toast(error.response.data.message, 'error')
      })
      .then((response) => {
        setLoading(false)
        if (response && response.status === 200) {
          setData(response.data.setting)
          toast(response.data.message, 'success')
        }
      })
  }

  useEffect(() => {
    const init = async () => {
      await setting()
    }
    init()
  }, [])

  async function setting() {
    setLoadingSetting(true)
    http
      .fetch({ path: 'setting' })
      .catch(() => {
        toast('Internal server error.', 'error')
        setLoadingSetting(false)
      })
      .then((response) => {
        if (response && response.status === 200) {
          setData(response.data.setting)
        }
        setLoadingSetting(false)
      })
  }

  const handleEditCLick = (value: string, type: string, label: string) => {
    setShowEditor(true)
    setIntialValue(value)
    setType(type)
    setLabel(label)
    setView(false)
  }
  return (
    <>
      {loading && <Loading />}
      <div className='flex justify-between items-center min-h-12 mb-7 lg:mb-5'>
        <h1 className='text-3xl font-bold uppercase'>Basic Setting</h1>
      </div>
      {!showEditor && (
        <div id='basic-setting-content' className='w-full gap-y-4 flex flex-col'>
          {loadingSetting &&
            Array.from(Array(1)).map((_, index) => (
              <div
                key={index}
                className={`animate-pulse flex py-3 px-4 md:flex-wrap md:gap-3 justify-between border border-slate-200 max-w-4xl gap-7 rounded-md items-center shadow-md shadow-slate-50/10 hover:shadow-lg ${index % 2 === 0 ? 'bg-gray-50 shadow-sm' : ''}`}
              >
                <span className={`h-5 bg-primary/20 ${index % 2 === 0 ? 'w-48' : 'w-28'}`}></span>
                <div className='flex items-center justify-end gap-4'>
                  {Array.from(Array(2)).map((_, index) => (
                    <div key={index} className='btn-primary uppercase flex gap-3 items-center h-[42px] w-32 border-opacity-30'>
                      <span className='h-4 w-10 bg-primary/20 rounded'></span>
                      <span className='h-4 w-5 bg-primary/20 rounded-full'></span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          {Object.entries(data).map(([key, { label, type, value }], index) => (
            <div
              key={key}
              className={`flex py-3 px-4 md:flex-wrap md:gap-3 justify-between border border-slate-200 max-w-4xl gap-7 rounded-md items-center shadow-md shadow-slate-50/10 hover:shadow-lg ${index % 2 === 0 ? 'bg-gray-50 shadow-sm' : ''}`}
            >
              <span className='text-lg'>{label}</span>
              <div className={`flex items-center ${type == 'richTextEditor' ? 'justify-end' : 'justify-center'}`}>
                {type == 'richTextEditor' ? (
                  <button
                    data-cy='basic-setting-view-btn'
                    className='btn-primary uppercase flex gap-3 items-center'
                    onClick={() => {
                      handleEditCLick(value, key, label)
                      setView(true)
                    }}
                  >
                    View
                    <FaEye />
                  </button>
                ) : (
                  <input
                    type='text'
                    className='form-input'
                    defaultValue={value}
                    placeholder={`Enter ${label}`}
                    onChange={(e) =>
                      setData((prevData) => ({
                        ...prevData,
                        [key]: {
                          ...prevData[key],
                          value: e.target.value
                        }
                      }))
                    }
                    name={key}
                  />
                )}
                <div className='ml-4'>
                  {type == 'richTextEditor' ? (
                    <button
                      data-cy='basic-setting-edit-btn'
                      className='btn-primary uppercase flex gap-3 items-center'
                      onClick={() => {
                        handleEditCLick(value, key, label)
                        setView(false)
                      }}
                    >
                      Edit
                      <FaEdit />
                    </button>
                  ) : (
                    <button
                      className='btn-primary uppercase flex gap-3 items-center'
                      name='save'
                      data-cy='basic-setting-save-btn'
                      onClick={() => {
                        submit(value, key)
                      }}
                    >
                      Save
                      <FaSave />
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {showEditor && (
        <div className='w-full'>
          <CKEditors
            data-cy='tiny-text'
            initialValue={intialValue}
            type={type}
            label={label}
            callSubmit={submit}
            isView={view}
            variable={templateVariables}
            reSize={true}
            back={() => {
              setShowEditor(false)
            }}
            classes={{
              submitBtnBlock: '!border-0',
              viewBlock: '!h-[73vh]'
            }}
          />
        </div>
      )}
    </>
  )
}
