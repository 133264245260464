import { Tooltip } from '@mui/material'
import { BsQuestionCircleFill } from 'react-icons/bs'

export default function HomeCollegeHelp() {
  return (
    <Tooltip
      title={
        <>
          <div className='font-semibold'>Home University</div>
          <ul className='list-disc list-inside pl-2'>
            <li>Only first 100 university are shown for performance reasons</li>
            <li>You can type in the box to search for and find specific universities.</li>
          </ul>
        </>
      }
      classes={{ popper: '[&_div]:text-base [&>div]:max-w-[525px]' }}
      enterTouchDelay={1}
      placement='bottom'
      arrow
    >
      <span>
        <BsQuestionCircleFill className='size-5 md:size-7 mt-1 text-slate-400 cursor-pointer' />
      </span>
    </Tooltip>
  )
}
