import Echo from 'laravel-echo'
import Pusher, { ChannelAuthorizationCallback } from 'pusher-js'
import http from './http'
import PrivateChannel from 'pusher-js/types/src/core/channels/private_channel'
import Logo from 'assets/images/logo.png'

export async function initConnection(): Promise<boolean> {
  return new Promise<boolean>((resolve) => {
    if (!(window.Echo instanceof Echo)) {
      window.Pusher = Pusher
      window.Echo = new Echo({
        broadcaster: process.env.REACT_APP_REVERB_BROADCASTER,
        key: process.env.REACT_APP_REVERB_APP_KEY,
        cluster: process.env.REACT_APP_REVERB_CLUSTER,
        wsHost: process.env.REACT_APP_REVERB_HOST,
        wsPort: process.env.REACT_APP_REVERB_PORT,
        wssPort: process.env.REACT_APP_REVERB_PORT,
        forceTLS: (process.env.REACT_APP_REVERB_SCHEME ?? 'https') === 'https',
        enabledTransports: ['ws', 'wss'],
        authorizer: (channel: PrivateChannel) => {
          return {
            authorize: (socketId: string, callback: ChannelAuthorizationCallback) => {
              http
                .fetch({
                  path: '/broadcasting/auth',
                  method: 'post',
                  data: {
                    socket_id: socketId,
                    channel_name: channel.name
                  }
                })
                .then((response) => {
                  callback(null, response.data)
                })
                .catch((error) => {
                  callback(error, error)
                })
            }
          }
        }
      })
    }

    resolve(window.Echo instanceof Echo)
  })
}

export async function registerNotificationEvent(channel: string, callback?: (response: any) => void) {
  if (!(window.Echo instanceof Echo)) {
    await initConnection()
  }

  window.Echo?.private(channel).notification((notification: any) => {
    if (typeof callback === 'function') {
      callback(notification)
    }
  })
}

export function sendBrowserNotification(message: string) {
  const doNotify = (msg: string) => {
    new Notification(msg, {
      icon: Logo
    })
  }

  if (!('Notification' in window)) {
    // Check if the browser supports notifications
    // alert('This browser does not support desktop notification')
  } else if (Notification.permission === 'granted') {
    // Check whether notification permissions have already been granted;
    // if so, create a notification
    doNotify(message)
  } else if (Notification.permission !== 'denied') {
    // We need to ask the user for permission
    Notification.requestPermission().then((permission) => {
      // If the user accepts, let's create a notification
      if (permission === 'granted') {
        doNotify(message)
      }
    })
  }

  // At last, if the user has denied notifications
}
