import { getToken, hasAccess } from 'CommonJS/common'
import http from 'CommonJS/http'
import AdminLayout from 'Components/Layout/AdminLayout'
import DefaultLayout from 'Components/Layout/default'
import GuestLayout from 'Components/Layout/guest'
import { routes } from 'Components/Layout/routes'
import Login from 'Components/Login'
import ForgotPassword from 'Components/Login/forgotPassword'
import PrivateRoute from 'Components/PrivateRoute'
import { UserContext } from 'Context/UserContext'
import React, { useContext, useEffect, useState } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import ResetPassword from 'Components/Login/ResetPassword'
import AppProvider from 'Context/AppContext'
import { toast } from 'Components/Toast/toast'
import { AuthContext } from 'Context/AuthContext'
import { isManagerAdvisor, isSuperAdmin } from 'CommonJS/roleHelper'
import RolePermissionProvider from 'Context/RolePermission/RolePermissionContext'
import ConsulateAppointmentProvider from 'Context/VisaPortal/ConsulateAppointmentContext'

function App() {
  const { data, dispatch } = useContext(UserContext)
  const [token] = useState(getToken())
  const navigate = useNavigate()
  const location = useLocation()
  const { Logout } = useContext(AuthContext)

  useEffect(() => {
    if (location.pathname === '/admin/setting' || location.pathname === '/admin/setting/') navigate('/admin/setting/basic')

    if (location?.hash) {
      let iteration = 0
      // Retry for 3 times every second to wait for DOM render.
      const interval = setInterval(() => {
        iteration++
        const element = document.querySelector(location.hash)
        if (element) {
          clearInterval(interval)
          element.scrollIntoView({ behavior: 'smooth' })
        }
        if (iteration > 2) clearInterval(interval)
      }, 1000)
    }

    /**
     * Routes checked with RegExp
     * /admin/visa-dashboard/5 (Admin -> Visa Student Dashboard)
     * /visa-dashboard/5 (Student -> Visa Student Dashboard)
     * /dashboard-visa (Admin -> Visa Min Dashboard)
     * /admin/student (Admin -> Admission Student Listing)
     * /admin/visa-student (Admin -> Visa Student Listing)
     */
    const regex = new RegExp('^(/admin)?(/dashboard-visa)|(visa-student)|(/visa-dashboard(/\\d+)?)$', 'g')
    if (!regex.test(location.pathname)) {
      document.documentElement.style.setProperty('--theme-color', '#2d3e48')
      document.documentElement.style.setProperty('--font-color', '#000')
      document.documentElement.style.setProperty('--menu-color', '#fea087')
    }
  }, [location])

  useEffect(() => {
    if (token) {
      http
        .checkLogin()
        .then((rsp) => {
          if (rsp.data?.roles?.includes('student') && !rsp.data?.student) {
            toast('Your Application is withdrew. ', 'info', { persist: true })
            setTimeout(async () => {
              await Logout()
            }, 1000)
          }
          if (rsp.status === 200 && rsp.data?.id) {
            // Update user object.in state and in local storage
            dispatch({
              type: 'ADD',
              payload: rsp.data
            })
            if (location.pathname == '/login') {
              navigate(isSuperAdmin(data?.roles) || isManagerAdvisor(data?.roles) ? '/dashboard' : '/')
            }
            // Token is valid to redirect to home page
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            /**
             * Token is invalid remove it from localStorage
             * and redirect to login page
             */
            localStorage.clear()
            navigate('/login')
          }
        })
    }
  }, [token])

  return (
    <AppProvider>
      <RolePermissionProvider>
        <ConsulateAppointmentProvider>
          <Routes>
            <Route path='/login' key='login' element={<Login />} />
            <Route path='/forgot-password' key='forgot' element={<ForgotPassword />} />
            <Route path='/reset-password' element={<ResetPassword />} />
            <Route path='/api' element={<h1>Without login route</h1>} />

            {routes.map((route, index) => {
              return (
                <React.Fragment key={`route-${index}`}>
                  {route?.component ? (
                    <Route
                      path={route.path}
                      key={`${route.path}-${index}`}
                      element={
                        <PrivateRoute>
                          {route.layout === 'default' && hasAccess(route, data) ? (
                            <DefaultLayout>{route.component}</DefaultLayout>
                          ) : route.layout === 'admin' && hasAccess(route, data) ? (
                            <AdminLayout>{route.component}</AdminLayout>
                          ) : route.layout === 'guest' ? (
                            <GuestLayout>{route.component}</GuestLayout>
                          ) : (
                            <Navigate to={isSuperAdmin(data?.roles) || isManagerAdvisor(data?.roles) ? '/dashboard' : '/'} replace={true} />
                          )}
                        </PrivateRoute>
                      }
                    />
                  ) : (
                    <Route path={route.path} key={`${route.path}-${index}`}>
                      {route?.children?.map((subroute, _index) => {
                        return (
                          <Route
                            index={_index == 0 ? true : false}
                            path={`${route.path}${subroute.path}`}
                            element={
                              <PrivateRoute>
                                {hasAccess(route, data) ? (
                                  <AdminLayout>{subroute.component}</AdminLayout>
                                ) : (
                                  <Navigate
                                    to={isSuperAdmin(data?.roles) || isManagerAdvisor(data?.roles) ? '/dashboard' : '/'}
                                    replace={true}
                                  />
                                )}
                              </PrivateRoute>
                            }
                            key={_index}
                          />
                        )
                      })}
                    </Route>
                  )}
                </React.Fragment>
              )
            })}
            <Route path='*' element={<Navigate to='/' replace />} />
          </Routes>
        </ConsulateAppointmentProvider>
      </RolePermissionProvider>
    </AppProvider>
  )
}

export default App
