import { linkify } from 'CommonJS/common'

interface VisaPortalStepInstructionProp {
  name: string
  content: string | null
  onClose?: () => void
}

export default function VisaPortalStepInstruction({ name, content = '', onClose }: VisaPortalStepInstructionProp) {
  return (
    <div className='w-full h-full fixed justify-center items-center bg-black/50  inset-x-0 inset-y-0 border z-[190] p-10 flex md:p-5'>
      <div className='bg-white rounded-md p-5 relative overflow-hidden w-full h-full'>
        <label className='uppercase tracking-wide text-gray-500 text-sm mb-2'>{name}</label>
        <iframe
          className={`w-full h-[calc(100%-75px)] border border-slate-200 md:h-[calc(100vh-160px)]`}
          srcDoc={
            content
              ? linkify(content, { target: '_blank' }) + "<style> body{font-family:'Helvetica Neue',sans-serif;}</style>"
              : 'No content Found'
          }
        ></iframe>

        <div className='flex justify-center items-center gap-6 mt-5'>
          <button className='btn-primary' onClick={() => onClose && onClose()}>
            Close
          </button>
        </div>
      </div>
    </div>
  )
}
