import http from 'CommonJS/http'
import { useState, useEffect } from 'react'
import Loading from 'Components/Loading'
import AdminSetting from 'Components/AdminSetting'
import { FaEdit, FaTrashAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import ReactJsPagination from 'react-js-pagination'
import { toast } from 'Components/Toast/toast'
import ConfirmationAlert from 'Components/ConfirmationAlert'
import { DepartureGuide } from './type'
import { CiSquarePlus } from 'react-icons/ci'

export default function PreDepartureGuide() {
  const [departureGuides, setDepartureGuides] = useState<DepartureGuide>()
  const [loading, setLoading] = useState<boolean>(false)
  const [deleteDepartureGuideId, setDeleteDepartureGuideId] = useState<string>('')
  const [isConfirmAlertOpen, setConfirmAlertOpen] = useState<boolean>(false)

  useEffect(() => {
    getDepartureGuideData()
  }, [])

  const getDepartureGuideData = async (pageNumber = 1) => {
    await setLoading(true)
    await http
      .fetch({ path: `/setting/pre-departure-guide?page=${pageNumber}` })
      .catch(() => {
        toast('Internal Server Error.', 'error')
        setLoading(false)
      })
      .then((response: any) => {
        setLoading(false)
        if (response && response.status === 200) {
          setDepartureGuides(response.data.departureGuides)
        }
      })
    await setLoading(false)
  }

  const handleDeleteDepartureGuide = async () => {
    if (!deleteDepartureGuideId) {
      toast('Something went wrong please try again', 'error')
      return
    }
    await setLoading(true)
    await http
      .fetch({ method: 'delete', path: `/setting/pre-departure-guide/${deleteDepartureGuideId}` })
      .catch((error) => {
        toast(error?.data?.message ?? 'Internal server error', 'error')
        setLoading(false)
        setConfirmAlertOpen(false)
        setDeleteDepartureGuideId('')
      })
      .then((response: any) => {
        setLoading(false)
        if (response && response.status === 200) {
          toast(response?.data?.message || 'Successfully deleted insurance', 'success')
          getDepartureGuideData(1)
          setConfirmAlertOpen(false)
          setDeleteDepartureGuideId('')
        }
      })
    await setLoading(false)
  }
  return (
    <>
      {loading && <Loading />}
      <div className='flex w-full min-h-screen md:flex-wrap md:min-h-full lg:flex-col'>
        <AdminSetting />
        <div className='px-7 py-5 w-full lg:px-4 min-w-[calc(100%-15rem)]' data-cy='pre-departure-guide-page'>
          <div className='flex justify-between items-center gap-3 md:flex-wrap md:pb-4'>
            <h1 className='text-3xl font-bold decoration-1'>Pre Departure Guide</h1>
            <Link
              to='/admin/setting/pre-departure-guide/add'
              className='btn-primary bg-primary hover:bg-primary/90  text-white flex gap-x-2 items-center'
              data-cy='add-pre-departure-guide-link'
            >
              Add Pre Departure Guide
              <CiSquarePlus className='w-6 h-6' />
            </Link>
          </div>
          <div className='overflow-auto'>
            <table className='w-full mt-5 lg:w-max' data-cy='pre-departure-guide-table'>
              <thead>
                <tr className='[&>*]:bg-primary [&>*]:text-white [&>*]:tracking-wider [&>*]:py-3 [&>*]:border-slate-500 [&>*]:text-left'>
                  <th>Id</th>
                  <th>Title</th>
                  <th>Institute</th>
                  <th>Session</th>
                  <th>Year</th>
                  <th>Program</th>
                  <th>Sending Institution</th>
                  <th>Home University</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {departureGuides?.data.length === 0 || !departureGuides ? (
                  <>
                    <tr>
                      <td colSpan={7}>No Pre Departure Guide Found</td>
                    </tr>
                  </>
                ) : (
                  <>
                    {departureGuides?.data?.map((departureGuide) => (
                      <tr key={departureGuide.id}>
                        <td>{departureGuide?.id}</td>
                        <td>{departureGuide?.title ?? '-'}</td>
                        <td>{departureGuide?.institute.length > 0 ? departureGuide?.institute.join(', ') : '-'}</td>
                        <td>{departureGuide?.session.length > 0 ? departureGuide?.session.join(', ') : '-'}</td>
                        <td>{departureGuide?.year.length > 0 ? departureGuide?.year.join(', ') : '-'}</td>
                        <td>{departureGuide?.program.length > 0 ? departureGuide?.program.join(', ') : '-'}</td>
                        <td>{departureGuide?.sendingInstitution.length > 0 ? departureGuide?.sendingInstitution.join(', ') : '-'}</td>
                        <td>{departureGuide?.homeCollege.length > 0 ? departureGuide?.homeCollege.join(', ') : '-'}</td>
                        <td>
                          <div className='flex gap-5 items-center'>
                            <Link
                              to={`/admin/setting/pre-departure-guide/edit/${departureGuide?.id}`}
                              className='btn btn-primary flex items-center gap-x-3'
                              data-cy='pre-departure-guide-edit'
                            >
                              Edit <FaEdit />
                            </Link>
                            <button
                              className='btn btn-danger flex items-center gap-x-3'
                              data-cy='pre-departure-guide-delete'
                              onClick={() => {
                                setDeleteDepartureGuideId(departureGuide?.id)
                                setConfirmAlertOpen(true)
                              }}
                            >
                              Delete <FaTrashAlt size={14} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
          {Boolean(departureGuides?.data?.length ?? 0 > 0) && (
            <div className='mr-10 [&>ul]:flex [&>ul]:gap-3 [&>*_a]:py-2 [&>*_a]:px-4 [&>*_a]:lg:py-1 [&>*_a]:lg:px-3 [&>*_a]:inline-block mt-4 [&>*_a]:border [&>*_a:hover]:bg-primary [&>*_a:hover]:text-white [&>*_li.active>a]:bg-primary [&>*_li.active>a]:text-white'>
              <ReactJsPagination
                activePage={departureGuides?.currentPage || 1}
                itemsCountPerPage={departureGuides?.perPage}
                totalItemsCount={departureGuides?.total || 0}
                pageRangeDisplayed={departureGuides?.to}
                onChange={(pageNumber) => {
                  getDepartureGuideData(pageNumber)
                }}
              />
            </div>
          )}
        </div>
      </div>
      <ConfirmationAlert
        title='Are you sure you perform this action ?'
        isConfirmModalOpen={isConfirmAlertOpen}
        setConfirmModalOpen={(val: boolean) => {
          setConfirmAlertOpen(val)
          if (!val) setDeleteDepartureGuideId('')
        }}
        ButtonText={'Yes I am sure'}
        actionName='Delete pre departure guide'
        onAction={handleDeleteDepartureGuide}
      />
    </>
  )
}
