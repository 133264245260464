import { useContext, useEffect, useState } from 'react'
import http from 'CommonJS/http'
import './index.css'
import { ReactComponent as NewApplication } from 'assets/images/new-application.svg'
import { ReactComponent as WithdrawnApplication } from 'assets/images/withdrawn-application.svg'
import { ReactComponent as CompleteApplication } from 'assets/images/complete-application.svg'
import { ReactComponent as TotalApplication } from 'assets/images/total-application.svg'
import { Autocomplete, createFilterOptions, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import Loading from 'Components/Loading'
import { AppContext } from 'Context/AppContext'
import { AppContextType } from 'Context/AppTypes'
import { DataItem, IconBgMap, IconMap, valueType } from './type'
import { toast } from 'Components/Toast/toast'
import { Link } from 'react-router-dom'
import { MdOutlinePendingActions, MdPayment } from 'react-icons/md'
import { RiBillLine } from 'react-icons/ri'
import { FaRegMoneyBillAlt } from 'react-icons/fa'
import { isStudyAbroadAdvisor } from 'CommonJS/roleHelper'
import { UserContext } from 'Context/UserContext'

export default function AdminDashboard() {
  const [data, setValue] = useState<Partial<valueType>>({
    instituteId: '',
    programId: '',
    sessionId: '',
    sendingInstitution: '',
    yearId: '',
    homeCollegeId: ''
  })

  interface SendingInstitutionOptionsType {
    text?: string
    value?: string
  }

  const [sendingInstitutionOptions, setSendingInstitution] = useState<SendingInstitutionOptionsType[]>([])

  const [loading, setLoading] = useState<boolean>(false)

  const iconBgMap: IconBgMap = {
    ai_outline_appstore_add: 'bg-primary/15',
    md_settings_applications: 'bg-blue-600/15',
    go_shield_check: 'bg-green-600/15',
    bi_money_withdraw: 'bg-red-600/15',
    go_shield_accepted: 'bg-green-300/15',
    go_shield_condition_accepted: 'bg-orange-300/15',
    go_shield_committed: 'bg-blue-900/15',
    md_outline_pending_actions: 'bg-[#FF6347]/15',
    md_payment: 'bg-[#32CD32]/15',
    ri_bill_line: 'bg-[#FF4500]/15',
    fa_reg_money_bill_alt: 'bg-[#228B22]/15'
  }
  const iconMap: IconMap = {
    ai_outline_appstore_add: <NewApplication className='w-14' />,
    md_settings_applications: <TotalApplication className='fill-blue-600 w-14' />,
    go_shield_check: <CompleteApplication className='fill-green-600 w-14' />,
    bi_money_withdraw: <WithdrawnApplication className='fill-red-600 w-14' />,
    go_shield_accepted: <CompleteApplication className='w-14 [&_*]:!stroke-green-300' />,
    go_shield_condition_accepted: <CompleteApplication className='w-14 [&_*]:!stroke-orange-600' />,
    go_shield_committed: <CompleteApplication className='w-14 [&_*]:!stroke-blue-900' />,
    md_outline_pending_actions: <MdOutlinePendingActions size={80} className='w-16 fill-[#FF6347]' />,
    md_payment: <MdPayment size={80} className='w-16 fill-[#32CD32]' />,
    ri_bill_line: <RiBillLine size={80} className='w-16 fill-[#FF4500]' />,
    fa_reg_money_bill_alt: <FaRegMoneyBillAlt size={80} className='w-16 fill-[#228B22]' />
  }

  const { institutes, sessions, programs, years, homeColleges, getInstitute, getPrograms, getSession, getYears, getHomeCollege } =
    useContext(AppContext) as AppContextType
  const [stats, setData] = useState<any>([])
  const [filterQueryParams, setFilterQueryParams] = useState('')
  const { data: loginUser } = useContext(UserContext)

  useEffect(() => {
    const init = async () => {
      await setLoading(true)
      if (institutes?.length === 0) getInstitute()
      if (programs?.length === 0) getPrograms()
      if (sessions?.length === 0) getSession()
      if (years?.length === 0) getYears()
      if (!isStudyAbroadAdvisor(loginUser.roles) && homeColleges?.length === 0) getHomeCollege()
      await setLoading(false)
    }
    init()
  }, [])

  useEffect(() => {
    const init = async () => {
      let filterableData = {}
      Object.keys(data).forEach((i) => {
        const val = (data as Array<string>)[i as any]
        if (val) {
          filterableData = {
            ...(filterableData as any),
            [i]: val
          }
        }
        return val ? { [i]: val } : null
      })
      const newQueryParams = new URLSearchParams(filterableData as any)
      setFilterQueryParams(newQueryParams.toString())

      await getDashboard()
    }
    init()
  }, [data])

  const getDashboard = () => {
    const paramsStringObject: Record<string, string> = {}
    if (data) {
      setLoading(true)
      Object.entries(data).forEach(([key, value]) => {
        if (value !== undefined && value !== null && value !== '') {
          paramsStringObject[key] = String(value)
        }
      })
    }
    http
      .fetch({ path: 'stats', options: { params: paramsStringObject } })
      .catch(() => {
        toast('Internal server error.', 'error')
        setLoading(false)
      })
      .then((response) => {
        setLoading(false)
        if (response && response.status === 200) {
          const rspData = response.data.data
          if (Object.keys(rspData).includes('sendingInstitutionOptions') && rspData?.sendingInstitutionOptions?.length > 0) {
            setSendingInstitution(rspData.sendingInstitutionOptions)
          }
          delete rspData?.sendingInstitutionOptions
          setData(Object.values(rspData))
        }
      })
  }

  const getItem = (item: DataItem): any => {
    let filterUrl = '/admin/student'
    if (item.filter) {
      filterUrl += `?${item.filter.key}=${item.filter.value}${filterQueryParams ? '&' + filterQueryParams : ''}`
    } else {
      filterUrl += `${item.applicationStatus ? '?application_status=' + item.applicationStatus + '&' + filterQueryParams : '?' + filterQueryParams}`
    }
    return filterUrl
  }

  return (
    <div className='px-7 w-full lg:px-4 py-5 relative' data-cy='dashboard-page'>
      <h1 className='text-3xl font-bold mb-7 md:mb-4'>Dashboard</h1>
      {loading && <Loading />}

      <div className='flex gap-4 justify-end mb-5 md:flex-col md:gap-1'>
        <FormControl sx={{ m: 1, minWidth: 80 }} className='!min-w-44'>
          <InputLabel id='admin-dashboard-sending-institution-filter-label'>Sending Institution</InputLabel>
          <Select
            labelId='admin-dashboard-sending-institution-filter-label'
            id='admin-dashboard-sending-institution-filter'
            data-cy='sending-institution'
            value={data?.sendingInstitution}
            onChange={(e) => {
              setValue((prevValue) => ({
                ...prevValue,
                sendingInstitution: e.target.value
              }))
            }}
            autoWidth
            label='Sending Institution'
          >
            <MenuItem value=''>None</MenuItem>
            {sendingInstitutionOptions?.map((option) => (
              <MenuItem key={option?.value} value={option?.value}>
                {option?.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 80 }} className='!min-w-36'>
          <InputLabel id='admin-dashboard-institute-filter-label'>Institutes</InputLabel>
          <Select
            labelId='admin-dashboard-institute-filter-label'
            id='admin-dashboard-institute-filter'
            data-cy='institutes'
            value={data?.instituteId}
            onChange={(e) => {
              setValue((prevValue) => ({
                ...prevValue,
                instituteId: e.target.value
              }))
            }}
            autoWidth
            label='Institutes'
          >
            <MenuItem value=''> None</MenuItem>
            {institutes?.map((institute) => (
              <MenuItem key={institute.id} value={institute.id}>
                {institute.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 80 }} className='!min-w-36'>
          <InputLabel id='admin-dashboard-program-filter-label'>Programs</InputLabel>
          <Select
            labelId='admin-dashboard-program-filter-label'
            id='admin-dashboard-program-filter'
            data-cy='programs'
            value={data?.programId}
            onChange={(e) => {
              setValue((prevValue) => ({
                ...prevValue,
                programId: e.target.value
              }))
            }}
            autoWidth
            label='Programs'
          >
            <MenuItem value=''> None</MenuItem>
            {programs?.map((institute) => (
              <MenuItem key={institute.id} value={institute.id}>
                {institute.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 80 }} className='!min-w-36'>
          <InputLabel id='admin-dashboard-session-filter-label'>Sessions</InputLabel>
          <Select
            labelId='admin-dashboard-session-filter-label'
            id='admin-dashboard-session-filter'
            data-cy='sessions'
            value={data?.sessionId}
            onChange={(e) => {
              setValue((prevValue) => ({
                ...prevValue,
                sessionId: e.target.value
              }))
            }}
            autoWidth
            label='Sessions'
          >
            <MenuItem value=''> None</MenuItem>
            {sessions?.map((institute) => (
              <MenuItem key={institute.id} value={institute.id}>
                {institute.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 80 }} className='!min-w-36'>
          <InputLabel id='admin-dashboard-year-filter-label'>Year</InputLabel>
          <Select
            labelId='admin-dashboard-year-filter-label'
            id='admin-dashboard-year-filter'
            data-cy='years'
            value={data?.yearId}
            onChange={(e) => {
              setValue((prevValue) => ({
                ...prevValue,
                yearId: e.target.value
              }))
            }}
            autoWidth
            label='year'
          >
            <MenuItem value=''>None</MenuItem>
            {years?.map((year) => (
              <MenuItem key={year.id} value={year.id}>
                {year.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {!isStudyAbroadAdvisor(loginUser.roles) && (
          <Autocomplete
            sx={{ m: 1, minWidth: 80 }}
            className='!min-w-60'
            id='admin-dashboard-homecollege-filter'
            data-cy='homecollege'
            options={homeColleges}
            getOptionLabel={(option: any) => option.name || ''}
            value={homeColleges.find((college) => college.id === data?.homeCollegeId) || null}
            disableCloseOnSelect={true}
            onChange={(event, newValue) => {
              setValue((prevValue) => ({
                ...prevValue,
                homeCollegeId: newValue ? newValue.id : ''
              }))
            }}
            filterOptions={createFilterOptions({
              limit: 100,
              trim: true
            })}
            componentsProps={{ popper: { id: 'course-schedule-home-college-popup' } }}
            renderInput={(params) => <TextField {...params} label='Home University' />}
          />
        )}
      </div>

      <div className='grid grid-cols-4 lg:grid-cols-2 md:grid-cols-1 gap-7'>
        {stats.length === 0 && (
          <>
            {[...Array(11)].map((item, index: number) => (
              <div key={index}>
                <div className='profile_card animate-pulse text-center'>
                  <div className='icon h-11 w-11 bg-primary/15'></div>
                  <div className='number h-9 w-14 rounded-sm bg-primary/15 mx-auto'></div>
                  <h3 className='title rounded-sm h-8 w-80 bg-primary/15 mx-auto'></h3>
                </div>
              </div>
            ))}
          </>
        )}

        {stats?.map((item: DataItem, index: number) => (
          <Link to={getItem(item)} key={index} className='profile_card text-center'>
            <div className={`icon ${iconBgMap[item.icon]}`}>{iconMap[item?.icon]}</div>
            <div className='number'>{item.total}</div>
            <h3 className='title'>{item.name}</h3>
          </Link>
        ))}
      </div>
    </div>
  )
}
