import { FaRegEnvelope, FaRegEnvelopeOpen } from 'react-icons/fa'
import NotificationType from './type'
import { FaXmark } from 'react-icons/fa6'
import http from 'CommonJS/http'
import { AxiosResponse } from 'axios'
import { toast } from 'Components/Toast/toast'

interface NotificationItemType {
  notification: NotificationType
  setLoading: (val: boolean) => void
  onUpdate: (notification: NotificationType) => void
  onDelete: (id: NotificationType['id']) => void
}

export default function NotificationItem({ notification, setLoading, onUpdate, onDelete }: NotificationItemType) {
  const isRead = notification.readAt !== null
  const hasLink = Boolean(notification.data?.link?.trim())

  /**
   * Handles updating the notification by sending a POST request to the server.
   *
   * @param {boolean} markRead - Whether to mark the notification as read or not. true == mark as read, false = mark as unread
   * @return {void}
   */
  function handleUpdate(markRead: boolean) {
    setLoading(true)
    http
      .fetch({
        path: `notifications/${notification.id}`,
        method: 'put',
        data: {
          markRead
        }
      })
      .then((response: void | AxiosResponse<{ message: string; notification: NotificationType }>) => {
        if (response && response.status === 200) {
          onUpdate(response?.data.notification)
          toast(response?.data.message ?? `Mark as ${markRead ? '' : 'un'}read successfully`, 'success')
        } else {
          setLoading(false)
        }
      })
      .catch(({ response }) => {
        setLoading(false)
        toast(response?.data.message ?? 'Internal server error.', 'error')
      })
  }

  function handleDelete() {
    setLoading(true)
    http
      .fetch({ path: `notifications/${notification.id}`, method: 'delete' })
      .then((response: void | AxiosResponse<{ message: string }>) => {
        if (response && response.status === 200) {
          onDelete(notification.id)
          toast(response?.data.message ?? 'Notification delete successfully', 'success')
        } else {
          setLoading(false)
        }
      })
      .catch(({ response }) => {
        setLoading(false)
        toast(response?.data.message ?? 'Internal server error.', 'error')
      })
  }

  return (
    <div
      className={`flex justify-between p-2 border-b border-gray-300 hover:bg-blue-100 cursor-pointer ${isRead ? 'bg-gray-200' : 'bg-white'}`}
    >
      <div className='flex gap-3 items-center max-w-[90%] min-w-[90%]'>
        {isRead ? (
          <button
            className='rounded-full size-12 text-center bg-primary/15 hover:shadow-lg border border-primary/25'
            title='Mark as unread'
            onClick={() => handleUpdate(false)}
          >
            <FaRegEnvelope className='size-6 mx-auto' />
          </button>
        ) : (
          <button
            className='rounded-full size-12 text-center bg-primary/15 hover:shadow-lg border border-primary/25'
            title='Mark as read'
            onClick={() => handleUpdate(true)}
          >
            <FaRegEnvelopeOpen className='size-6 mx-auto' />
          </button>
        )}
        <div className='flex flex-col'>
          <span className='text-sm text-gray-500'>{notification.createdAt} EST</span>
          <a
            href={hasLink ? notification.data?.link ?? '' : '#'}
            target={hasLink ? '_blank' : '_self'}
            rel='noreferrer'
            className={`line-clamp-2 ${isRead ? 'font-medium' : 'font-semibold'} ${hasLink ? 'text-blue-500 hover:underline underline-offset-2' : ''}`}
            onClick={() => !isRead && handleUpdate(true)}
          >
            {notification.data.title}
          </a>
          {notification.data?.subtitle && (
            <div
              className='text-sm [&_a]:text-blue-500 [&_a]:hover:underline [&_a]:underline-offset-2'
              dangerouslySetInnerHTML={{ __html: notification.data?.subtitle }}
            ></div>
          )}
        </div>
      </div>
      <button className='py-3 px-1.5' title='Delete' onClick={handleDelete}>
        <FaXmark className='size-6 text-red-500' />
      </button>
    </div>
  )
}
