import { UserRole } from 'Context/AppTypes'

export const isSuperAdmin = (userRole: UserRole[]): boolean => userRole.includes('superadmin')
export const isAdvisor = (userRole: UserRole[]): boolean => userRole.includes('advisor')
export const isStudent = (userRole: UserRole[]): boolean => userRole.includes('student')
export const isBasicUser = (userRole: UserRole[]): boolean => userRole.includes('basic_user')
export const isStudyAbroadAdvisor = (userRole: UserRole[]): boolean => userRole.includes('study_abroad_advisor')

// Check if a user is a advisor or sub-advisor (advisor or basic user or study abroad advisor role)
export const isSubAdvisor = (userRole: UserRole[]): boolean => Boolean(isBasicUser(userRole) || isStudyAbroadAdvisor(userRole))

// Check if a user is an admin (either superadmin or advisor)
export const isAdmin = (userRole: UserRole[]): boolean => Boolean(isSuperAdmin(userRole) || isAdvisor(userRole))

// Check if a user is a manager advisor (any advisory role, basic admin or study abroad advisor)
export const isManagerAdvisor = (userRole: UserRole[]): boolean => Boolean(isAdvisor(userRole) || isSubAdvisor(userRole))

// Lists of roles, useful for UI rendering or further checks
export const ManagersRoles: UserRole[] = ['superadmin', 'advisor', 'basic_user', 'study_abroad_advisor']
export const AdvisorsRoles: UserRole[] = ['advisor', 'basic_user', 'study_abroad_advisor']
export const Student: UserRole = 'student'
export const StudyAbroadAdvisor: UserRole = 'study_abroad_advisor'
export const BasicUser: UserRole = 'basic_user'
export const Advisor: UserRole = 'advisor'
export const Superadmin: UserRole = 'superadmin'
