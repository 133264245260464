interface Callback<T = any> {
  (detail: T): void
}

interface WrappedCallback {
  (e: Event): void
}

// Change to Map for iteration capability
// Map structure: eventType => (callback => wrappedCallback)
const eventListenersMap: Map<string, Map<Callback, WrappedCallback>> = new Map()

interface EventBusType {
  $on<T = any>(eventType: string, callback: Callback<T>): void
  $dispatch<T = any>(eventType: string, data: T): void
  $remove(eventType: string): void
}

const EventBus: EventBusType = {
  $on<T>(eventType: string, callback: Callback<T>): void {
    const wrappedCallback: WrappedCallback = (e: Event) => {
      callback((e as CustomEvent<T>).detail)
    }

    if (!eventListenersMap.has(eventType)) {
      eventListenersMap.set(eventType, new Map())
    }
    eventListenersMap.get(eventType)!.set(callback, wrappedCallback)

    document.addEventListener(eventType, wrappedCallback)
  },

  $dispatch<T>(eventType: string, data: T): void {
    const callbacksMap = eventListenersMap.get(eventType)
    if (!callbacksMap) {
      // eslint-disable-next-line no-console
      console.info(`%c[event-bus] %cNo event listener registered for '${eventType}'`, 'color: #2d3e48; font-weight: bold;', '')
      return
    }

    const event = new CustomEvent<T>(eventType, { detail: data })
    document.dispatchEvent(event)
  },

  $remove(eventType: string): void {
    const callbacksMap = eventListenersMap.get(eventType)
    if (!callbacksMap) return

    callbacksMap.forEach((wrappedCallback) => {
      document.removeEventListener(eventType, wrappedCallback)
    })

    // After removing all listeners for this eventType, remove its entry from the map
    eventListenersMap.delete(eventType)
  }
}

export default EventBus
