import Label from '../Label'
import { DetailPropsType } from '../type'
import { useContext, useEffect, useRef, useState } from 'react'
import { BiRefresh } from 'react-icons/bi'
import { subStepContext } from '../context'
import SignatureCanvas from 'react-signature-canvas'
import ReactSignatureCanvas from 'react-signature-canvas'
import { Controller } from 'react-hook-form'
import { IoIosSave } from 'react-icons/io'
import { imageUrlToDataURL, uploadFile } from 'CommonJS/common'
import { Tooltip } from '@mui/material'
import { BsQuestionCircleFill } from 'react-icons/bs'
import { toast } from 'Components/Toast/toast'
import Description from '../Description'

function SignatureType({ item, control, watch, isRenderConditionalComponent, setValue, getValues, isViewOnly, setError }: DetailPropsType) {
  const {
    state: { currentPage }
  } = useContext(subStepContext)
  const signatureRef = useRef<ReactSignatureCanvas | null>()
  const [isLoading, setLoading] = useState<boolean>(false)
  const [disableSave, setDisableSave] = useState<boolean>(true)
  const [helpOpenAlreadyOpen, setHelpOpenAlreadyOpen] = useState<boolean>(false)
  const [helpOpen, setHelpOpen] = useState<boolean>(false)

  useEffect(() => {
    const init = async () => {
      if (isRenderConditionalComponent && currentPage === item?.pageNumber && item?.visibility !== 'hidden') {
        if (getValues && getValues(item?.id?.toString())) {
          setLoading(true)
          try {
            const value = await getValues(item?.id?.toString())
            const base64 = await imageUrlToDataURL(value as string)
            signatureRef.current?.fromDataURL(base64, { height: 150, width: 300 })
          } catch (e) {
            toast('Something went wrong while rendering signature. Please try refreshing page.', 'error')
          } finally {
            setLoading(false)
          }
        }
      }
    }
    init()

    return () => {
      signatureRef?.current?.clear()
    }
  }, [item, currentPage])

  function dataURLtoBlob(dataurl: string): boolean | Blob {
    const arr = dataurl.split(',')
    const match = arr[0].match(/:(.*?);/)
    if (match && match.length > 0) {
      const mime = match[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], { type: mime })
    }

    return false
  }

  const onSave = async () => {
    if (signatureRef.current?.isEmpty()) {
      setError && setError(item?.id?.toString(), { type: 'manual', message: 'This field is required' })
      return
    }
    setError && setError(item?.id?.toString(), { type: 'manual', message: '' })

    const dataURI = await signatureRef?.current?.toDataURL('image/png')
    const blob = dataURLtoBlob(dataURI as string)
    const fileObj = await new File([blob as BlobPart], `test.png`, {
      type: 'image/png'
    })

    try {
      await setLoading(true)
      const res = await uploadFile(fileObj)
      if (res) await setValue(item?.id.toString(), res?.document?.fullPath)
      await setLoading(false)
      setDisableSave(true)
    } catch (error) {
      setValue(item?.id.toString(), '')
      setLoading(false)
    }
  }

  const showHelp = () => {
    // only auto show help tooltip once.
    if (helpOpenAlreadyOpen) return

    setHelpOpen(true)
    setHelpOpenAlreadyOpen(true)
    // auto hide tooltip after 5 seconds
    setTimeout(() => {
      setHelpOpen(false)
    }, 5000)
  }

  if (isRenderConditionalComponent === false || currentPage !== item?.pageNumber || item?.visibility === 'hidden') return null

  return (
    <div className='mt-2' id='signature-type'>
      <div className='flex justify-start md:flex-col'>
        <div className='flex justify-start w-[30%] gap-3 md:w-full items-start'>
          <Label structure={item} classes='w-auto' />
          <Tooltip
            open={helpOpen}
            onClose={() => setHelpOpen(false)}
            onOpen={() => setHelpOpen(true)}
            title='Before you submit the form, make sure to save your signature. Click "Save" icon below your signature to include it with the form.'
            classes={{ popper: '[&_div]:text-base' }}
            enterTouchDelay={1}
            placement='bottom'
            arrow
          >
            <span>
              <BsQuestionCircleFill className='size-5 md:size-7 mt-1 text-slate-400 cursor-pointer' />
            </span>
          </Tooltip>
        </div>

        <div>
          {isViewOnly ? (
            <div
              className={`h-[150px] w-[300px] border-2 border-dashed border-slate-400 ${watch(item?.id?.toString()) ? 'bg-loader' : ''}`}
            >
              {watch(item?.id?.toString()) && (
                <img
                  src={watch(item?.id?.toString())}
                  className='w-full h-full object-cover'
                  onLoad={(e) => (e.target as HTMLElement).classList.add('!bg-white')}
                />
              )}
            </div>
          ) : (
            <div className={`${isLoading ? 'animate-pulse' : ''}`}>
              <Controller
                control={control}
                name={item?.id?.toString()}
                rules={isViewOnly ? {} : { required: { value: item?.isRequired, message: 'This field is required.' } }}
                render={({ fieldState: { error } }) => {
                  return (
                    <div className='relative w-max'>
                      <SignatureCanvas
                        onBegin={showHelp}
                        onEnd={() => setDisableSave(false)}
                        penColor='black'
                        ref={(ref) => (signatureRef.current = ref)}
                        canvasProps={{
                          width: 300,
                          height: 150,
                          className: `sigCanvas border-2 border-dashed border-slate-400 ${item?.cssClass}`
                        }}
                      />
                      <div className='flex justify-between items-start mt-2'>
                        <span>
                          {error && <p className='text-left text-red-500 text-sm'>{error?.message}</p>}
                          <p className='max-w-60 font-normal text-xs text-orange-700'>{`*Before you submit the form, make sure to save your signature. Click "Save" icon above your signature to include it with the form.`}</p>
                        </span>
                        <div className='flex justify-end gap-3 mt-1'>
                          <button
                            type='button'
                            onClick={() => {
                              signatureRef.current?.clear()
                              setValue(item?.id?.toString(), '')
                            }}
                          >
                            <BiRefresh className='size-6 cursor-pointer' />
                          </button>
                          <button
                            className={`${disableSave ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
                            type='button'
                            onClick={() => !disableSave && onSave()}
                            disabled={disableSave}
                          >
                            <IoIosSave className='size-6' />
                          </button>
                        </div>
                        {isLoading && (
                          <div className=' flex justify-center items-center absolute top-0 right-0 left-0 bottom-0'>
                            <div className='spinner w-5 h-5 ' />
                          </div>
                        )}
                      </div>
                    </div>
                  )
                }}
              />
            </div>
          )}
          <Description structure={item} />
        </div>
      </div>
    </div>
  )
}

export default SignatureType
