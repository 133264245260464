import { useState } from 'react'
import { VisaPortalInfoSettingType } from './type'
import { FaEdit, FaEye, FaSave } from 'react-icons/fa'
import { FaX } from 'react-icons/fa6'
import Loading from 'Components/Loading'
// import TinyEditor from 'Components/TinyEditor'
import CKEditors from 'Components/CKEditor'
import http from 'CommonJS/http'
import { toast } from 'Components/Toast/toast'

interface VisaPortalInfoSettingProps {
  visaPortalSettings: VisaPortalInfoSettingType | undefined
  setVisaPortalSettings: (data: VisaPortalInfoSettingType) => void
}
export default function VisaPortalInfoSetting({ visaPortalSettings, setVisaPortalSettings }: VisaPortalInfoSettingProps) {
  const [loading, setLoading] = useState<boolean>(false)
  const [editData, setEditData] = useState<VisaPortalInfoSettingType | null>()
  const [editTitleError, setEditTitleError] = useState<string | null>(null)
  const [showEditor, setShowEditor] = useState<boolean>(false)
  const [viewOnly, setViewOnly] = useState<boolean>(false)

  function handleEdit(data: Partial<VisaPortalInfoSettingType>) {
    if ('visaTitle' in data && !data?.visaTitle) {
      setEditTitleError('Visa dashboard title field is required')
      return
    }
    setEditTitleError('')
    setLoading(true)

    http
      .fetch({ method: 'post', path: 'setting', data: data })
      .catch((error) => {
        setLoading(false)
        toast(error?.response?.data?.message, 'error')
      })
      .then((response) => {
        setLoading(false)
        if (response && response.status === 200) {
          toast(response?.data?.message, 'success')
          setVisaPortalSettings({
            ...visaPortalSettings,
            ...data
          } as VisaPortalInfoSettingType)
          setEditData(null)
          setShowEditor(false)
        }
      })
  }

  return (
    <div className='overflow-x-auto lg:w-full w-[50vw] relative'>
      {loading && <Loading className='!absolute' />}
      {!showEditor ? (
        <table className='table-auto w-full md:table md:w-max' data-cy='visa-portal-instruction-setting-table'>
          <thead>
            <tr>
              <th className='w-[46%]'>Visa Title</th>
              <th className='w-[35%]'>Action</th>
              <th>Visa Instructions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {editData ? (
                  <div>
                    <input
                      data-cy='visa-title'
                      type='text'
                      className={`form-input`}
                      placeholder={`Enter Visa Dashboard Title`}
                      name={'order'}
                      value={editData.visaTitle ?? ''}
                      onChange={(e) => {
                        const value = String(e.target.value) as string
                        setEditData(() => {
                          return {
                            ...editData,
                            visaTitle: value
                          }
                        })
                      }}
                    />
                    {editTitleError && (
                      <div className='text-left text-red-500 text-sm' data-cy='error'>
                        {editTitleError}
                      </div>
                    )}
                  </div>
                ) : (
                  <span>{visaPortalSettings?.visaTitle ?? '-'}</span>
                )}
              </td>
              <td>
                <div className='flex gap-3 justify-center'>
                  {editData ? (
                    <>
                      <button
                        className='btn btn-primary flex items-center gap-x-3'
                        data-cy='save-btn'
                        onClick={() => {
                          handleEdit({ visaTitle: editData.visaTitle })
                        }}
                      >
                        Save <FaSave />
                      </button>
                      <button
                        data-cy='cancel-btn'
                        className='btn btn-secondary flex items-center gap-x-3'
                        onClick={() => {
                          setEditData(null)
                        }}
                      >
                        Cancel <FaX />
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className='btn btn-primary flex items-center gap-x-3'
                        data-cy='edit-btn'
                        onClick={() => {
                          setEditData({
                            visaTitle: '',
                            visaInstruction: '',
                            ...(visaPortalSettings ?? {})
                          })
                        }}
                      >
                        Edit <FaEdit />
                      </button>
                    </>
                  )}
                </div>
              </td>
              <td>
                <div className='flex gap-2 justify-center'>
                  <button
                    data-cy='instruction-edit'
                    className='btn-primary uppercase flex gap-3 items-center p-3'
                    onClick={() => {
                      setShowEditor(true)
                      setEditData({
                        visaTitle: '',
                        visaInstruction: '',
                        ...(visaPortalSettings ?? {})
                      })
                      setViewOnly(false)
                    }}
                  >
                    <FaEdit />
                  </button>
                  <button
                    data-cy='instruction-view'
                    className='btn-primary uppercase flex gap-3 items-center p-3'
                    onClick={() => {
                      setShowEditor(true)
                      setEditData({
                        visaTitle: '',
                        visaInstruction: '',
                        ...(visaPortalSettings ?? {})
                      })
                      setViewOnly(true)
                    }}
                  >
                    <FaEye />
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      ) : (
        <>
          <div className='w-full h-[calc(100vh-260px)] min-w-[870px] md:min-w-full [&_iframe]:h-[calc(100%-60px)]'>
            <CKEditors
              initialValue={visaPortalSettings?.visaInstruction || ''}
              type={'instructions'}
              label='Visa portal - Instruction'
              callSubmit={(content) => handleEdit({ visaInstruction: content })}
              isView={viewOnly}
              variable={[]}
              classes={{
                editorBlock: 'h-[calc(100%-70px)]'
              }}
              back={() => {
                setShowEditor(false)
                setEditData(null)
              }}
            />
          </div>
        </>
      )}
    </div>
  )
}
