import { Link } from 'react-router-dom'
import { AdvisorType } from './type'
import noImagePlaceholder from '../../assets/images/no-image-placeholder.png'

interface AdvisorInfoProps {
  advisor?: AdvisorType
}

export default function AdvisorInfo({ advisor }: AdvisorInfoProps) {
  return (
    <div className='ml-auto lg:mt-2'>
      <div className='text-end me-4 font-semibold'>Your Admissions Advisor</div>
      {advisor ? (
        <div className='flex items-center'>
          <div className='text-end'>
            <div>{advisor?.name}</div>
            <div>
              <Link to={`mailto:${advisor?.email}`} className='link'>
                {advisor?.email}
              </Link>
            </div>
            <div>
              <Link to={advisor?.phoneNumber ? `tel:${advisor?.phoneNumber}` : '#'} className='link'>
                {advisor?.phoneNumber}
              </Link>
            </div>
          </div>
          <div className='ms-2'>
            {advisor?.photoLink ? (
              <img src={advisor?.photoLink ?? noImagePlaceholder} alt='no-image' className='w-28 h-28 rounded-full object-cover' />
            ) : (
              <img src={noImagePlaceholder} alt='no-image' className='w-28 h-28 rounded-full object-cover' />
            )}
          </div>
        </div>
      ) : (
        <div className='flex justify-center'>
          <div>No Advisor Assign</div>
        </div>
      )}
    </div>
  )
}
