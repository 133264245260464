import http from 'CommonJS/http'
import { AxiosResponse } from 'axios'
import React, { createContext } from 'react'
import { toast } from 'Components/Toast/toast'
import { PermissionType, RolePermissionTypes, RoleType } from './RolePermissionTypes'

export const RolePermissionContext = createContext<RolePermissionTypes | null>(null)

const RolePermissionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [roles, setRoles] = React.useState<RoleType[]>([])
  /**
   * Array of all available permissions
   */
  const [permissions, setPermissions] = React.useState<PermissionType[]>([])

  const getRoles = async () => {
    return http
      .fetch({ path: 'setting/roles' })
      .then((response: void | AxiosResponse<{ roles: RoleType[]; permissions: PermissionType[] }>) => {
        if (response && response.status === 200) {
          setRoles(response.data.roles)
          setPermissions(response.data.permissions)
        }
      })
      .catch(({ response }) => {
        toast(response?.data.message ?? 'Internal server error.', 'error')
      })
  }

  return (
    <RolePermissionContext.Provider
      value={{
        roles,
        permissions,
        getRoles
      }}
    >
      {children}
    </RolePermissionContext.Provider>
  )
}

export default RolePermissionProvider
