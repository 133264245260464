import http from 'CommonJS/http'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { FaEye, FaEyeSlash, FaSave } from 'react-icons/fa'
import { SetStateAction, useContext, useEffect, useState } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { removeBlankPairs } from 'CommonJS/common'
import noImagePlaceholder from '../../assets/images/no-image-placeholder.png'
import { Controller, useForm } from 'react-hook-form'
import ImageSelector from 'Components/ImageSelector'
import Loading from 'Components/Loading'
import { AppContext } from 'Context/AppContext'
import { AppContextType, UserRole } from 'Context/AppTypes'
import { AdvisorInputs } from './type'
import { toast } from 'Components/Toast/toast'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { UserContext } from 'Context/UserContext'
import { isStudyAbroadAdvisor, Superadmin } from 'CommonJS/roleHelper'

export default function AdvisorAddEdit() {
  const [showPassword, setShowPassword] = useState(false)
  interface Errors {
    name?: string[]
    email?: string[]
    phoneNumber?: string[]
    password?: string[]
    message?: string
    role?: string
    homeCollege?: string
  }
  const [error, setError] = useState<Errors>({})
  // const [errors, setErrors] = useState<Errors>({})
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const pathname = location.pathname
  const hasView = pathname.includes('view')
  const userId = searchParams.get('userId')
  const { uploadFile, userTypeInfo, getTypeInfo } = useContext(AppContext) as AppContextType
  const { data } = useContext(UserContext)
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setValue,
    control,
    watch
  } = useForm<AdvisorInputs>({
    defaultValues: {
      role: ''
    }
  })

  const [file, setFile] = useState<File>()
  const [imageSrc, setImageSrc] = useState(null)

  useEffect(() => {
    const init = async () => {
      if (Object.entries(userTypeInfo.roles).length === 0) await getTypeInfo()
    }
    init()
  }, [])

  useEffect(() => {
    if (userId !== undefined && userId !== null) {
      Number(userId) === data.id && !hasView ? navigate('/admin/users') : getAdvisorData()
    } else {
      reset({
        name: '',
        email: '',
        phoneNumber: '',
        password: '',
        photoId: ''
      })
    }
  }, [userId])

  const getAdvisorData = async () => {
    setLoading(true)
    await http
      .fetch({ path: `advisors/${userId}` })
      .catch(() => {
        toast('Internal Server Error.', 'error')
      })
      .then((response: any) => {
        setLoading(false)
        if (response && response.status === 200) {
          reset({
            name: response?.data?.advisor?.name,
            email: response?.data?.advisor?.email,
            phoneNumber: response?.data?.advisor?.advisor?.phoneNumber,
            password: '',
            photoId: response?.data?.advisor?.advisor?.photoLink,
            role: response?.data?.advisor?.roles[0],
            homeCollege: response?.data?.advisor?.homeCollegeId,
            sendingInstitution: response?.data?.advisor?.sendingInstitution
          })
          setImageSrc(response?.data?.advisor?.advisor?.photoLink)
        }
      })
  }

  const handleSave = async (info: any) => {
    const newData = info

    const path = userId ? `/advisors/${userId}` : '/advisors'
    const method = userId ? `put` : `post`
    await setLoading(true)

    if (file) {
      const fileRes = await uploadFile(file)
      if (fileRes) {
        setValue('photoId', fileRes?.document?.id)
        newData.photoLink = fileRes?.document?.id
        toast(fileRes?.message, 'success')
        await http
          .fetch({ method: method, path: path, data: removeBlankPairs(newData, 'password') })
          .then((response: any) => {
            if (response && response.status === 200) {
              navigate('/admin/users')
              toast(response?.data?.message || 'Successfully added data', 'success')
            }
          })
          .catch((error) => {
            setError(error.response.data.errors)
          })
      }
      await setLoading(false)
    } else {
      await http
        .fetch({ method: method, path: path, data: removeBlankPairs(newData, 'password') })
        .catch((error) => {
          setError(error.response.data.errors)
          setLoading(false)
        })
        .then((response: any) => {
          if (response && response.status === 200) {
            navigate('/admin/users')
            toast(response?.data?.message || 'Successfully added data', 'success')
          }
          setLoading(false)
        })
    }
    setLoading(false)
  }

  const changeFileState = (f: SetStateAction<File | undefined>) => {
    setFile(f)
  }

  const changeImageSrcState = (f: SetStateAction<null>) => {
    setImageSrc(f)
  }

  return (
    <div className='m-10 px-4 mx-auto lg:w-full lg:px-4' data-cy='advisor-add-edit-page'>
      {loading && <Loading />}
      <div className='flex justify-between items-center'>
        <h1 className='text-3xl font-bold' data-cy='title'>
          {hasView ? 'View User' : `${userId ? 'Edit' : 'Add'} User`}
        </h1>
        <Link to='/admin/users'>
          <button className='btn-primary flex items-center gap-x-2' data-cy='go-back'>
            <IoIosArrowBack />
            Back
          </button>
        </Link>
      </div>
      <form
        className='w-full p-7 md:p-4 rounded-md border border-slate-200 shadow-2xl shadow-primary/20 mt-10 max-w-4xl'
        onSubmit={handleSubmit(handleSave)}
      >
        <div className='flex flex-wrap mb-6 md:mb-3'>
          <div className='w-full mb-6 md:mb-3'>
            <label className='block uppercase tracking-wide text-gray-700 text-base font-semibold mb-2' htmlFor='grid-role'>
              Role
            </label>
            <FormControl fullWidth variant='outlined' disabled={hasView}>
              <InputLabel htmlFor='select-control-for-role'>Role</InputLabel>
              <Controller
                name='role'
                control={control}
                rules={{ required: 'Role Required' }}
                render={({ field: { onChange, value } }) => (
                  <Select value={value} onChange={onChange} label='Role' data-cy='user-role-dropdown' labelId='select-control-for-role'>
                    <MenuItem value=''>Select</MenuItem>
                    {userTypeInfo.roles?.map((role) => {
                      return (
                        <MenuItem key={role.key} value={role.key} data-cy={`user-role-item-${role.key}`}>
                          {role.value}
                        </MenuItem>
                      )
                    })}
                  </Select>
                )}
              />
            </FormControl>
            {errors?.role?.type == 'required' && (
              <p className='text-left text-red-500 text-sm' data-cy='error'>
                Role is required
              </p>
            )}
            {error.role && (
              <p className='text-red-500 text-xs italic' data-cy='error'>
                {error.role[0]}
              </p>
            )}
          </div>
          {isStudyAbroadAdvisor([watch('role') as UserRole]) && (
            <>
              <div className='w-full mb-6 md:mb-3'>
                <label className='block uppercase tracking-wide text-gray-700 text-base font-semibold mb-2' htmlFor='grid-role'>
                  Home College/University
                </label>
                <FormControl fullWidth variant='outlined' disabled={hasView}>
                  <InputLabel htmlFor='select-control-for-home-college'>Home College/University</InputLabel>
                  <Controller
                    name='homeCollege'
                    control={control}
                    rules={{
                      validate: () => {
                        const sendingInstitutionValue = watch('sendingInstitution')
                        const homeCollegeValue = watch('homeCollege')
                        if (!sendingInstitutionValue && !homeCollegeValue) {
                          return 'You must select either Home College/University or Sending Institution'
                        }
                      }
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        value={value ?? ''}
                        onChange={onChange}
                        label='Home College/University'
                        labelId='select-control-for-home-college'
                        data-cy='home-college-dropdown'
                      >
                        <MenuItem value={``}>Select</MenuItem>
                        {userTypeInfo.homeCollege?.map((homeCollege, index) => {
                          return (
                            <MenuItem key={index} value={homeCollege.id} data-cy='home-college-items'>
                              {homeCollege.name}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    )}
                  />
                </FormControl>
                {errors.homeCollege?.message && (
                  <p className='text-left text-red-500 text-sm' data-cy='error'>
                    {errors.homeCollege.message}
                  </p>
                )}
              </div>
              <div className='w-full mb-6 md:mb-3'>
                <label className='block uppercase tracking-wide text-gray-700 text-base font-semibold mb-2' htmlFor='grid-role'>
                  Sending Institution
                </label>
                <FormControl fullWidth variant='outlined' disabled={hasView}>
                  <InputLabel htmlFor='select-control-for-home-college'>Sending Institution</InputLabel>
                  <Controller
                    name='sendingInstitution'
                    control={control}
                    rules={{
                      validate: () => {
                        const homeCollegeValue = watch('homeCollege')
                        const sendingInstitutionValue = watch('sendingInstitution')
                        if (!homeCollegeValue && !sendingInstitutionValue) {
                          return 'You must select either Home College/University or Sending Institution'
                        }
                      }
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        value={value ?? ''}
                        onChange={onChange}
                        label='Sending Institution'
                        labelId='select-control-for-sending-institution'
                        data-cy='sending-institution-dropdown'
                      >
                        <MenuItem value={``}>None</MenuItem>
                        {userTypeInfo.sendingInstitution?.map((sendingInstitution, index) => {
                          return (
                            <MenuItem key={index} value={sendingInstitution.value} data-cy='sending-institution'>
                              {sendingInstitution.text}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    )}
                  />
                </FormControl>
                {errors.sendingInstitution?.message && (
                  <p className='text-left text-red-500 text-sm' data-cy='error'>
                    {errors.sendingInstitution.message}
                  </p>
                )}
              </div>
            </>
          )}
          <div className='w-full mb-6 md:mb-3'>
            <label className='block uppercase tracking-wide text-gray-700 text-base font-semibold mb-2' htmlFor='grid-first-name'>
              Name
            </label>
            <input
              className='form-input'
              id='grid-first-name'
              {...register('name', {
                required: true
              })}
              type='text'
              name='name'
              placeholder='Enter name'
              disabled={hasView}
              data-cy='name'
            />
            {errors?.name?.type == 'required' && (
              <p className='text-left text-red-500 text-sm' data-cy='error'>
                Name is required
              </p>
            )}
            {error.name && (
              <p className='text-red-500 text-xs italic' data-cy='error'>
                {error.name[0]}
              </p>
            )}
          </div>
          <div className='w-full mb-6 md:mb-3'>
            <label className='block uppercase tracking-wide text-gray-700 text-base font-semibold mb-2' htmlFor='grid-last-name'>
              Email
            </label>
            <input
              className='form-input'
              id='grid-last-name'
              type='email'
              disabled={hasView}
              {...register('email', {
                required: true
              })}
              placeholder='Enter email address'
              data-cy='email'
            />
            {errors?.email?.type == 'required' && (
              <p className='text-left text-red-500 text-sm' data-cy='error'>
                Email is required
              </p>
            )}
            {error.email && (
              <p className='text-red-500 text-xs italic' data-cy='error'>
                {error.email[0]}
              </p>
            )}
          </div>
          {watch('role') !== Superadmin && (
            <div className='w-full'>
              <label className='block uppercase tracking-wide text-gray-700 text-base font-semibold mb-2' htmlFor='grid-last-name'>
                Phone Number
              </label>
              <input
                className='form-input'
                id='grid-phone-number'
                type='text'
                disabled={hasView}
                {...register('phoneNumber', {
                  required: true
                })}
                placeholder='Enter phone number'
                data-cy='phone-number'
                onChange={(e) => {
                  // const isValid = /^\(?([2-9][0-8][0-9])\)?([2-9][0-9]{2})([0-9]{4})$/.test(e.target.value)
                  const isValid = String(e.target.value).trim()
                  setError({
                    ...error,
                    phoneNumber: isValid ? undefined : ['Invalid phone number format']
                  })
                }}
              />
              {errors?.phoneNumber?.type == 'required' && (
                <p className='text-left text-red-500 text-sm' data-cy='error'>
                  Phone number is required
                </p>
              )}
              {error.phoneNumber && (
                <p className='text-red-500 text-xs italic' data-cy='error'>
                  {error.phoneNumber[0]}
                </p>
              )}
            </div>
          )}
        </div>
        {!hasView ? (
          <>
            <div className='flex flex-wrap mb-6'>
              <div className='w-full'>
                <label className='block uppercase tracking-wide text-gray-700 text-base font-semibold mb-2' htmlFor='grid-password'>
                  Password
                </label>
                <div className='relative'>
                  <input
                    className='form-input pr-12 peer'
                    id='grid-password'
                    type={showPassword ? 'text' : 'password'}
                    {...register('password', {
                      required: userId ? false : true
                    })}
                    name='password'
                    placeholder='******************'
                    autoComplete='off'
                    readOnly={true}
                    data-cy='password'
                    onFocus={(e) => e.target.removeAttribute('readonly')}
                  />
                  <div
                    className='absolute top-0 right-0 bottom-0 border-l bg-white border border-gray-300 px-4 cursor-pointer rounded-r-md z-20 flex items-center [&_svg]:text-xl peer-focus:border-primary peer-focus:!border-l-gray-300'
                    data-cy='toggle-password'
                    onClick={() => {
                      setShowPassword(!showPassword)
                    }}
                  >
                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </div>
                </div>
                {errors?.password?.type == 'required' && (
                  <p className='text-left text-red-500 text-sm' data-cy='error'>
                    Password is required
                  </p>
                )}
                {error.password && (
                  <p className='text-red-500 text-xs italic' data-cy='error'>
                    {error.password[0]}
                  </p>
                )}
              </div>
            </div>
            {watch('role') !== Superadmin && (
              <div className='block mb-6'>
                <label className='block uppercase tracking-wide text-gray-700 text-base font-semibold mb-2' htmlFor='file_input'>
                  Upload file
                </label>
                <ImageSelector
                  size='110px'
                  changeFileState={changeFileState}
                  changeImageSrcState={changeImageSrcState}
                  imageSrc={imageSrc}
                />
              </div>
            )}
            {error.message && (
              <div className='text-left text-red-500 text-sm' data-cy='error'>
                <p>{error?.message}</p>
              </div>
            )}
            <div className='flex justify-center'>
              <button type='submit' className='btn-primary flex items-center gap-x-3' data-cy='save-btn'>
                Save <FaSave />
              </button>
            </div>
          </>
        ) : (
          <>
            {getValues('photoId') && (
              <div className='flex flex-col mb-6'>
                <label className='block uppercase tracking-wide text-gray-700 text-base font-semibold mb-2' htmlFor='grid-first-name'>
                  Image
                </label>
                <ImageSelector size='110px' isViewOnly={true} imageSrc={getValues('photoId') ?? noImagePlaceholder} />
              </div>
            )}
          </>
        )}
      </form>
    </div>
  )
}
