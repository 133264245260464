import { useContext } from 'react'
import Label from '../Label'
import { DetailPropsType } from '../type'
import { subStepContext } from '../context'

function PageType({ item, onNext, onPrevious, isViewOnly, setOpenModal }: DetailPropsType) {
  const {
    state: { currentPage, gravityForm }
  } = useContext(subStepContext)

  return (
    <div className='flex justify-center bg-white border-t py-4 absolute inset-x-0 bottom-0 md:p-2 md:justify-between'>
      <div className='flex justify-start'>
        <Label structure={item} />
      </div>
      <div className='flex justify-center gap-5 md:flex-wrap md:gap-2'>
        <div className='flex absolute top-0 bottom-0 left-5 items-center md:relative mx:min-w-full mx:w-full md:left-0'>
          <label className=' select-none'>{`Page ${currentPage} of ${gravityForm?.pagination.pages?.length}`}</label>
        </div>

        {currentPage !== 1 && item?.previousButton && (
          <button className='btn-primary mt-2 md:mt-0' id='previous' type='button' onClick={onPrevious}>
            {item?.previousButton?.text}
          </button>
        )}
        {item?.nextButton && currentPage !== gravityForm?.pagination?.pages?.length && (
          <button className='btn-secondary mt-2 md:mt-0' id='next' type='button' onClick={onNext}>
            {item?.nextButton?.text}
          </button>
        )}

        {gravityForm?.save?.button && !isViewOnly && (
          <button
            className={`mt-2 md:mt-0 ${item?.nextButton && currentPage !== gravityForm?.pagination?.pages?.length ? 'btn-primary' : 'btn-secondary'}`}
            id='save'
            type='submit'
          >
            {item?.nextButton && currentPage !== gravityForm?.pagination?.pages?.length ? (
              <span>{gravityForm?.save?.button?.text}</span>
            ) : (
              'SUBMIT'
            )}
          </button>
        )}

        {isViewOnly && (
          <button className='btn-primary mt-2 md:mt-0' type='reset' onClick={() => setOpenModal(false)}>
            Close
          </button>
        )}
      </div>
    </div>
  )
}
export default PageType
