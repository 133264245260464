import { lazy, SetStateAction, Suspense, useContext, useEffect, useState } from 'react'
import ImagePlaceholder from 'assets/images/no-image-placeholder.png'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { UserContext } from '../../../Context/UserContext'
import http from 'CommonJS/http'
import ImageSelector from 'Components/ImageSelector'
import Loading from 'Components/Loading'
import ConfirmationAlert from 'Components/ConfirmationAlert'
import { AppContext } from 'Context/AppContext'
import { AppContextType } from 'Context/AppTypes'
import { StudentInfo } from '../type'
import { toast } from 'Components/Toast/toast'
import Step from 'Components/Dashboard/Step'
import { StatusEnum } from 'CommonJS/StatusEnum'
import { getToken, setImpersonateInitiated, setToken, TIME_FORMAT_LABEL } from 'CommonJS/common'
import EventBus from 'CommonJS/eventBus'
import { isAdmin, isStudent, isSubAdvisor } from 'CommonJS/roleHelper'
const StudentActivityLog = lazy(() => import('./StudentActivityLog'))

export default function SideMenu() {
  const location = useLocation()
  const navigate = useNavigate()
  const { data, dispatch } = useContext(UserContext)
  const [studentInfo, setStudentInfo] = useState<StudentInfo>()
  const [imageSrc, setImageSrc] = useState(null)
  const [loading, setLoading] = useState<boolean>(false)
  const match = useLocation()
  const { id } = useParams()
  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false)
  const [action, setAction] = useState<string>('')
  const [actionLabel, setActionLabel] = useState<string>('')
  const { uploadFile } = useContext(AppContext) as AppContextType
  const [openStepModal, setStepOpenModal] = useState<boolean>(false)
  const [openActivityLog, setOpenActivityLog] = useState<boolean>(false)

  let studentId: any
  if (match.pathname.includes('student-dashboard')) {
    studentId = id
  } else if (match.pathname.includes('visa-dashboard') && id) {
    studentId = id
  } else {
    studentId = data?.student?.id
  }

  useEffect(() => {
    // No need to get student info here, it will be loaded from Student Component.
    // getStudentInfo()

    const updateMetaCallback = (data: any) => {
      setStudentInfo(data as StudentInfo)
      if (data?.profileImage) {
        setImageSrc(data?.profileImage)
      }
    }

    EventBus.$on('student:info-updated', updateMetaCallback)

    return () => {
      EventBus.$remove('student:info-updated')
    }
  }, [])

  async function getStudentInfo() {
    http
      .fetch({ path: `students/${studentId}` })
      .catch(() => {
        toast('Internal server error.', 'error')
      })
      .then((response) => {
        if (response && response.status === 200) {
          setStudentInfo(response.data.student)
          setImageSrc(response.data?.student?.profileImage)
        }
      })
  }

  const changeFileState = async (file: File) => {
    try {
      await setLoading(true)
      if (file) {
        const response = await uploadFile(file)
        if (response?.success) {
          const payload = {
            profile_image: response?.document.id
          }
          http
            .fetch({ method: 'put', path: `students/${studentId}/profileImage`, data: payload })
            .catch((error) => {
              toast(error?.response?.data?.message, 'error')
              setLoading(false)
            })
            .then((response) => {
              if (response && response.status === 200) {
                toast(response?.data?.message, 'success')
              }
              setLoading(false)
            })
        }
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const changeImageSrcState = (f: SetStateAction<null>) => {
    setImageSrc(f)
  }

  const performAction = () => {
    setLoading(true)
    http
      .fetch({ method: 'put', path: `students/${studentId}/${action}` })
      .catch(() => {
        toast('Internal server error.', 'error')
        setConfirmModalOpen(false)
      })
      .then((response) => {
        setLoading(false)
        if (response && response.status === 200) {
          toast(response?.data.message, 'success')
          if (['deleteApplication', 'archiveApplication'].includes(action)) {
            navigate('/admin/student')
          } else if (['unArchiveApplication', 'makeActive'].includes(action)) {
            navigate(0) // to simulate reload to prevent actions on dashboard
          } else if (['withdrawApplication'].includes(action)) {
            navigate('/login')
          } else getStudentInfo()
        }
        setConfirmModalOpen(false)
      })
  }

  const impersonateStudent = () => {
    setLoading(true)
    setImpersonateInitiated(true)
    http
      .fetch({ method: 'post', path: `impersonate/${studentId}` })
      .catch((error) => {
        toast(error?.data?.message || 'Internal server error.', 'error')
      })
      .then(async (response) => {
        setLoading(false)
        toast(response?.data.message, 'success')
        if (response && response.status === 200) {
          const adminUser = data
          const adminToken = getToken()

          // create new User object and save current admin access under impersonatedBy
          const studentUser = {
            ...response.data?.user,
            impersonatedBy: {
              user: adminUser,
              token: adminToken
            }
          }
          await localStorage.setItem('user', JSON.stringify(studentUser))
          await setToken(response.data.token)
          await dispatch({
            type: 'ADD',
            payload: studentUser
          })

          if (location.pathname.includes('/visa-dashboard')) {
            window.location.href = '/visa-dashboard'
          }
        }
      })
  }

  return (
    <div className='text-center flex flex-col justify-between min-h-full max-h-full w-96 border-r lg:w-full relative h-[calc(100vh-77px)] lg:h-full'>
      {loading && <Loading />}
      <div className='overflow-auto p-5 md:px-4'>
        <div className='mb-4'>
          <img src={studentInfo?.institute?.icon ?? ImagePlaceholder} alt='' className='w-full lg:w-1/2 lg:mx-auto md:lg:w-full' />
        </div>
        <div className='mb-4'>Date Started: {studentInfo?.createdAt} </div>
        <div className='mb-4'>
          <div className='text-secondary uppercase visa-portal-font-bg'>Institute</div>
          <h4 className='text-xl font-bold text-primary'>{studentInfo?.institute?.name}</h4>
        </div>
        <div className='mb-4'>
          <div className='text-secondary uppercase visa-portal-font-bg'>Session</div>
          <h4 className='text-xl font-bold text-primary'>{studentInfo?.studentMeta?.session}</h4>
        </div>
        <div className='mb-4'>
          <div className='text-secondary uppercase visa-portal-font-bg'>Year</div>
          <h4 className='text-xl font-bold text-primary'>{studentInfo?.studentMeta?.year}</h4>
        </div>
        <div className='mb-4'>
          <div className='text-secondary uppercase visa-portal-font-bg'>Program</div>
          <h4 className='text-xl font-bold text-primary'>{studentInfo?.studentMeta?.program}</h4>
        </div>

        <div className='mb-4 flex justify-center items-center'>
          <ImageSelector
            isViewOnly={studentInfo?.deletedAt !== null}
            size={'110px'}
            imageSrc={imageSrc}
            changeFileState={changeFileState}
            changeImageSrcState={changeImageSrcState}
          />
        </div>

        <div className='mb-4'>
          <h5 className='font-semibold text-xl'>{`${studentInfo?.studentMeta?.firstName} ${studentInfo?.studentMeta?.lastName}`}</h5>
          {studentInfo?.studentMeta?.nickname && (
            <h5 className='text-lg -mt-1' title={'Preferred First Name or Nickname'}>
              {studentInfo?.studentMeta?.nickname}
            </h5>
          )}
          <ul>
            <li>{studentInfo?.studentMeta?.preferredEmail}</li>
            {studentInfo?.alternateEmail && <li>{studentInfo?.alternateEmail}</li>}
            {studentInfo?.studentMeta?.phone && <li>{studentInfo?.studentMeta?.phone}</li>}
            {studentInfo?.studentMeta?.birth_day && <li>{studentInfo?.studentMeta?.birth_day}</li>}
            {studentInfo?.studentMeta?.gender && <li>{studentInfo?.studentMeta?.gender}</li>}
            <li>{studentInfo?.studentMeta?.preferredPronouns}</li>
          </ul>
          <ul className='navbar-nav'>
            {/* <li>
              <Link to='#'>US Home Address Street </Link>
            </li>
            <li>
              <Link to='#'>Us Home Address State, Zip</Link>
            </li>
            <li>
              <Link to='#'>US Home State</Link>
            </li>
            <li>
              <Link to='#'>US Home Country </Link>
            </li> */}
            <li>{studentInfo?.studentMeta?.countryCitizenship}</li>
          </ul>
        </div>
        <div className='mb-4'>
          <h5 className='font-semibold text-xl'>Academic Information</h5>
          <ul className='mb-4'>
            <li>{studentInfo?.studentMeta?.homeCollegeUniversity}</li>
            <li>{studentInfo?.studentMeta?.collegeMajor}</li>
            <li>{studentInfo?.studentMeta?.minor}</li>
            {studentInfo?.studentMeta?.cumulative_gpa && <li>{studentInfo?.studentMeta?.cumulative_gpa} </li>}
          </ul>
        </div>
        <div className='mb-4'>
          <h5 className='font-semibold text-xl'>Study Abroad Advisor</h5>
          {studentInfo?.studentMeta?.studyAbroadAdvisorEmail || studentInfo?.studentMeta?.studyAbroadAdvisorName ? (
            <ul className='mb-4'>
              {/* <li>
                <Link to='#'>Home University </Link>
              </li> */}
              <li>{studentInfo?.studentMeta?.studyAbroadAdvisorEmail ?? '-'}</li>
              <li>{studentInfo?.studentMeta?.studyAbroadAdvisorName ?? '-'}</li>
            </ul>
          ) : (
            <ul className='mb-4'>
              <li>No Advisor Assign</li>
            </ul>
          )}
        </div>
        <div className='mb-4'>
          <h5 className='font-semibold text-xl'>Last Login</h5>
          <div>
            {studentInfo?.user?.lastLogin ? (
              <>
                {studentInfo?.user?.lastLogin} {TIME_FORMAT_LABEL}
              </>
            ) : (
              '-'
            )}
          </div>
        </div>
      </div>

      <div className='py-5 sticky bottom-0 bg-white  w-full left-0 right-0 px-5 shadow-[1px_-3px_10px_0px_#f2f2f2] lg:static lg:flex md:flex-wrap lg:gap-4 lg:[&>*]:w-auto lg:[&>*]:m-0 lg:items-center lg:justify-center md:[&>*]:min-w-60'>
        {match.pathname.includes('student-dashboard') && isAdmin(data.roles) && (
          <Link
            data-cy='visa-portal-dashboard'
            className='inline-block w-full mb-3 btn-primary'
            to={`/admin/visa-dashboard${isStudent(data.roles) ? '' : '/' + studentId}`}
          >
            View Visa Portal Dashboard
          </Link>
        )}
        {studentInfo?.deletedAt ? (
          <>
            {!isStudent(data.roles) && match.pathname.includes('visa-dashboard') && (
              <Link
                className='inline-block w-full mb-3 btn-primary'
                to={`/admin/student-dashboard${isStudent(data.roles) ? '' : '/' + studentId}`}
              >
                View Admission Dashboard
              </Link>
            )}
            <button
              className={`w-full btn-secondary ${studentInfo.toBeDeleted ? 'disabled' : ''}`}
              onClick={() => {
                if (studentInfo.toBeDeleted) {
                  toast('Action cannot be performed. Student is scheduled for deletion.', 'warning')
                } else {
                  setConfirmModalOpen(true)
                  setAction('unArchiveApplication')
                  setActionLabel('Un-Archive Application')
                }
              }}
            >
              Un-Archive Application
            </button>
          </>
        ) : (
          <>
            {(isStudent(data.roles) || isSubAdvisor(data.roles)) && (
              <div
                className='mb-2 cursor-pointer hover:underline underline-offset-2'
                onClick={() => {
                  setStepOpenModal(true)
                  setAction('Cancellation&Withdraw')
                  setActionLabel('Cancellation & Withdraw Policies')
                }}
              >
                Cancellation & Withdraw Policies
              </div>
            )}
            {!isStudent(data.roles) && match.pathname.includes('visa-dashboard') && (
              <Link
                className='inline-block w-full mb-3 btn-primary'
                to={`/admin/student-dashboard${isStudent(data.roles) ? '' : '/' + studentId}`}
              >
                View Admission Dashboard
              </Link>
            )}
            {isStudent(data.roles) && (
              <>
                {studentInfo?.applicationStatus?.toLowerCase() != StatusEnum.COMPLETED && (
                  <button
                    className='w-full btn-secondary'
                    onClick={() => {
                      setConfirmModalOpen(true)
                      setAction('withdrawApplication')
                      setActionLabel('Withdraw Application')
                    }}
                  >
                    Withdraw My Application
                  </button>
                )}
              </>
            )}
            {isAdmin(data.roles) && (
              <>
                <button className='w-full mb-3 btn-primary' onClick={impersonateStudent}>
                  View as Student
                </button>
                {studentInfo?.applicationStatus == 'reject' && (
                  <button
                    className='w-full mb-3 btn-secondary'
                    onClick={() => {
                      setConfirmModalOpen(true)
                      setAction('makeActive')
                      setActionLabel('Make Active Student')
                    }}
                  >
                    Make Active Student
                  </button>
                )}
                <button
                  className='w-full mb-3 btn-secondary'
                  onClick={() => {
                    setConfirmModalOpen(true)
                    setAction('archiveApplication')
                    setActionLabel('Archive Application')
                  }}
                >
                  Archive Application
                </button>
                <button
                  className='w-full btn-secondary'
                  onClick={() => {
                    setConfirmModalOpen(true)
                    setAction('deleteApplication')
                    setActionLabel('Delete Application')
                  }}
                >
                  Delete Application
                </button>
              </>
            )}
          </>
        )}

        {!isStudent(data.roles) && (
          <button
            className='w-full btn-secondary mt-3'
            onClick={() => {
              setOpenActivityLog(true)
            }}
          >
            Activity Log
          </button>
        )}
      </div>
      <div className={`${isConfirmModalOpen ? '' : 'hidden'}`}>
        <ConfirmationAlert
          title={`${action === 'deleteApplication' ? 'This action can not be reversed. \n' : ''} Are you sure you perform this action ?`}
          isConfirmModalOpen={isConfirmModalOpen}
          setConfirmModalOpen={setConfirmModalOpen}
          ButtonText={'Yes I am sure'}
          actionName={actionLabel}
          onAction={() => {
            performAction()
          }}
        />
      </div>
      {openStepModal && (
        <Step
          setOpenModal={setStepOpenModal}
          formSubStep={null}
          isView={true}
          type={action}
          label={actionLabel}
          initialValue={studentInfo?.cancelWithdrawPolicy ?? ''}
          callSave={() => {
            setStepOpenModal(false)
            return Promise.resolve(null) // Return a resolved promise with null
          }}
        />
      )}
      {openActivityLog && (
        <Suspense fallback={<Loading />}>
          <StudentActivityLog studentId={studentId} onClose={setOpenActivityLog} />
        </Suspense>
      )}
    </div>
  )
}
