import http from 'CommonJS/http'
import { useState, useEffect, useContext, ChangeEvent } from 'react'
import Loading from 'Components/Loading'
import AdminSetting from 'Components/AdminSetting'
import { FaEdit, FaTrashAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import ReactJsPagination from 'react-js-pagination'
import { toast } from 'Components/Toast/toast'
import ConfirmationAlert from 'Components/ConfirmationAlert'
import { Schedule } from './type'
import { CiSquarePlus } from 'react-icons/ci'
import { AppContext } from 'Context/AppContext'
import { AppContextType } from 'Context/AppTypes'
import styled from '@emotion/styled'
import { Button } from '@mui/material'

export default function CourseSchedule() {
  const [courseSchedules, setCourseSchedules] = useState<Schedule>()
  const [loading, setLoading] = useState<boolean>(false)
  const [deleteCourseScheduleId, setDeleteCourseScheduleId] = useState<string>('')
  const [isConfirmAlertOpen, setConfirmAlertOpen] = useState<boolean>(false)
  const { uploadFile } = useContext(AppContext) as AppContextType

  useEffect(() => {
    getCourseScheduleData()
  }, [])

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1
  })

  //handle file change
  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>, key: string) => {
    try {
      event.preventDefault()
      const imageFile = event.target.files ? event.target.files[0] : ''
      await setLoading(true)
      if (imageFile) {
        const fileRes = await uploadFile(imageFile, undefined, key)
        if (fileRes.success === true) {
          const documentId = fileRes.document.id

          http
            .fetch({ method: 'post', path: '/setting/course-schedule/importLinks', data: { id: documentId } })
            .catch(() => {
              toast('Internal server error.', 'error')
              setLoading(false)
            })
            .then((response) => {
              setLoading(false)
              if (response && response.status === 200) {
                toast(response?.data?.message || 'Successfully uploaded', 'success')
              }
            })
        } else {
          toast(fileRes.message ?? 'Internal server error', 'error')
        }
      }
      await setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const getCourseScheduleData = async (pageNumber = 1) => {
    await setLoading(true)
    await http
      .fetch({ path: `/setting/course-schedule?page=${pageNumber}` })
      .catch(() => {
        toast('Internal Server Error.', 'error')
        setLoading(false)
      })
      .then((response: any) => {
        setLoading(false)
        if (response && response.status === 200) {
          setCourseSchedules(response.data.courseSchedules)
        }
      })
    await setLoading(false)
  }

  const handleDeleteCourseSchedule = async () => {
    if (!deleteCourseScheduleId) {
      toast('Something went wrong please try again', 'error')
      return
    }
    await setLoading(true)
    await http
      .fetch({ method: 'delete', path: `/setting/course-schedule/${deleteCourseScheduleId}` })
      .catch((error) => {
        toast(error?.data?.message ?? 'Internal server error', 'error')
        setLoading(false)
        setConfirmAlertOpen(false)
        setDeleteCourseScheduleId('')
      })
      .then((response: any) => {
        setLoading(false)
        if (response && response.status === 200) {
          toast(response?.data?.message || 'Successfully deleted insurance', 'success')
          getCourseScheduleData(1)
          setConfirmAlertOpen(false)
          setDeleteCourseScheduleId('')
        }
      })
    await setLoading(false)
  }
  return (
    <>
      {loading && <Loading />}
      <div className='flex w-full min-h-screen md:flex-wrap md:min-h-full lg:flex-col'>
        <AdminSetting />
        <div className='px-7 py-5 w-full lg:px-4 min-w-[calc(100%-15rem)]' data-cy='course-schedule-page'>
          <div className='flex justify-between items-center gap-3 md:flex-wrap md:pb-4'>
            <h1 className='text-3xl font-bold decoration-1'>Course Schedule</h1>
            <div className='flex gap-2'>
              <Button component='label' role={undefined} variant='contained' tabIndex={-1}>
                Upload File
                <VisuallyHiddenInput type='file' onChange={(event) => handleFileChange(event, 'course_schedule_link_import')} />
              </Button>
              <Link
                to='/admin/setting/course-schedule/add'
                className='btn-primary bg-primary hover:bg-primary/90  text-white flex gap-x-2 items-center'
                data-cy='add-course-schedule-link'
              >
                Add Course Schedule
                <CiSquarePlus className='w-6 h-6' />
              </Link>
            </div>
          </div>
          <div className='overflow-auto'>
            <table className='w-full mt-5 lg:w-max' data-cy='course-schedule-table'>
              <thead>
                <tr className='[&>*]:bg-primary [&>*]:text-white [&>*]:tracking-wider [&>*]:py-3 [&>*]:border-slate-500 [&>*]:text-left'>
                  <th>Id</th>
                  <th>Title</th>
                  <th>Institute</th>
                  <th>Session</th>
                  <th>Year</th>
                  <th>Program</th>
                  <th>Sending Institution</th>
                  <th>Home University</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {courseSchedules?.data.length === 0 || !courseSchedules ? (
                  <>
                    <tr>
                      <td colSpan={7}>No Course Schedule Found</td>
                    </tr>
                  </>
                ) : (
                  <>
                    {courseSchedules?.data?.map((courseSchedule) => (
                      <tr key={courseSchedule.id}>
                        <td>{courseSchedule?.id}</td>
                        <td>{courseSchedule?.title ?? '-'}</td>
                        <td>{courseSchedule?.institute.length > 0 ? courseSchedule?.institute.join(', ') : '-'}</td>
                        <td>{courseSchedule?.session.length > 0 ? courseSchedule?.session.join(', ') : '-'}</td>
                        <td>{courseSchedule?.year.length > 0 ? courseSchedule?.year.join(', ') : '-'}</td>
                        <td>{courseSchedule?.program.length > 0 ? courseSchedule?.program.join(', ') : '-'}</td>
                        <td>{courseSchedule?.sendingInstitution.length > 0 ? courseSchedule?.sendingInstitution.join(', ') : '-'}</td>
                        <td>{courseSchedule?.homeCollege.length > 0 ? courseSchedule?.homeCollege.join(', ') : '-'}</td>
                        <td>
                          <div className='flex gap-5 items-center'>
                            <Link
                              to={`/admin/setting/course-schedule/edit/${courseSchedule?.id}`}
                              className='btn btn-primary flex items-center gap-x-3'
                              data-cy='course-schedule-edit'
                            >
                              Edit <FaEdit />
                            </Link>
                            <button
                              className='btn btn-danger flex items-center gap-x-3'
                              data-cy='course-schedule-delete'
                              onClick={() => {
                                setDeleteCourseScheduleId(courseSchedule?.id)
                                setConfirmAlertOpen(true)
                              }}
                            >
                              Delete <FaTrashAlt size={14} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
          {Boolean(courseSchedules?.data?.length ?? 0 > 0) && (
            <div className='mr-10 [&>ul]:flex [&>ul]:gap-3 [&>*_a]:py-2 [&>*_a]:px-4 [&>*_a]:lg:py-1 [&>*_a]:lg:px-3 [&>*_a]:inline-block mt-4 [&>*_a]:border [&>*_a:hover]:bg-primary [&>*_a:hover]:text-white [&>*_li.active>a]:bg-primary [&>*_li.active>a]:text-white'>
              <ReactJsPagination
                activePage={courseSchedules?.currentPage || 1}
                itemsCountPerPage={courseSchedules?.perPage}
                totalItemsCount={courseSchedules?.total || 0}
                pageRangeDisplayed={courseSchedules?.to}
                onChange={(pageNumber) => {
                  getCourseScheduleData(pageNumber)
                }}
              />
            </div>
          )}
        </div>
      </div>
      <ConfirmationAlert
        title='Are you sure you perform this action ?'
        isConfirmModalOpen={isConfirmAlertOpen}
        setConfirmModalOpen={(val: boolean) => {
          setConfirmAlertOpen(val)
          if (!val) setDeleteCourseScheduleId('')
        }}
        ButtonText={'Yes I am sure'}
        actionName='Delete course schedule'
        onAction={handleDeleteCourseSchedule}
      />
    </>
  )
}
