import { FileUploadResponse } from 'Context/AppTypes'
import axios from 'axios'
import linkifyHtml from 'linkify-html'
import http from './http'
import dayjs from 'dayjs'
import { RouteItem } from 'Components/Layout/type'
import { userObject as UserObject } from 'Context/UserContext'
import { userCan } from './PermissionHelper'

const FVPSL = 'fVPSL'
export const getToken = () => localStorage.getItem('jwt')
export const setToken = (token: string) => localStorage.setItem('jwt', token)
export const setLocalStoreUser = (user: unknown) => localStorage.setItem('user', JSON.stringify(user))
export const getLocalStoreUser = () => JSON.parse(localStorage.getItem('user') as string)

export const removeBlankPairs = (obj: object, removeOnlyKey?: string) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => {
      if (removeOnlyKey) {
        if (key.trim() === removeOnlyKey) {
          return value !== '' && value !== null && value !== undefined
        }
        return true
      }

      return key.trim() !== '' && value !== '' && value !== null && value !== undefined
    })
  )
}

export const linkify = (content?: string, options = {}) => {
  const withOptions = {
    target: '_blank',
    ...options
  }
  return linkifyHtml(content ?? '', withOptions)
}

let abortAllController: null | AbortController = null

export const abortAllControllerHandler = {
  get: (): AbortController => {
    if (!abortAllController) {
      abortAllController = new AbortController()
    }

    return abortAllController
  },
  init: () => (abortAllController = new AbortController())
}

export const API_BASE_URL = process.env.REACT_APP_API_URL
export const axiosClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-type': 'application/json'
  },
  signal: abortAllControllerHandler.get().signal
})

axiosClient.interceptors.request.use((config) => {
  config.headers['Access-Control-Allow-Origin'] = '*'
  const token = getToken()

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  const pathRegex = /^\/admin\/(visa-student|visa-dashboard)/
  if (pathRegex.test(location.pathname)) {
    if (!config.params) {
      config.params = {}
    }
    /**
     * fVPSL = For Visa Portal Student List
     * * Abbreviated for security reason
     * end user doesn't need to know what this flag is for
     */
    config.params[FVPSL] = true
  }

  return config
})

axiosClient.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (getImpersonateInitiated()) {
      return
    }
    if (error.code === 'ERR_NETWORK') {
      // TODO: handle internal server error
    } else if (error.response?.status === 401) {
      // handle unauthorized request
      localStorage.clear()
      window.location.reload()
    }
    return Promise.reject(error)
  }
)

export default axiosClient

export function forVisaPortalStudentListParam(value: boolean = false) {
  return { [FVPSL]: value }
}

export async function uploadFile(file: File, studentId?: any): Promise<FileUploadResponse | any> {
  try {
    if (file) {
      const blob = new Blob([file as BlobPart])
      const newFormData = new FormData()
      newFormData.append('file', blob, file?.name || '')
      if (studentId) newFormData.append('student', studentId)
      const response = await http.fetch({
        method: 'post',
        path: 'fileUpload',
        data: newFormData,
        options: {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      })
      if (response?.data.success) return response.data
      return response
    }
  } catch (error: any) {
    if (error?.response && error?.response?.data) {
      return error?.response.data
    }
    return error?.message ?? null
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function debounce<T extends Function>(cb: T, wait = 20) {
  let h: any = 0
  const callable = (...args: any) => {
    clearTimeout(h)
    h = setTimeout(() => cb(...args), wait)
  }
  return <T>(<any>callable)()
}
// Use debounce(() => 10, 20)()

export function imageUrlToDataURL(url: string): Promise<string> {
  return fetch(url, {
    method: 'GET',
    headers: { 'Cache-Control': 'no-cache', 'Content-Type': 'image/png' }
  })
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onloadend = () => resolve(reader.result as string)
          reader.onerror = reject
          reader.readAsDataURL(blob)
        })
    )
}
/**
 * Converts a file to a data URL using the FileReader API.
 *
 * @param {File} file - The file to be converted.
 * @return {Promise<string | null>} A promise that resolves with the data URL of the file, or null if an error occurs.
 */
export function uploadFileToDataURL(file: File) {
  return new Promise<string | ArrayBuffer | null>((resolve) => {
    const reader = new FileReader()
    reader.onload = (e: ProgressEvent<FileReader>) => {
      resolve(e.target?.result ?? null)
    }
    reader.readAsDataURL(file)
  })
}

export async function downloadFile(url: string) {
  let objectUrl = url
  try {
    const image = await fetch(url)
    const imageBlog = await image.blob()
    objectUrl = URL.createObjectURL(imageBlog)
  } catch (error) {
    //
  }
  const element = document.createElement('a')
  element.setAttribute('href', objectUrl)
  const s3BaseUrl = `https://${String(process.env.REACT_APP_S3_BASE_URL)}/documents/`
  element.setAttribute('download', url.replace(s3BaseUrl, ''))
  element.setAttribute('target', '_blank')
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
  URL.revokeObjectURL(objectUrl)
}

type formatDateParamType = string | null | undefined

export function formatDate(date: formatDateParamType | dayjs.Dayjs = '', fallback?: string): formatDateParamType {
  if (!date || date === '0000-00-00') {
    return fallback ?? date
  }

  try {
    return dayjs(date).format('MM/DD/YYYY')
  } catch (e) {
    return fallback
  }
}

export function toTitleCase(str: string, split = ' ') {
  if (!str) {
    return ''
  }
  const strArr = str.split(split).map((word) => {
    return word[0].toUpperCase() + word.substring(1).toLowerCase()
  })

  return strArr.join(' ')
}

export const hasAccess = (route: RouteItem, user: UserObject): boolean => {
  const allowedRoles = route?.allowedRoles
  if (!allowedRoles) {
    return true
  }
  const hasRoleAccess = user.roles.some((role) => allowedRoles.includes(role))
  let hasPermissionAccess = true

  const allowedPermissions = route?.permissions
  if (allowedPermissions && allowedPermissions.length > 0) {
    hasPermissionAccess = userCan(user, allowedPermissions)
  }

  return hasRoleAccess && hasPermissionAccess
}

export function isValidDate(date: string) {
  if (String(date).includes('-') && String(date).includes('T')) return dayjs(date).isValid()
}

/**
 * Converts a snake_case string to camelCase.
 *
 * @param {string} str - The snake_case string to convert.
 * @return {string} The camelCase version of the input string.
 *
 * @example
 * snakeToCamel('my_snake_case_string'); // Output: 'mySnakeCaseString'
 * snakeToCamel('my-snake_case_string'); // Output: 'mySnakeCaseString'
 * snakeToCamel('My-sNake_case_string'); // Output: 'mySnakeCaseString'
 */
export const toCamelCase = (str: string): string => {
  // Convert the string to lower case to handle cases where the input string contains upper case letters.
  const value = str.toLowerCase()

  // Use a regular expression to replace each snake_case or hyphenated-case group with its camelCase equivalent.
  // The regular expression matches any sequence of a hyphen or underscore followed by a lowercase letter.
  // The matched group is then replaced with the uppercase version of the letter.
  // The replace() method is called with a replacement function that removes the hyphen or underscore from the matched group.
  return value.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''))
}

export const BillingAndDepositDueFields = ['due_date', 'deposit_paid_date', 'invoice_balance_due_date', 'invoice_balance_paid_date']

/**
 * In case of impersonate initiated by clicking `View as student`,
 * delayed api call on student dashboard should be aborted,
 * and before triggering abort any api called is already called avoid logout.
 */
export let ImpersonateInitiated = false

export const getImpersonateInitiated = (): boolean => ImpersonateInitiated
export const setImpersonateInitiated = (val: boolean) => (ImpersonateInitiated = val)

export const TIME_FORMAT_LABEL = 'EST'
