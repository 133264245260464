import { useEffect, useState } from 'react'
import http from 'CommonJS/http'
import { FaSave } from 'react-icons/fa'
import Loading from 'Components/Loading'
import { useForm, useFormState } from 'react-hook-form'
import { toast } from 'Components/Toast/toast'
import AdminSetting from 'Components/AdminSetting'
import { FormDataFields, GetApiResponseFormDataFields, LoginErrorType } from './type'
import { AxiosResponse } from 'axios'
import { BsQuestionCircleFill } from 'react-icons/bs'
import { Tooltip } from '@mui/material'

export default function MailConfig() {
  const [error, setError] = useState<LoginErrorType>({})
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    getMailConfig()
  }, [])

  const defaultValues: FormDataFields = {
    fromEmail: '',
    fromName: '',
    mailHost: '',
    mailPort: '',
    mailUsername: '',
    mailPassword: '',
    mailEncryption: '',
    mailBcc: null
  }

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm<FormDataFields>({ defaultValues })

  const { dirtyFields } = useFormState({
    control
  })

  const getMailConfig = async () => {
    setLoading(true)
    await http
      .fetch({ path: `setting/mailConfig` })
      .catch(() => {
        toast('Internal Server Error.', 'error')
      })
      .then((response: void | AxiosResponse<GetApiResponseFormDataFields>) => {
        setLoading(false)
        if (response && response.status === 200) {
          let mailBcc: string[] | string | null = response?.data?.mail?.mailBcc
          if (Array.isArray(mailBcc)) {
            mailBcc = mailBcc.join(',')
          } else {
            mailBcc = ''
          }
          reset({
            fromEmail: response?.data?.mail?.fromEmail,
            fromName: response?.data?.mail?.fromName,
            mailHost: response?.data?.mail?.mailHost,
            mailPort: response?.data?.mail?.mailPort,
            mailUsername: response?.data?.mail?.mailUsername,
            mailPassword: response?.data?.mail?.mailPassword,
            mailEncryption: response?.data?.mail?.mailEncryption,
            mailBcc: mailBcc
          })
        }
      })
  }

  const handleSave = async (info: FormDataFields) => {
    await setLoading(true)
    let mailBccStr = info?.mailBcc ?? null
    const newData = {
      fromEmail: info?.fromEmail,
      fromName: info?.fromName,
      mailHost: info?.mailHost,
      mailPort: info?.mailPort,
      mailUsername: info?.mailUsername,
      mailPassword: info?.mailPassword,
      mailEncryption: info?.mailEncryption,
      mailBcc: [] as string[]
    }
    let mailBccArr: null | string[] = null
    if (mailBccStr && String(mailBccStr).trim()) {
      mailBccArr = String(mailBccStr)
        .split(',')
        .map((val) => String(val).trim())
        .filter((n) => n)
      newData.mailBcc = mailBccArr
    }

    setError({ message: '' })

    await http
      .fetch({ method: 'post', path: 'setting/mailConfig', data: newData })
      .catch((error) => {
        setError({
          message: error?.response?.data?.message || error.message,
          ...(error?.response?.data?.errors || [])
        })
        setLoading(false)
      })
      .then((response: any) => {
        setLoading(false)
        if (response && response.status === 200) {
          toast(response?.data?.message || 'Successfully updated data', 'success')
          if (Array.isArray(mailBccArr)) {
            mailBccStr = mailBccArr.join(',')
          } else {
            mailBccStr = ''
          }
          reset({
            ...newData,
            mailBcc: mailBccStr
          })
        }
      })
    await setLoading(false)
  }
  return (
    <div className='flex w-full min-h-screen md:flex-wrap md:min-h-full lg:flex-col'>
      {loading && <Loading />}
      <AdminSetting />
      <div className='m-10 px-4 w-full lg:m-0 lg:px-4 lg:py-7' data-cy='mail-setting-page'>
        <div className='flex justify-between items-center'>
          <h1 className='text-3xl font-bold' data-cy='title'>
            Mail Settings
          </h1>
        </div>
        <form
          className='p-7 md:p-4 rounded-md border border-slate-200 shadow-2xl shadow-primary/20	 mt-10'
          onSubmit={handleSubmit(handleSave)}
        >
          <div className='mb-6'>
            <div className='mb-6'>
              <label className='block uppercase tracking-wide text-gray-700 text-base font-semibold mb-2' htmlFor='grid-first-name'>
                From Email
              </label>
              <input
                className='form-input'
                type='text'
                {...register('fromEmail', {
                  required: 'From email is required',
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: 'Invalid email address'
                  }
                })}
                name='fromEmail'
                data-cy='from-email'
                placeholder='Enter From Email'
              />
              {errors?.fromEmail && (
                <p className='text-left text-red-500 text-sm' data-cy='error'>
                  {errors.fromEmail.message}
                </p>
              )}
            </div>
            <div className='mb-6'>
              <label className='block uppercase tracking-wide text-gray-700 text-base font-semibold mb-2' htmlFor='grid-first-name'>
                From Name
              </label>
              <input
                className='form-input'
                type='text'
                {...register('fromName', {
                  required: 'From name is required'
                })}
                name='fromName'
                data-cy='from-name'
                placeholder='Enter From Name'
              />
              {errors?.fromName && (
                <p className='text-left text-red-500 text-sm' data-cy='error'>
                  {errors.fromName.message}
                </p>
              )}
            </div>
            <div className='mb-6'>
              <label className='block uppercase tracking-wide text-gray-700 text-base font-semibold mb-2' htmlFor='grid-first-name'>
                Mail Host
              </label>
              <input
                className='form-input'
                type='text'
                {...register('mailHost', {
                  required: true
                })}
                name='mailHost'
                data-cy='mail-host'
                placeholder='Enter Mail Host'
              />
              {errors?.mailHost?.type == 'required' && (
                <p className='text-left text-red-500 text-sm' data-cy='error'>
                  Mail host is required
                </p>
              )}
            </div>
            <div className='mb-6'>
              <label className='block uppercase tracking-wide text-gray-700 text-base font-semibold mb-2' htmlFor='grid-first-name'>
                Mail Port
              </label>
              <input
                className='form-input'
                type='text'
                {...register('mailPort', {
                  required: true
                })}
                name='mailPort'
                data-cy='mail-port'
                placeholder='Enter Mail Port'
              />
              {errors?.mailPort?.type == 'required' && (
                <p className='text-left text-red-500 text-sm' data-cy='error'>
                  Mail port is required
                </p>
              )}
            </div>
            <div className='mb-6'>
              <label className='block uppercase tracking-wide text-gray-700 text-base font-semibold mb-2' htmlFor='grid-first-name'>
                Mail Username
              </label>
              <input
                className='form-input'
                type='text'
                {...register('mailUsername', {
                  required: true
                })}
                name='mailUsername'
                data-cy='mail-username'
                placeholder='Enter Mail Username'
              />
              {errors?.mailUsername?.type == 'required' && (
                <p className='text-left text-red-500 text-sm' data-cy='error'>
                  Mail username is required
                </p>
              )}
            </div>
            <div className='mb-6'>
              <label className='block uppercase tracking-wide text-gray-700 text-base font-semibold mb-2' htmlFor='grid-first-name'>
                Mail Password
              </label>
              <input
                className='form-input'
                type='text'
                {...register('mailPassword', {
                  required: true
                })}
                name='mailPassword'
                data-cy='mail-password'
                placeholder='Enter From Password'
              />
              {errors?.mailPassword?.type == 'required' && (
                <p className='text-left text-red-500 text-sm' data-cy='error'>
                  Mail password is required
                </p>
              )}
            </div>
            <div className='mb-6'>
              <label className='block uppercase tracking-wide text-gray-700 text-base font-semibold mb-2' htmlFor='grid-first-name'>
                Mail Encryption
              </label>
              <input
                className='form-input'
                type='text'
                {...register('mailEncryption', {
                  required: true
                })}
                name='mailEncryption'
                data-cy='mail-encryption'
                placeholder='Enter Mail Encryption'
              />
              {errors?.mailEncryption?.type == 'required' && (
                <p className='text-left text-red-500 text-sm' data-cy='error'>
                  Mail Encryption is required
                </p>
              )}
            </div>
            <div className='mb-6'>
              <div className='flex gap-3 flex-wrap'>
                <label className='block uppercase tracking-wide text-gray-700 text-base font-semibold' htmlFor='grid-first-name'>
                  Mail Bcc (to send student mail copy to)
                </label>
                <Tooltip
                  title={
                    <div className='font-semibold'>
                      Mail Bcc used when &#10077;Email Template&#10078; &rarr; &#10077;Send copy to Advisor, Hubspot and Admission?&#10078;
                      enabled.
                    </div>
                  }
                  classes={{ popper: '[&_div]:text-base [&>div]:max-w-[450px]' }}
                  enterTouchDelay={1}
                  placement='bottom'
                  arrow
                >
                  <span>
                    <BsQuestionCircleFill className='size-5 md:size-7 mt-1 text-slate-400 cursor-pointer' />
                  </span>
                </Tooltip>
              </div>
              <small className='text-sm block mb-2'>Multiple values can be provided separated by comma (,)</small>
              <input
                className='form-input'
                type='text'
                {...register('mailBcc')}
                name='mailBcc'
                data-cy='mail-bcc'
                placeholder='Enter Mail Bcc'
              />
              {errors?.mailBcc?.type == 'required' && (
                <p className='text-left text-red-500 text-sm' data-cy='error'>
                  Mail Bcc is required
                </p>
              )}
            </div>
          </div>
          {error.message && (
            <div className='text-left text-red-500 text-sm' data-cy='error'>
              <p>{error?.message}</p>
            </div>
          )}
          <div className='flex justify-center'>
            <button
              type='submit'
              disabled={Object.keys(dirtyFields).length === 0}
              className={`btn-primary flex items-center gap-x-3 ${Object.keys(dirtyFields).length === 0 ? 'opacity-50' : ''}`}
              data-cy='save-btn'
            >
              Save <FaSave />
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
