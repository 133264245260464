import AdminSetting from 'Components/AdminSetting'
import { Box, Tab, Tabs } from '@mui/material'
import { useState } from 'react'
import GlobalSetting from './GlobalSetting'
import AdmissionPortal from './AdmissionPortal'
import VisaPortal from './VisaPortal'

interface FormSettingTabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function FormSettingTabPanel(props: FormSettingTabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ pt: 3, pl: 4 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}
export default function FormSyncSetting() {
  const [tabIndex, setTabIndex] = useState<number>(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue)
  }

  return (
    <div className='w-8/12 xl:w-full'>
      <div className='flex w-full min-h-screen md:flex-wrap md:min-h-full lg:flex-col' data-cy='form-setting-page'>
        <AdminSetting />
        <div className='md:w-full p-4 md:px-0 md:py-2'>
          <Box sx={{}}>
            <Box>
              <Tabs
                value={tabIndex}
                className='tab-list'
                onChange={handleChange}
                aria-label='form setting tabs'
                variant='scrollable'
                allowScrollButtonsMobile
                sx={{ pl: 3 }}
              >
                <Tab label='Global Settings' {...a11yProps(0)} data-cy='global-settings' />
                <Tab label='Admission Portal Form Settings' {...a11yProps(1)} data-cy='admission-portal-form-settings' />
                <Tab label='Visa Portal Form Settings' {...a11yProps(2)} data-cy='visa-portal-form-settings' />
              </Tabs>
            </Box>
            <FormSettingTabPanel value={tabIndex} index={0}>
              <GlobalSetting />
            </FormSettingTabPanel>
            <FormSettingTabPanel value={tabIndex} index={1}>
              <AdmissionPortal />
            </FormSettingTabPanel>
            <FormSettingTabPanel value={tabIndex} index={2}>
              <VisaPortal />
            </FormSettingTabPanel>
          </Box>
        </div>
      </div>
    </div>
  )
}
