import { useContext } from 'react'
import { DetailPropsType } from '../type'
import { subStepContext } from '../context'
import { linkify } from 'CommonJS/common'
import Description from '../Description'

function HtmlType({ item, isRenderConditionalComponent }: DetailPropsType) {
  const {
    state: { currentPage }
  } = useContext(subStepContext)

  if (isRenderConditionalComponent === false || currentPage !== item?.pageNumber || item?.visibility === 'hidden') return null
  return (
    <div className='mt-5'>
      {/* <h1 className=' text-lg'>{item?.label}</h1> */}
      <div className='html-content p-revert' dangerouslySetInnerHTML={{ __html: linkify(item?.content) }} />
      <Description structure={item} />
    </div>
  )
}

export default HtmlType
