import http from 'CommonJS/http'
import { useState, useEffect } from 'react'
import Loading from 'Components/Loading'
import AdminSetting from 'Components/AdminSetting'
import { FaEdit, FaTrashAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import ReactJsPagination from 'react-js-pagination'
import { toast } from 'Components/Toast/toast'
import ConfirmationAlert from 'Components/ConfirmationAlert'
import { Smartphone } from './type'
import { CiSquarePlus } from 'react-icons/ci'

export default function SmartphoneApp() {
  const [smartphoneApps, setSmartphoneApps] = useState<Smartphone>()
  const [loading, setLoading] = useState<boolean>(false)
  const [deleteSmartphoneAppId, setDeleteSmartphoneAppId] = useState<string>('')
  const [isConfirmAlertOpen, setConfirmAlertOpen] = useState<boolean>(false)

  useEffect(() => {
    getSmartphoneAppData()
  }, [])

  const getSmartphoneAppData = async (pageNumber = 1) => {
    await setLoading(true)
    await http
      .fetch({ path: `/setting/smartphone-app?page=${pageNumber}` })
      .catch(() => {
        toast('Internal Server Error.', 'error')
        setLoading(false)
      })
      .then((response: any) => {
        setLoading(false)
        if (response && response.status === 200) {
          setSmartphoneApps(response.data.smartphoneApps)
        }
      })
    await setLoading(false)
  }

  const handleDeleteSmartphoneApp = async () => {
    if (!deleteSmartphoneAppId) {
      toast('Something went wrong please try again', 'error')
      return
    }
    await setLoading(true)
    await http
      .fetch({ method: 'delete', path: `/setting/smartphone-app/${deleteSmartphoneAppId}` })
      .catch((error) => {
        toast(error?.data?.message ?? 'Internal server error', 'error')
        setLoading(false)
        setConfirmAlertOpen(false)
        setDeleteSmartphoneAppId('')
      })
      .then((response: any) => {
        setLoading(false)
        if (response && response.status === 200) {
          toast(response?.data?.message || 'Successfully deleted insurance', 'success')
          getSmartphoneAppData(1)
          setConfirmAlertOpen(false)
          setDeleteSmartphoneAppId('')
        }
      })
    await setLoading(false)
  }
  return (
    <>
      {loading && <Loading />}
      <div className='flex w-full min-h-screen md:flex-wrap md:min-h-full lg:flex-col'>
        <AdminSetting />
        <div className='px-7 py-5 w-full lg:px-4 min-w-[calc(100%-15rem)]' data-cy='smartphone-app-page'>
          <div className='flex justify-between items-center gap-3 md:flex-wrap md:pb-4'>
            <h1 className='text-3xl font-bold decoration-1'>Smartphone App</h1>
            <Link
              to='/admin/setting/smartphone-app/add'
              className='btn-primary bg-primary hover:bg-primary/90  text-white flex gap-x-2 items-center'
              data-cy='add-smartphone-app-link'
            >
              Add Smartphone App
              <CiSquarePlus className='w-6 h-6' />
            </Link>
          </div>
          <div className='overflow-auto'>
            <table className='w-full mt-5 lg:w-max' data-cy='smartphone-app-table'>
              <thead>
                <tr className='[&>*]:bg-primary [&>*]:text-white [&>*]:tracking-wider [&>*]:py-3 [&>*]:border-slate-500 [&>*]:text-left'>
                  <th>Id</th>
                  <th>Title</th>
                  <th>Institute</th>
                  <th>Session</th>
                  <th>Year</th>
                  <th>Program</th>
                  <th>Sending Institution</th>
                  <th>Home University</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {smartphoneApps?.data.length === 0 || !smartphoneApps ? (
                  <>
                    <tr>
                      <td colSpan={7}>No Smartphone App Found</td>
                    </tr>
                  </>
                ) : (
                  <>
                    {smartphoneApps?.data?.map((smartphone) => (
                      <tr key={smartphone.id}>
                        <td>{smartphone?.id}</td>
                        <td>{smartphone?.title ?? '-'}</td>
                        <td>{smartphone?.institute.length > 0 ? smartphone?.institute.join(', ') : '-'}</td>
                        <td>{smartphone?.session.length > 0 ? smartphone?.session.join(', ') : '-'}</td>
                        <td>{smartphone?.year.length > 0 ? smartphone?.year.join(', ') : '-'}</td>
                        <td>{smartphone?.program.length > 0 ? smartphone?.program.join(', ') : '-'}</td>
                        <td>{smartphone?.sendingInstitution.length > 0 ? smartphone?.sendingInstitution.join(', ') : '-'}</td>
                        <td>{smartphone?.homeCollege.length > 0 ? smartphone?.homeCollege.join(', ') : '-'}</td>
                        <td>
                          <div className='flex gap-5 items-center'>
                            <Link
                              to={`/admin/setting/smartphone-app/edit/${smartphone?.id}`}
                              className='btn btn-primary flex items-center gap-x-3'
                              data-cy='smartphone-app-edit'
                            >
                              Edit <FaEdit />
                            </Link>
                            <button
                              className='btn btn-danger flex items-center gap-x-3'
                              data-cy='smartphone-app-delete'
                              onClick={() => {
                                setDeleteSmartphoneAppId(smartphone?.id)
                                setConfirmAlertOpen(true)
                              }}
                            >
                              Delete <FaTrashAlt size={14} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
          {Boolean(smartphoneApps?.data?.length ?? 0 > 0) && (
            <div className='mr-10 [&>ul]:flex [&>ul]:gap-3 [&>*_a]:py-2 [&>*_a]:px-4 [&>*_a]:lg:py-1 [&>*_a]:lg:px-3 [&>*_a]:inline-block mt-4 [&>*_a]:border [&>*_a:hover]:bg-primary [&>*_a:hover]:text-white [&>*_li.active>a]:bg-primary [&>*_li.active>a]:text-white'>
              <ReactJsPagination
                activePage={smartphoneApps?.currentPage || 1}
                itemsCountPerPage={smartphoneApps?.perPage}
                totalItemsCount={smartphoneApps?.total || 0}
                pageRangeDisplayed={smartphoneApps?.to}
                onChange={(pageNumber) => {
                  getSmartphoneAppData(pageNumber)
                }}
              />
            </div>
          )}
        </div>
      </div>
      <ConfirmationAlert
        title='Are you sure you perform this action ?'
        isConfirmModalOpen={isConfirmAlertOpen}
        setConfirmModalOpen={(val: boolean) => {
          setConfirmAlertOpen(val)
          if (!val) setDeleteSmartphoneAppId('')
        }}
        ButtonText={'Yes I am sure'}
        actionName='Delete smartphone app'
        onAction={handleDeleteSmartphoneApp}
      />
    </>
  )
}
