import { useContext, useEffect } from 'react'
import StudentProvider from '../context'
import { AppContext } from 'Context/AppContext'
import { AppContextType } from 'Context/AppTypes'
import VisaPortalStudent from './VisaPortalStudent'

export default function App() {
  const { visaPortalSetting, getVisaPortalSetting } = useContext(AppContext) as AppContextType

  useEffect(() => {
    const init = async () => {
      if (Object.keys(visaPortalSetting ?? {})?.length === 0) {
        await getVisaPortalSetting()
      }
    }
    init()
  }, [])

  useEffect(() => {
    if (visaPortalSetting) {
      document.documentElement.style.setProperty('--theme-color', visaPortalSetting.themeColor)
      document.documentElement.style.setProperty('--font-color', visaPortalSetting.fontColor)
      document.documentElement.style.setProperty('--menu-color', '#F5F5DC')
    }
  }, [visaPortalSetting])

  return (
    <StudentProvider>
      <VisaPortalStudent />
    </StudentProvider>
  )
}
