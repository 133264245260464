export enum EmailTemplateEnum {
  STUDENT_REGISTER = 'student_register',
  STUDENT_REJECT = 'student_reject',
  STUDENT_FORGET_PASSWORD = 'student_forget_password',
  STUDENT_ACCEPTANCE_LETTER = 'student_acceptance_letter',
  STUDENT_VISA_LETTER = 'student_visa_letter',
  STUDENT_REJECT_APPLICATION = 'student_reject_application',
  STUDENT_WITHDRAW_APPLICATION = 'student_withdraw_application',
  STUDENT_ACCEPT_APPLICATION = 'student_accept_application',
  CONDITIONAL_ACCEPT = 'conditional_accept',
  STUDENT_CONFIRM_ENROLLMENT = 'student_confirm_enrollment',
  EXPORT_REPORT = 'export_report',
  ADVISOR_NOTE_FOR_STUDENT = 'advisor_note_for_student',
  WITHDRAW_APPLICANT = 'withdraw_applicant',
  CONFIRM_ALL_FORMS_COMPLETED = 'confirm_all_forms_completed',
  COMPLETE_APPLICATION = 'complete_application',
  ARCHIVE_APPLICATION = 'archive_application',
  DELETE_APPLICATION = 'delete_application',
  MAKE_STUDENT_ACTIVE = 'make_student_active',
  APPROVE_STUDENT_STEP = 'approve_student_step',
  RECHECK_STUDENT_STEP = 'recheck_student_step',
  ADVISOR_ASSIGNED = 'advisor_assigned',
  COMPLETE_STUDENT_STEP = 'complete_student_step',
  COURSE_SCHEDULE_LINK_IMPORT = 'course_schedule_link_import',
  COURSE_SCHEDULE_CONFIRMATION_SIGNED = 'course_schedule_confirmation_signed',
  COURSE_SCHEDULE_CONFIRMATION_COMPLETED = 'course_schedule_confirmation_completed',
  DOWNLOAD_FORM_SUBMISSION_PDF = 'download_form_submission_pdf'
}
