/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import EventBus from 'CommonJS/eventBus'

interface FreezeTableColumnsProps {
  containerSelector: string
}

export function FreezeTableColumns({ containerSelector }: FreezeTableColumnsProps) {
  const [value, setValue] = useState<string | null>(null)

  useEffect(() => {
    /**
     * unset freeze column on initial load to avoid flicker on route changes.
     */
    document.head.querySelector(`#${getStyleTagSelector()}`)?.remove()

    getStoredValue()
    EventBus.$on('get:student-listing', () => {
      const stored = getStoredValue()
      if (stored) {
        setTimeout(() => {
          handleChange(String(stored))
        })
      }
    })
  }, [])

  const getStoredValue = () => {
    const savedFixedColumns = Number(localStorage.getItem('savedFixedColumns'))

    if (savedFixedColumns && (savedFixedColumns < 6 || savedFixedColumns > 1)) {
      setValue(String(savedFixedColumns))

      return savedFixedColumns
    }

    return null
  }

  const getStyleTagSelector = () => {
    let styleTagSelector = containerSelector
    // Check if the first character is '#' or '.'
    if (styleTagSelector[0] === '#' || styleTagSelector[0] === '.') {
      // Return the substring starting from the second character
      styleTagSelector = styleTagSelector.slice(1)
    }

    return styleTagSelector
  }

  const handleChange = async (newVal: string | null) => {
    const val = newVal
    setValue(val)

    const styleTagSelector = getStyleTagSelector()
    document.head.querySelector(`#${styleTagSelector}`)?.remove()

    localStorage.setItem('savedFixedColumns', val ?? '')
    if (!val) return

    const style = document.createElement('style')
    style.id = styleTagSelector

    const containerElm = document.querySelector(containerSelector)
    const tableRect: any = containerElm?.querySelector('table')?.getBoundingClientRect()
    let thElms: any = containerElm?.querySelectorAll('thead th')
    thElms = Array.from(thElms ?? []).slice(0, Number(val))
    ;(thElms as Array<HTMLElement>).forEach((th, index) => {
      const thRect = th.getBoundingClientRect()
      const leftPosition = thRect.x - tableRect.x

      style.innerHTML += `
      ${containerSelector} table thead tr th:nth-child(${index + 1}) {
          box-shadow: 2px 0px 5px rgba(0,0,0,0.8);
          z-index: 10;
          position: sticky;
          left: ${leftPosition}px;
        }
      `
    })

    // all table rows
    const trElms: any = containerElm?.querySelectorAll('tbody tr')
    ;(trElms as Array<HTMLElement>).forEach((tr, trIndex) => {
      let tdElms: any = tr.querySelectorAll('td')
      // first n table cells
      tdElms = Array.from(tdElms ?? []).slice(0, Number(val))
      ;(tdElms as Array<HTMLTableCellElement>).forEach((td, tdIndex) => {
        const tdRect = td.getBoundingClientRect()
        const leftPosition = tdRect.x - tableRect.x

        style.innerHTML += `
        ${containerSelector} table tbody tr:nth-child(${trIndex + 1}) td:nth-child(${tdIndex + 1}) {
            box-shadow: 2px 0px 5px rgba(0,0,0,0.8);
            z-index: 10;
            background: white;
            position: sticky;
            left: ${leftPosition}px;
          }
        `
      })
    })
    document.head.appendChild(style)
  }

  return (
    <div className='max-w-40 min-w-40 py-4'>
      <Autocomplete
        data-cy='freeze-table'
        value={value}
        onChange={(e: any, newVal: string | null) => handleChange(newVal)}
        id='controllable-states-demo'
        options={['2', '3', '4', '5']}
        sx={{ width: 150 }}
        renderInput={(params) => <TextField {...params} label='Fixed columns' />}
      />
    </div>
  )
}
