import { MdClose } from 'react-icons/md'
import { TemplateData } from './type'
import { linkify } from 'CommonJS/common'
import { useEffect } from 'react'

type DescriptionProps = {
  template: TemplateData
  onClose?: () => void
}

export default function Description({ template, onClose }: DescriptionProps) {
  const handleImageEventListener = (detach = false) => {
    const imgTags = document.getElementById('email-template-container')?.querySelectorAll('img')
    imgTags?.forEach((imgTag) => {
      if (detach) {
        imgTag.removeEventListener('click', function () {})
      } else {
        imgTag.addEventListener('click', function () {
          this.src && window.open(this.src, '_blank')?.focus()
        })
      }
    })
  }

  useEffect(() => {
    handleImageEventListener()
  }, [])

  return (
    <>
      <div
        id='email-template-container'
        className='w-full h-full fixed flex justify-center items-center bg-black/50  inset-x-0 inset-y-0 border z-[190] p-10 md:p-3'
      >
        <div className='bg-white w-1/2 h-5/6 mx-auto rounded-md p-5 relative overflow-hidden lg:w-full lg:h-auto md:p-3'>
          <div className='flex justify-between items-center'>
            <h1 className=' font-semibold text-2xl'>{template?.key} Template Description</h1>
            <MdClose
              className='cursor-pointer bg-primary text-white rounded-full w-9 min-w-9 h-9 p-1'
              size={28}
              onClick={() => {
                if (typeof onClose === 'function') {
                  handleImageEventListener(true)
                  onClose()
                }
              }}
            />
          </div>

          <div
            className='mt-4 pb-2 h-min max-h-[calc(100%-50px)] border-t overflow-auto text-lg [&_img]:mt-3 [&_img]:cursor-pointer [&_img]:border'
            dangerouslySetInnerHTML={{ __html: linkify(template?.description) }}
          ></div>
        </div>
      </div>
    </>
  )
}
