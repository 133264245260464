import React, { createContext } from 'react'
import {
  AppContextType,
  FileUploadResponse,
  HomeCollege,
  Institute,
  Program,
  SendingInstitution,
  Session,
  UserTypeInfo,
  Year,
  VisaPortalSetting
} from './AppTypes'
import http from 'CommonJS/http'
import { toast } from 'Components/Toast/toast'

export const AppContext = createContext<AppContextType | null>(null)

const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userTypeInfo, setUserTypeInfo] = React.useState<UserTypeInfo>({ roles: [], homeCollege: [], sendingInstitution: [] })
  const [institutes, setInstitutes] = React.useState<Institute[] | []>([])
  const [sessions, setSessions] = React.useState<Session[] | []>([])
  const [programs, setPrograms] = React.useState<Program[] | []>([])
  const [sendingInstitutions, setSendingInstitution] = React.useState<SendingInstitution[] | []>([])
  const [homeColleges, setHomeCollege] = React.useState<HomeCollege[] | []>([])
  const [years, setYears] = React.useState<Year[] | []>([])
  const [templateVariables, setTemplatesVariables] = React.useState<Array<string>>([])

  const [visaPortalSetting, setVisaPortalSetting] = React.useState<VisaPortalSetting | null>(null)
  const getInstitute = () => {
    return http
      .fetch({ path: 'setting/institutes' })
      .then((response) => {
        if (response && response.status === 200) {
          setInstitutes(response.data.institutes)
        }
      })
      .catch(() => {
        toast('Internal server error.', 'error')
      })
  }

  const getSession = () => {
    return http
      .fetch({ path: 'setting/sessions' })
      .then((response) => {
        if (response && response.status === 200) {
          setSessions(response.data.sessions)
        }
      })
      .catch(() => {
        toast('Internal server error.', 'error')
      })
  }

  const getPrograms = () => {
    return http
      .fetch({ path: 'setting/programs' })
      .then((response) => {
        if (response && response.status === 200) {
          setPrograms(response.data.programs)
        }
      })
      .catch(() => {
        toast('Internal server error.', 'error')
      })
  }

  const getYears = () => {
    return http
      .fetch({ path: 'setting/years' })
      .then((response) => {
        if (response && response.status === 200) {
          setYears(response.data.years)
        }
      })
      .catch(() => {
        toast('Internal server error.', 'error')
      })
  }

  const getSendingInstitution = () => {
    return http
      .fetch({ path: 'setting/sendingInstitution' })
      .then((response) => {
        if (response && response.status === 200) {
          setSendingInstitution(response.data.sendingInstitution)
        }
      })
      .catch(() => {
        toast('Internal server error.', 'error')
      })
  }

  const getHomeCollege = () => {
    return http
      .fetch({ path: 'setting/homeColleges' })
      .then((response) => {
        if (response && response.status === 200) {
          setHomeCollege(response.data.homeCollege)
        }
      })
      .catch(() => {
        toast('Internal server error.', 'error')
      })
  }

  async function getTemplatesVariables() {
    return http
      .fetch({ path: '/templates/variables?type=letter' })
      .catch(() => {
        toast('Internal server error.', 'error')
      })
      .then((response) => {
        if (response && response.status === 200) {
          setTemplatesVariables(response.data.variables)
        }
      })
  }

  async function getTypeInfo() {
    return http
      .fetch({ path: '/user/info' })
      .catch(() => {
        toast('Internal server error.', 'error')
      })
      .then((response) => {
        if (response && response.status === 200) {
          setUserTypeInfo(response.data)
        }
      })
  }

  async function getVisaPortalSetting() {
    return http
      .fetch({ path: '/setting/theme' })
      .catch(() => {
        toast('Internal server error.', 'error')
      })
      .then((response) => {
        if (response && response.status === 200) {
          setVisaPortalSetting(response.data.theme)
        }
      })
  }

  /**
   * @deprecated use from src/CommonJS/common.ts instead
   */
  async function uploadFile(file: File, studentId?: any, key?: string): Promise<FileUploadResponse | any> {
    try {
      if (file) {
        const blob = new Blob([file as BlobPart])
        const newFormData = new FormData()
        newFormData.append('file', blob, file?.name || '')
        if (studentId) newFormData.append('student', studentId)
        if (key) newFormData.append('key', key)
        const response = await http.fetch({
          method: 'post',
          path: 'fileUpload',
          data: newFormData,
          options: {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        })
        if (response?.data.success) return response.data
        return null
      }
    } catch (error: any) {
      const rsp = error?.response?.data
      return rsp
    }
  }

  async function multipleUploadFile(filelist: FileList, studentId?: any): Promise<FileUploadResponse | any> {
    try {
      if (filelist) {
        const newFormData = new FormData()
        if (filelist && filelist.length > 0) {
          for (let i = 0; i < filelist.length; i++) {
            const file = filelist[i]
            const blob = new Blob([file as BlobPart])
            newFormData.append(`file[${i}]`, blob, file.name || '')
          }
        }
        if (studentId) newFormData.append('student', studentId)
        const response = await http.fetch({
          method: 'post',
          path: 'multipleFileUpload',
          data: newFormData,
          options: {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        })
        if (response?.data.success) return response.data
        return null
      }
    } catch (error) {
      return null
    }
  }

  return (
    <AppContext.Provider
      value={{
        institutes,
        sessions,
        programs,
        sendingInstitutions,
        homeColleges,
        visaPortalSetting,
        years,
        templateVariables,
        userTypeInfo,
        getInstitute,
        getSession,
        getPrograms,
        getSendingInstitution,
        getHomeCollege,
        getVisaPortalSetting,
        getYears,
        uploadFile,
        multipleUploadFile,
        getTemplatesVariables,
        getTypeInfo
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppProvider
