import { StudentMetaType } from 'Components/Layout/type'
import { StudentVisaPortalInfo, VisaFormStep } from './type'
import { ChangeEvent, useEffect, useState } from 'react'
import { toCamelCase, uploadFile, uploadFileToDataURL } from 'CommonJS/common'
import { Button } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import styled from '@emotion/styled'
import Loading from 'Components/Loading'
import { toast } from 'Components/Toast/toast'
import http from 'CommonJS/http'
import { AxiosResponse } from 'axios'
import { FileUploadResponse } from 'Context/AppTypes'
import FormDocumentViewer from './FormDocumentViewer'

interface VisaPortalStepDetailsProp {
  viewOnly: boolean
  studentId?: number
  /**
   *
   * @param showToast - default true
   * @param defaultStepInfo - default null
   * @returns {boolean}
   */
  canPerformAction: (showToast?: boolean, defaultStepInfo?: null) => boolean
  formStep: VisaFormStep
  studentMeta?: StudentMetaType
  onClose?: () => void
  onUpdate?: (studentMeta: Partial<StudentMetaType>, formStepInformation: StudentVisaPortalInfo, visaPortalStatus: string) => void
}
interface UploadFileContentType {
  file: File | null
  blob: string | ArrayBuffer | null
}

interface SaveStepFormResponseType {
  success: boolean
  message?: string
  visaPortalStepInfo: StudentVisaPortalInfo
  visaPortalStatus: string
}

export default function VisaPortalStepDetails({
  viewOnly = false,
  studentId,
  canPerformAction,
  formStep,
  studentMeta,
  onClose,
  onUpdate
}: VisaPortalStepDetailsProp) {
  const [loading, setLoading] = useState<boolean>(false)
  const [value, setValue] = useState(null)
  const [uploadedFileContent, setUploadedFileContent] = useState<UploadFileContentType | null>(null)

  const AllowedFileTypes = ['application/pdf', 'image/*']

  useEffect(() => {
    const metaKey = getStepMetaKey()
    if (studentMeta && metaKey in studentMeta) {
      setValue(studentMeta[metaKey])
    }
  }, [])

  function getStepMetaKey() {
    return toCamelCase(`${'visa'}-${formStep.slug}`)
  }

  function isValidateFileType(fileType: string) {
    return AllowedFileTypes.includes(fileType) || (fileType.startsWith('image/') && AllowedFileTypes.includes('image/*'))
  }

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!canPerformAction()) return

    try {
      event.preventDefault()
      const imageFile = event.target.files && event.target.files[0]
      const fileType = imageFile?.type ?? ''

      if (!isValidateFileType(fileType)) {
        toast('Invalid file uploaded. Allowed file type [pdf, image/*]', 'error')
        return
      }
      setLoading(true)
      if (imageFile) {
        setUploadedFileContent(null)
        const blobContent = await uploadFileToDataURL(imageFile)
        setUploadedFileContent({
          file: imageFile,
          blob: blobContent
        })
      }

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const handleSubmit = async () => {
    if (!canPerformAction()) return
    if (!uploadedFileContent || !uploadedFileContent.file) {
      toast('Invalid file uploaded', 'error')
      return
    }
    if (!isValidateFileType(uploadedFileContent.file.type)) {
      toast('Invalid file uploaded. Allowed file type [pdf, image/*]', 'error')
      return
    }

    setLoading(true)
    const uploadResponse = (await uploadFile(uploadedFileContent.file, studentId)) as FileUploadResponse
    if (!uploadResponse || !uploadResponse.success || !uploadResponse.document) {
      setLoading(false)
      toast(uploadResponse?.message ?? 'Error while uploading file', 'error')
      return
    }

    http
      .fetch({
        method: 'post',
        path: `/visa-dashboard/saveStepForm/${studentId}/${formStep.id}`,
        data: {
          [getStepMetaKey()]: uploadResponse?.document.id
        }
      })
      .catch(({ response }: { response: void | AxiosResponse<{ message: string }> }) => {
        toast(response?.data.message ?? 'Internal server error.', 'error')
        setLoading(false)
      })
      .then((response: void | AxiosResponse<SaveStepFormResponseType>) => {
        if (response && response.status === 200) {
          const message = response?.data?.message ?? ''
          if (message) toast(message, 'success')
          if (onUpdate) {
            onUpdate(
              {
                [getStepMetaKey()]: uploadResponse?.document?.fullPath
              },
              response.data.visaPortalStepInfo,
              response.data.visaPortalStatus
            )
          }
          onClose && onClose()
        }
      })
  }

  const isPDF = (filePath: string, checkFileType = false) => {
    return filePath.split('.').pop() === 'pdf' || (checkFileType && !filePath.startsWith('image/'))
  }

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1
  })

  return (
    <div className='w-full h-full fixed justify-center items-center bg-black/50  inset-x-0 inset-y-0 border z-[190] p-10 flex md:p-5'>
      {loading && <Loading />}
      <div className='bg-white rounded-md p-5 relative overflow-hidden w-full h-full'>
        {/* Main Content */}
        <div className='flex justify-between md:flex-col'>
          <label className='uppercase tracking-wide text-gray-500 text-sm mb-2'>{formStep.name}</label>
          {(uploadedFileContent || !viewOnly) && (
            <div className='flex gap-3 justify-end sm:w-full'>
              {uploadedFileContent && (
                <button className='btn-secondary' onClick={() => setUploadedFileContent(null)}>
                  Reset
                </button>
              )}
              {!viewOnly && (
                <Button
                  component='label'
                  className='!shadow-none'
                  role={undefined}
                  variant='contained'
                  tabIndex={-1}
                  disabled={canPerformAction(false) === false}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload file
                  <VisuallyHiddenInput
                    type='file'
                    accept='application/pdf, image/*'
                    onChange={(event) => handleFileChange(event)}
                    onClick={(e) => {
                      if (!canPerformAction()) e.preventDefault()
                    }}
                  />
                </Button>
              )}
            </div>
          )}
        </div>
        <div className='mt-4 h-[calc(100%-115px)] md:h-[calc(100%-130px)] border border-slate-200'>
          {uploadedFileContent ? (
            <FormDocumentViewer
              src={String(uploadedFileContent.blob)}
              title={uploadedFileContent.file ? 'Uploaded Content' : 'Image Document'}
              isPDF={isPDF(uploadedFileContent.file?.type ?? '', true)}
            />
          ) : (
            <FormDocumentViewer
              src={value ?? ''}
              srcDoc={!value && 'No Content Found'}
              title={'Document'}
              isPDF={!value || isPDF(value ?? '')}
            />
          )}
        </div>
        {/* Footer */}
        <div className='flex justify-center items-center gap-6 mt-5 md:mt-3'>
          {!viewOnly && (
            <button
              className={`btn-primary ${!uploadedFileContent ? 'cursor-not-allowed opacity-50' : ''}`}
              onClick={() => {
                if (!canPerformAction(true)) return
                if (uploadedFileContent) {
                  handleSubmit()
                } else {
                  toast('Please upload file', 'error')
                }
              }}
            >
              Submit
            </button>
          )}
          <button className='btn-secondary' onClick={() => onClose && onClose()}>
            Close
          </button>
        </div>
      </div>
    </div>
  )
}
