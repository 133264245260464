import { createContext, ReactNode, useReducer } from 'react'
import UserReducer from './reducer'
import { UserRole } from './AppTypes'
import { isStudent } from 'CommonJS/roleHelper'
import { PermissionEnum } from 'CommonJS/PermissionEnum'

type Props = {
  children?: ReactNode
}
interface adviorsObject {
  id: number
  phoneNumber: string
  photoLink: string
}
interface studentObject {
  id: number
}

export interface userObject {
  id: number
  name?: string
  email?: string
  roles: UserRole[]
  permissions?: PermissionEnum[]
  lastLogin?: string
}
interface impersonatedByObject {
  user: userObject
  token: string
}

type TypeUserContext = {
  data: {
    id: number
    name: string
    email: string
    advisor?: adviorsObject
    student?: studentObject
    emailVerifiedAt?: string | null
    twoFactorSecret?: string | null
    twoFactorRecoveryCodes?: string[] | null
    twoFactorConfirmedAt?: string | null
    roles: UserRole[]
    permissions: PermissionEnum[]
    lastLogin: string
    createdAt: string
    updatedAt: string
    filterableFields: string[] | null
    displayFields: string[] | null
    impersonatedBy?: impersonatedByObject
  }
  dispatch: (newState: any) => void
}

const initialValue = {
  data: {
    id: 0,
    name: '',
    email: '',
    emailVerifiedAt: null,
    twoFactorSecret: null,
    twoFactorRecoveryCodes: null,
    twoFactorConfirmedAt: null,
    roles: [],
    permissions: [],
    advisor: {
      id: 0,
      phoneNumber: '',
      photoLink: ''
    },
    student: {
      id: 0
    },
    lastLogin: '',
    createdAt: '',
    updatedAt: '',
    filterableFields: [''],
    displayFields: ['']
  },
  dispatch: () => {}
}

const UserContext = createContext<TypeUserContext>(initialValue)

const loadPersistedData = () => {
  const persistedData = localStorage.getItem('user')
  let data = initialValue.data
  if (persistedData) {
    try {
      data = JSON.parse(persistedData)
    } catch (e) {
      data = initialValue.data
    }
  }

  if (isStudent(data?.roles) && !data.student) {
    localStorage.clear() // to trigger logout
  }

  return data
}

const UserProvider = ({ children }: Props) => {
  const [data, dispatch] = useReducer(UserReducer, loadPersistedData())

  return <UserContext.Provider value={{ data, dispatch }}>{children}</UserContext.Provider>
}

export { UserContext, UserProvider }
