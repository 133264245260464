import { FormControl, MenuItem, MenuProps, Select } from '@mui/material'
import Loading from 'Components/Loading'
import { ConsulateAppointmentContext } from 'Context/VisaPortal/ConsulateAppointmentContext'
import { ConsulateAppointmentTypes } from 'Context/VisaPortal/ConsulateAppointmentTypes'
import { useContext, useEffect, useState } from 'react'
import { ConsulateAppointmentBlockFormType, StudentVisaPortalInfo } from './type'
import { Controller, useForm, useFormState } from 'react-hook-form'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { FaSave } from 'react-icons/fa'
import http from 'CommonJS/http'
import { AxiosResponse } from 'axios'
import { toast } from 'Components/Toast/toast'
import dayjs from 'dayjs'
import { formatDate } from 'CommonJS/common'

interface ConsulateAppointmentBlockProps {
  className?: string
  student?: StudentVisaPortalInfo
  studentId?: number
  loadData: boolean
  onUpdateCallback?: (data: ConsulateAppointmentBlockFormType) => void
  /**
   *
   * @param showToast - default true
   * @param defaultStepInfo - default null
   * @returns {boolean}
   */
  canPerformAction: (showToast?: boolean, defaultStepInfo?: null) => boolean
}

export default function ConsulateAppointmentBlock({
  className,
  student,
  studentId,
  loadData,
  onUpdateCallback,
  canPerformAction
}: ConsulateAppointmentBlockProps) {
  const [loading, setLoading] = useState<boolean>(false)
  const {
    consulateJurisdictions,
    loadConsulateJurisdiction,
    altConsulateJurisdictions,
    loadAltConsulateJurisdiction,
    batchVisaProcesses,
    loadBatchVisaProcesses
  } = useContext(ConsulateAppointmentContext) as ConsulateAppointmentTypes

  const newEntryDefaultValue: ConsulateAppointmentBlockFormType = {
    consulateJurisdictionId: '',
    altConsulateJurisdictionId: '',
    appointmentDate: '',
    batchVisaProcessId: ''
  }

  const MenuProps: Partial<MenuProps> = {
    PaperProps: {
      style: {
        maxHeight: 320
      }
    }
  }

  const {
    handleSubmit,
    reset,
    control,
    setError,
    getValues,
    formState: { errors }
  } = useForm<ConsulateAppointmentBlockFormType>({ defaultValues: newEntryDefaultValue })

  const { dirtyFields } = useFormState({
    control
  })

  useEffect(() => {
    /**
     * Once the visa steps, step information is loaded then load consulate block data.
     * this is because in case visa application document step is not enabled by admin ("Show Icon" checkbox)
     * and student tries to access visa dashboard, then it will redirect back to admission dashboard.
     * in this case below data is useless.
     */
    const init = async () => {
      if (!loadData) return
      setLoading(true)
      batchVisaProcesses?.length === 0 && (await loadBatchVisaProcesses())
      consulateJurisdictions?.length === 0 && (await loadConsulateJurisdiction())
      altConsulateJurisdictions?.length === 0 && (await loadAltConsulateJurisdiction())
      setLoading(false)

      let appointmentDate = student?.appointmentDate
      if (appointmentDate) {
        appointmentDate = formatDate(appointmentDate)
      }

      reset({
        consulateJurisdictionId: student?.consulateJurisdictionId ?? '',
        altConsulateJurisdictionId: student?.altConsulateJurisdictionId ?? '',
        appointmentDate: appointmentDate ?? '',
        batchVisaProcessId: student?.batchVisaProcessId ?? ''
      })
    }

    init()
  }, [loadData])

  const handleSave = async () => {
    if (!canPerformAction()) return

    if (loading || Object.keys(dirtyFields).length === 0) {
      toast('Please update data before saving.', 'error')
      return
    }
    interface ApiErrorResponseErrorsType {
      consulateJurisdictionId?: string
      altConsulateJurisdictionId?: string
      appointmentDate?: string
      batchVisaProcessId?: string
    }
    setLoading(true)
    const payload = getValues()

    await http
      .fetch({
        method: 'post',
        path: `visa-dashboard/consulate-appointment/${studentId}`,
        data: payload
      })
      .then((response: void | AxiosResponse<{ message: string }>) => {
        if (response && response.status === 200) {
          toast(response?.data.message ?? 'Data updated successfully', 'success')
        }
        onUpdateCallback && onUpdateCallback(payload)
        reset(payload)
        setLoading(false)
      })
      .catch(({ response }: { response: void | AxiosResponse<{ message: string; errors: ApiErrorResponseErrorsType }> }) => {
        toast(response?.data.message ?? 'Internal server error.', 'error')
        setLoading(false)
        Object.keys(response?.data?.errors ?? {}).forEach((error) => {
          const errorKey = error as keyof ApiErrorResponseErrorsType
          const errors = response?.data?.errors[errorKey]
          if (errors && errors?.length > 0) {
            setError(errorKey, { message: errors[0] })
          }
        })
      })
  }

  return (
    <div
      className={`bg-white rounded-md w-full p-4 flex flex-row md:flex-col relative justify-around gap-4 items-center ${className}${loading ? ' cursor-progress' : ''}`}
    >
      {loading && <Loading className='!absolute !z-50' />}
      <div className='w-full'>
        <h3 className='text-xl font-bold mb-2'>Consulate Jurisdiction</h3>
        <FormControl fullWidth>
          <Controller
            name='consulateJurisdictionId'
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                id='visa-dashboard-consulate-jurisdiction'
                data-cy='consulate-jurisdiction'
                className='w-full'
                displayEmpty={true}
                value={value}
                onChange={onChange}
                disabled={canPerformAction(false) === false}
                MenuProps={MenuProps}
              >
                <MenuItem value=''>None</MenuItem>
                {consulateJurisdictions?.map((option) => {
                  return (
                    <MenuItem key={`consulate-jurisdiction-${option.id}`} value={option.id}>
                      {option.name}
                    </MenuItem>
                  )
                })}
              </Select>
            )}
          />
        </FormControl>
        {errors?.consulateJurisdictionId && <div className='text-sm text-red-600'>{errors?.consulateJurisdictionId?.message}</div>}
      </div>
      <div className='w-full'>
        <h3 className='text-xl font-bold mb-2'>Alt Consulate Jurisdiction</h3>
        <FormControl fullWidth>
          <Controller
            name='altConsulateJurisdictionId'
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                id='visa-dashboard-alt-consulate-jurisdiction'
                data-cy='alt-consulate-jurisdiction'
                className='w-full'
                displayEmpty={true}
                value={value}
                onChange={onChange}
                disabled={canPerformAction(false) === false}
                MenuProps={MenuProps}
              >
                <MenuItem value=''>None</MenuItem>
                {altConsulateJurisdictions?.map((option) => {
                  return (
                    <MenuItem key={`alt-consulate-jurisdiction-${option.id}`} value={option.id}>
                      {option.name}
                    </MenuItem>
                  )
                })}
              </Select>
            )}
          />
        </FormControl>
        {errors?.altConsulateJurisdictionId && <div className='text-sm text-red-600'>{errors?.altConsulateJurisdictionId?.message}</div>}
      </div>
      <div className='w-full'>
        <h3 className='text-xl font-bold mb-2'>Appointment Date</h3>
        <FormControl fullWidth>
          <Controller
            name='appointmentDate'
            control={control}
            render={({ field: { onChange, value } }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  onChange={(e) => {
                    onChange(formatDate(e) ?? '')
                  }}
                  value={dayjs(value)}
                  format='MM/DD/YYYY'
                  disabled={canPerformAction(false) === false}
                  slotProps={{
                    textField: {
                      error: false
                    },
                    actionBar: {
                      actions: ['clear']
                    }
                    // textField: {
                    //   helperText: 'MM/DD/YYYY'
                    // }
                  }}
                />
              </LocalizationProvider>
            )}
          />
        </FormControl>
        {errors?.appointmentDate && <div className='text-sm text-red-600'>{errors?.appointmentDate?.message}</div>}
      </div>
      <div className='w-full'>
        <h3 className='text-xl font-bold mb-2'>Batch Visa Process</h3>
        <FormControl fullWidth>
          <Controller
            name='batchVisaProcessId'
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                id='visa-dashboard-batch0visa-process'
                data-cy='batch-visa=process'
                className='w-full'
                displayEmpty={true}
                value={value}
                onChange={onChange}
                disabled={canPerformAction(false) === false}
                MenuProps={MenuProps}
              >
                <MenuItem value=''>None</MenuItem>
                {batchVisaProcesses?.map((option) => {
                  return (
                    <MenuItem key={`batch-visa=process-${option.id}`} value={option.id}>
                      {option.name}
                    </MenuItem>
                  )
                })}
              </Select>
            )}
          />
        </FormControl>
        {errors?.batchVisaProcessId && <div className='text-sm text-red-600'>{errors?.batchVisaProcessId.message}</div>}
      </div>
      <div className='flex flex-col justify-between gap-3'>
        <div className='md:hidden'>&nbsp;</div>
        <button
          className={`btn-primary text-lg py-4 px-4 flex gap-2 items-center md:px-7 ${Object.keys(dirtyFields).length === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
          onClick={handleSubmit(handleSave)}
        >
          <span className='hidden md:block'>Save</span>
          <FaSave />
        </button>
      </div>
    </div>
  )
}
