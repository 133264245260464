import http from 'CommonJS/http'
import AdminSetting from 'Components/AdminSetting'
import Loading from 'Components/Loading'
import { toast } from 'Components/Toast/toast'
import { useEffect, useState } from 'react'
import { FaEdit, FaEye } from 'react-icons/fa'
import ReactJsPagination from 'react-js-pagination'
import { Link } from 'react-router-dom'
import Description from './Description'
import { TemplateData } from './type'
import { Switch } from '@mui/material'
import { AxiosResponse } from 'axios'

export default function EmailTemplates() {
  interface Template {
    currentPage: number
    data: TemplateData[]
    firstPageUrl: string
    from: number
    lastPage: number
    lastPageUrl: string
    links: {
      url: string | null
      label: string
      active: boolean
    }[]
    nextPageUrl: string | null
    path: string
    perPage: number
    prevPageUrl: string | null
    to: number
    total: number
  }

  interface FieldDataUpdateResponseType {
    message: string
    success: boolean
  }

  const [templates, setTemplates] = useState<Template>()
  const [loading, setLoading] = useState(false)
  const [showDescription, setShowDescription] = useState<TemplateData | null>(null)
  const [page, setPage] = useState<number>(1)

  useEffect(() => {
    setLoading(true)
    getTemplateData()
  }, [])

  const getTemplateData = async (pageNumber = 1) => {
    setPage(pageNumber)
    await http
      .fetch({ path: `templates?page=${pageNumber}` })
      .catch(() => {
        toast('Internal Server Error.', 'error')
      })
      .then((response: any) => {
        setLoading(false)
        if (response && response.status === 200) {
          setTemplates(response.data.templates)
        }
      })
  }

  const handleChange = (newField: Partial<TemplateData>, refreshTable: boolean = true) => {
    const newData = newField
    http
      .fetch({ method: `put`, path: `/templates/${newField?.id}`, data: newData })
      .catch((error: any) => {
        toast(error.response?.data?.message || 'Internal server error', 'error')
      })
      .then((response: void | AxiosResponse<FieldDataUpdateResponseType>) => {
        setLoading(false)
        if (response && response.status === 200) {
          toast(response?.data?.message || 'Successfully updated data', 'success')
          if (refreshTable) {
            getTemplateData(page)
          }
        }
      })
  }

  return (
    <>
      {loading && <Loading />}
      <div className='flex w-full min-h-screen md:flex-wrap md:min-h-full lg:flex-col'>
        <AdminSetting />
        <div className='px-7 py-5 w-full lg:px-4 min-w-[calc(100%-15rem)]' data-cy='email-template-page'>
          <div className='flex justify-between items-center'>
            <h1 className='text-3xl font-bold decoration-1'>Email Templates</h1>
          </div>

          <div className='overflow-auto'>
            <table className='w-full mt-5 lg:w-max' data-cy='email-template-table'>
              <thead>
                <tr className='[&>*]:bg-primary [&>*]:text-white [&>*]:tracking-wider [&>*]:py-3 [&>*]:border-slate-500 [&>*]:text-left'>
                  <th>Id</th>
                  <th>Subject</th>
                  <th>Key</th>
                  <th>Description</th>
                  <th>Enabled?</th>
                  <th>
                    Send copy to Advisor,
                    <br />
                    Hubspot and Admission?
                  </th>
                  {/* <th>In App Notification?</th> */}
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {templates?.data.length === 0 || !templates ? (
                  <>
                    <tr>
                      <td colSpan={6}>No Email Template Found</td>
                    </tr>
                  </>
                ) : (
                  <>
                    {templates?.data?.map((template) => (
                      <tr key={template.id}>
                        <td>
                          <strong>{template?.id}</strong>
                        </td>
                        <td>{template?.subject}</td>
                        <td>{template?.key}</td>
                        <td>
                          <div className='w-max'>
                            {template?.description ? (
                              <a
                                className='btn btn-secondary cursor-pointer flex items-center gap-x-3'
                                onClick={() => setShowDescription(template)}
                              >
                                View <FaEye />
                              </a>
                            ) : (
                              'No description available'
                            )}
                          </div>
                        </td>
                        <td>
                          <Switch
                            checked={template?.isEnabled}
                            inputProps={{ 'aria-label': 'controlled' }}
                            onChange={(e) => {
                              const updatedTemplate = { id: template.id, isEnabled: e.target.checked }
                              setLoading(true)
                              handleChange(updatedTemplate)
                            }}
                          />
                        </td>
                        <td>
                          <Switch
                            checked={template?.sendCopy}
                            inputProps={{ 'aria-label': 'controlled' }}
                            onChange={(e) => {
                              const updatedTemplate = { id: template.id, sendCopy: e.target.checked }
                              setLoading(true)
                              handleChange(updatedTemplate)
                            }}
                          />
                        </td>
                        {/* <td>
                          <Switch
                            checked={template?.inAppNotification}
                            inputProps={{ 'aria-label': 'controlled' }}
                            onChange={(e) => {
                              const updatedTemplate = { id: template.id, inAppNotification: e.target.checked }
                              setLoading(true)
                              handleChange(updatedTemplate)
                            }}
                          />
                        </td> */}
                        <td>
                          <div className='flex gap-5'>
                            <Link
                              to={`/admin/setting/template/edit?templateId=${template?.id}`}
                              className='btn btn-primary flex items-center gap-x-3'
                              data-cy='email-template-edit'
                            >
                              Edit <FaEdit />
                            </Link>
                            <Link
                              to={`/admin/setting/template/view?templateId=${template?.id}`}
                              className='btn btn-secondary flex items-center gap-x-3'
                              data-cy='email-template-view'
                            >
                              View <FaEye />
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
          {Boolean(templates?.data?.length ?? 0 > 0) && (
            <div className='mr-10 [&>ul]:flex [&>ul]:gap-3 [&>*_a]:py-2 [&>*_a]:px-4 [&>*_a]:lg:py-1 [&>*_a]:lg:px-3 [&>*_a]:inline-block mt-4 [&>*_a]:border [&>*_a:hover]:bg-primary [&>*_a:hover]:text-white [&>*_li.active>a]:bg-primary [&>*_li.active>a]:text-white'>
              <ReactJsPagination
                activePage={templates?.currentPage || 1}
                itemsCountPerPage={templates?.perPage}
                totalItemsCount={templates?.total || 0}
                pageRangeDisplayed={templates?.to}
                onChange={(pageNumber) => {
                  getTemplateData(pageNumber)
                }}
              />
            </div>
          )}

          {showDescription !== null && <Description template={showDescription} onClose={() => setShowDescription(null)} />}
        </div>
      </div>
    </>
  )
}
