import { useContext } from 'react'
import Label from '../Label'
import { DetailPropsType } from '../type'
import { subStepContext } from '../context'
import { Controller } from 'react-hook-form'
import Description from '../Description'

function TextareaType({ item, control, isRenderConditionalComponent, isViewOnly }: DetailPropsType) {
  const {
    state: { currentPage }
  } = useContext(subStepContext)

  if (isRenderConditionalComponent === false || currentPage !== item?.pageNumber || item?.visibility === 'hidden') return null

  return (
    <div className='mt-2'>
      <div className='flex justify-start md:flex-col'>
        <Label structure={item} />

        <div className='flex justify-start w-[70%] gap-x-1 md:w-full md:block'>
          <div className='w-full'>
            <Controller
              control={control}
              name={item?.id?.toString()}
              rules={isViewOnly ? {} : { required: { value: item?.isRequired, message: 'This field is required.' } }}
              render={({ field: { ref, value, onChange }, fieldState: { error } }) => {
                return (
                  <div className='w-full'>
                    <textarea className='form-input' ref={ref} value={value} onChange={onChange} rows={5} disabled={isViewOnly} />
                    {error && <p className='text-left text-red-500 text-sm'>{error?.message}</p>}
                  </div>
                )
              }}
            />
            <Description structure={item} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TextareaType
