import { ConsulateAppointmentContext } from 'Context/VisaPortal/ConsulateAppointmentContext'
import { StatusInfoType, ConsulateAppointmentTypes } from 'Context/VisaPortal/ConsulateAppointmentTypes'
import { useContext, useEffect, useState } from 'react'
import { useForm, useFormState } from 'react-hook-form'
import { FaEdit, FaSave, FaTrashAlt } from 'react-icons/fa'
import { FormDataType } from './ConsulateAppointmentFormTypes'
import http from 'CommonJS/http'
import { AxiosResponse } from 'axios'
import { toast } from 'Components/Toast/toast'
import { FaX } from 'react-icons/fa6'
import ConfirmationModal from 'Components/ConfirmationModal'
import Loading from 'Components/Loading'

export default function StatusInfo() {
  const [loading, setLoading] = useState<boolean>(false)
  const [saveLoading, setSaveLoading] = useState<boolean>(false)
  const { statusInfos, loadStatusInfo, updateStatusInfos } = useContext(ConsulateAppointmentContext) as ConsulateAppointmentTypes
  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false)
  const [selectRowId, setSelectRowId] = useState<number | null>(null)
  const [editData, setEditData] = useState<StatusInfoType | null>(null)
  const [editError, setEditError] = useState<string | null>(null)

  useEffect(() => {
    const init = async () => {
      setLoading(true)
      if (statusInfos?.length === 0) await loadStatusInfo()

      setLoading(false)
    }
    init()
  }, [])

  const newEntryDefaultValue: FormDataType = {
    name: ''
  }

  const {
    register,
    handleSubmit,
    reset,
    control,
    setError,
    formState: { errors }
  } = useForm<FormDataType>({ defaultValues: newEntryDefaultValue })

  const { dirtyFields } = useFormState({
    control
  })

  const handleCreateNew = async (formData: FormDataType) => {
    setSaveLoading(true)
    await http
      .fetch({ method: 'post', path: 'setting/statusInfo', data: { name: formData.name } })
      .then((response: void | AxiosResponse<{ statusInfo: StatusInfoType }>) => {
        if (response && response.status === 200) {
          updateStatusInfos([...statusInfos, response.data.statusInfo])
          reset({ name: '' })
          toast('New Status Info Created Successfully', 'success')
        }
        setSaveLoading(false)
      })
      .catch(({ response }) => {
        toast(response?.data.message ?? 'Internal server error.', 'error')
        setSaveLoading(false)
        const nameErrors = response?.data?.errors?.name
        if (nameErrors && nameErrors?.length > 0) {
          setError('name', { message: nameErrors[0] })
        }
      })
  }

  const handleDelete = async () => {
    if (!selectRowId) {
      toast('Something went wrong, please reload window and try again.', 'error')
      return
    }
    setSaveLoading(true)
    await http
      .fetch({ method: 'delete', path: `setting/statusInfo/${selectRowId}` })
      .then((response: void | AxiosResponse<{ success: boolean; message?: string }>) => {
        if (response && response.status === 200 && response.data.success) {
          const newStatusInfo = statusInfos.filter((sI) => sI.id !== selectRowId)
          updateStatusInfos(newStatusInfo)
          toast(response.data?.message ?? 'Status Info Deleted Successfully', 'success')
        }
        setSaveLoading(false)
        setConfirmModalOpen(false)
      })
      .catch(({ response }) => {
        toast(response?.data.message ?? 'Internal server error.', 'error')
        setSaveLoading(false)
      })
  }

  const handleEdit = async () => {
    if (!editData || !editData.id) {
      toast('Something went wrong, please reload window and try again.', 'error')
      return
    }
    if (!editData.name) {
      setEditError('Status Info name is required')
      return
    }

    setSaveLoading(true)
    setEditError(null)
    await http
      .fetch({ method: 'put', path: `setting/statusInfo/${editData.id}`, data: editData })
      .then((response: void | AxiosResponse<{ success: boolean; message?: string }>) => {
        if (response && response.status === 200 && response.data.success) {
          const newStatusInfo = statusInfos.map((cJ) => {
            if (cJ.id === editData.id) {
              cJ.name = editData.name
            }
            return cJ
          })
          updateStatusInfos(newStatusInfo)
          toast(response.data?.message ?? 'Status Info Updated Successfully', 'success')
          setEditData(null)
        }
        setSaveLoading(false)
      })
      .catch(({ response }) => {
        toast(response?.data.message ?? 'Internal server error.', 'error')
        setSaveLoading(false)
        const nameErrors = response?.data?.errors?.name
        if (nameErrors && nameErrors?.length > 0) {
          setEditError(nameErrors[0])
        }
      })
  }

  return (
    <div className='w-full'>
      {saveLoading && <Loading />}
      <h1 className='text-3xl font-bold md:text-2xl'>Status Info</h1>

      <div className='w-full gap-y-4 flex flex-col md:w-full overflow-x-auto mt-4'>
        <table className='table-auto lg:w-max' data-cy='default-table'>
          <thead>
            <tr>
              <th className='w-1/6'>Id</th>
              <th>Name</th>
              <th className='w-1/3'>Action</th>
            </tr>
          </thead>
          <tbody>
            {!loading && (!statusInfos || statusInfos.length === 0) && (
              <tr>
                <td colSpan={3}>No Status Info Found</td>
              </tr>
            )}
            {loading &&
              Array.from(Array(2)).map((_, index) => (
                <tr key={`loaderStatusInfo_${index}`} className='animate-pulse cursor-progress'>
                  <td>
                    <span className='block w-5 h-6 bg-primary/20 rounded'></span>
                  </td>
                  <td>
                    <span className='block w-40 md:w-20 h-6 bg-primary/20 rounded'></span>
                  </td>
                  <td>
                    <div className='flex gap-3'>
                      <span className='flex gap-3 py-3 px-7 bg-primary/20 rounded items-center'>
                        <span className='block w-8 h-5 bg-primary/20 rounded'></span>
                        <span className='block w-4 h-5 bg-primary/20 rounded'></span>
                      </span>
                      <span className='flex gap-3 py-3 px-7 bg-primary/20 rounded items-center'>
                        <span className='block w-12 h-5 bg-primary/20 rounded'></span>
                        <span className='block w-4 h-5 bg-primary/20 rounded'></span>
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
            {statusInfos?.map((statusInfo, index) => (
              <tr key={`statusInfo_${statusInfo.id}_${index}`}>
                <td>{statusInfo.id}</td>
                <td>
                  {editData && editData.id && editData.id === statusInfo.id ? (
                    <div className='w-full max-w-[60%]'>
                      <input
                        type='text'
                        className={`form-input`}
                        placeholder={`Enter Status Info`}
                        name={'name'}
                        value={editData.name}
                        onChange={(e) => {
                          const value = e.target.value as string
                          setEditData(() => {
                            return {
                              ...editData,
                              name: value
                            }
                          })
                        }}
                      />
                      {editError && (
                        <div className='text-left text-red-500 text-sm' data-cy='error'>
                          {editError}
                        </div>
                      )}
                    </div>
                  ) : (
                    <span>{statusInfo.name}</span>
                  )}
                </td>
                <td>
                  <div className='flex gap-3'>
                    {editData && editData.id && editData.id === statusInfo.id ? (
                      <>
                        <button className='btn btn-primary flex items-center gap-x-3' data-cy='save-btn' onClick={handleEdit}>
                          Save <FaSave />
                        </button>
                        <button
                          data-cy='cancel-btn'
                          className='btn btn-secondary flex items-center gap-x-3'
                          onClick={() => {
                            setEditData(null)
                            setEditError(null)
                          }}
                        >
                          Cancel <FaX />
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className='btn btn-primary flex items-center gap-x-3'
                          data-cy='edit-btn'
                          onClick={() => {
                            setEditData(statusInfo)
                          }}
                        >
                          Edit <FaEdit />
                        </button>
                        <button
                          data-cy='delete-btn'
                          className='btn btn-danger flex items-center gap-x-3'
                          onClick={() => {
                            setSelectRowId(statusInfo.id)
                            setConfirmModalOpen(true)
                          }}
                        >
                          Delete <FaTrashAlt size={14} />
                        </button>
                      </>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr className='[&_td]:border-t [&_td]:border-t-black'>
              <td colSpan={2}>
                <div className='flex gap-3 items-center'>
                  <span className='font-semibold'>Create New Status Info:</span>
                  <div className='w-full max-w-[60%]'>
                    <input
                      data-cy='name'
                      {...register('name', {
                        required: 'Status Info name is required'
                      })}
                      type='text'
                      className={`form-input ${saveLoading ? 'cursor-progress' : ''}`}
                      placeholder={`Enter Status Info`}
                      name={'name'}
                      disabled={saveLoading}
                    />
                    {errors?.name && (
                      <div className='text-left text-red-500 text-sm' data-cy='error'>
                        {errors.name.message}
                      </div>
                    )}
                  </div>
                </div>
              </td>
              <td>
                <div className='flex gap-3'>
                  <button
                    className={`btn btn-primary flex items-center gap-x-3 ${saveLoading ? 'cursor-progress opacity-75' : ''} ${Object.keys(dirtyFields).length === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={saveLoading || Object.keys(dirtyFields).length === 0}
                    title={Object.keys(dirtyFields).length === 0 ? 'Enter name to create new status info.' : ''}
                    data-cy='save-btn'
                    onClick={handleSubmit(handleCreateNew)}
                  >
                    Save <FaSave />
                  </button>
                  <button
                    className={`btn btn-secondary flex items-center gap-x-3 ${saveLoading ? 'cursor-progress opacity-75' : ''} ${saveLoading || Object.keys(dirtyFields).length === 0 ? 'cursor-not-allowed opacity-50' : ''}`}
                    disabled={saveLoading || Object.keys(dirtyFields).length === 0}
                    data-cy='cancel-btn'
                    onClick={() => {
                      if (!saveLoading || Object.keys(dirtyFields).length !== 0) {
                        reset({ name: '' })
                      }
                    }}
                  >
                    Cancel <FaX />
                  </button>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <ConfirmationModal
        title='Are you sure you want to delete?'
        isConfirmModalOpen={isConfirmModalOpen}
        setConfirmModalOpen={setConfirmModalOpen}
        onAction={handleDelete}
      />
    </div>
  )
}
