import { Close } from '@mui/icons-material'
import { closeSnackbar, enqueueSnackbar } from 'notistack'

type BaseVariant = 'default' | 'error' | 'success' | 'warning' | 'info'

const alreadyExistCheck = new Map()
export const toast = (message: any, variant: BaseVariant, options?: object) => {
  const uniqueKey = Math.random().toString(30)
  const toastMessage = typeof message == 'string' ? message : 'Something went wrong'

  /**
   * This is to avoid multiple same error message (of student dashboard)
   * when student is deleted and 404 on api call
   */
  const uniqueCheckKey = btoa(String(toastMessage).toLowerCase())
  const isTargetMessage =
    toastMessage.startsWith('No query results for model [App\\Models\\Student]') || toastMessage.startsWith('Internal Server Error')
  if (isTargetMessage && alreadyExistCheck.has(uniqueCheckKey)) {
    setTimeout(() => {
      alreadyExistCheck.delete(uniqueCheckKey)
    }, 3500)
    return
  }
  alreadyExistCheck.set(uniqueCheckKey, true)

  enqueueSnackbar(toastMessage, {
    key: uniqueKey,
    variant: variant,
    ...options,
    action: () => (
      <div className='cursor-pointer' onClick={() => closeSnackbar(uniqueKey)}>
        <Close />
      </div>
    )
  })
}
