import http from 'CommonJS/http'
import ImageSelector from 'Components/ImageSelector'
import { AppContext } from 'Context/AppContext'
import { AppContextType } from 'Context/AppTypes'
import { SetStateAction, useContext, useEffect, useState } from 'react'
import { FaEdit, FaEye, FaSave } from 'react-icons/fa'
import { VisaPortalInfoSettingType, VisaPortalStep } from './type'
import { toast } from 'Components/Toast/toast'
import Loading from 'Components/Loading'
// import TinyEditor from 'Components/TinyEditor'
import CKEditors from 'Components/CKEditor'
import { FaX } from 'react-icons/fa6'
import { AxiosResponse } from 'axios'
import VisaPortalInfoSetting from './VisaPortalInfoSetting'

export default function VisaPortal() {
  const [formData, setFormData] = useState<VisaPortalStep[]>([])
  const [visaSettings, setVisaSettings] = useState<VisaPortalInfoSettingType>()
  const [showEditor, setShowEditor] = useState<boolean>(false)
  const [view, setView] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { uploadFile } = useContext(AppContext) as AppContextType
  const [stepData, setStepData] = useState<VisaPortalStep>()
  const [editData, setEditData] = useState<VisaPortalStep | null>(null)
  const [editNameError, setEditNameError] = useState<string | null>(null)
  const [editOrderError, setEditOrderError] = useState<string | null>(null)

  useEffect(() => {
    getVisaPortalStep()
  }, [])

  interface GetVisaPortalStepResponse {
    success: boolean
    visaPortalSteps: VisaPortalStep[]
    visaPortalSettings: VisaPortalInfoSettingType
  }

  const getVisaPortalStep = async () => {
    setLoading(true)
    http
      .fetch({ path: 'setting/visaPortalStep' })
      .catch(() => {
        setLoading(false)
        toast('Internal Server Error.', 'error')
      })
      .then((response: void | AxiosResponse<GetVisaPortalStepResponse>) => {
        setLoading(false)
        if (response && response.status === 200) {
          setFormData(response.data.visaPortalSteps)
          setVisaSettings(response.data.visaPortalSettings)
        }
      })
  }

  const changeFileState = async (file: SetStateAction<File | undefined>, VisaPortalStepId: number | string) => {
    try {
      await setLoading(true)
      if (file instanceof File && file.type) {
        const fileRes = await uploadFile(file)
        if (fileRes) {
          const iconUpload = await fileRes?.document.id
          if (fileRes?.document.id) {
            const payload = {
              icon: iconUpload
            }
            http
              .fetch({ method: 'post', path: `setting/visaPortalStep/${VisaPortalStepId}`, data: payload })
              .catch((error) => {
                toast(error?.response?.data?.message, 'error')
              })
              .then((response) => {
                if (response && response.status === 200) {
                  getVisaPortalStep()
                  setLoading(false)
                  toast(response?.data?.message, 'success')
                }
              })
          }
        }
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const handleEdit = async () => {
    if (!editData || !editData.id) {
      toast('Something went wrong, please reload window and try again.', 'error')
      return
    }
    if (!editData.name) {
      setEditNameError('Name is required')
      return
    }
    if (!editData.order) {
      setEditOrderError('Order is required')
      return
    }
    setLoading(true)
    setEditNameError(null)
    setEditOrderError(null)
    await http
      .fetch({ method: 'put', path: `/setting/visaPortalStep/${editData.id}`, data: { name: editData.name, order: editData.order } })
      .catch((error) => {
        setLoading(false)
        toast(error?.response?.data?.message, 'error')
      })
      .then((response) => {
        if (response && response.status === 200) {
          setFormData((prevFormData) => {
            const updatedItem = response.data.visaPortalStep
            return prevFormData.map((item) => (item.id === updatedItem.id ? updatedItem : item))
          })
          toast(response?.data?.message, 'success')
          setLoading(false)
          setEditData(null)
        }
      })
  }

  const handleInstruction = (value: string, settingType: string) => {
    setLoading(true)
    const payload = {
      [settingType]: value
    }
    http
      .fetch({ method: 'put', path: `setting/visaPortalStep/${stepData?.id}`, data: payload })
      .catch((error) => {
        setLoading(false)
        toast(error?.response?.data?.message, 'error')
      })
      .then((response) => {
        setLoading(false)
        if (response && response.status === 200) {
          setFormData(formData.map((step) => (step.id === stepData?.id ? { ...step, [settingType]: value } : step)))
          toast(response?.data?.message, 'success')
        }
      })
  }
  return (
    <>
      {loading && <Loading />}
      {!showEditor ? (
        <div className='w-full' data-cy='visa-portal-form-setting'>
          <div className='flex justify-between items-center min-h-12 mb-7 md:mb-5'>
            <h1 className='text-3xl font-bold uppercase md:text-2xl'>Visa Portal Form Settings</h1>
          </div>
          <VisaPortalInfoSetting visaPortalSettings={visaSettings} setVisaPortalSettings={(data) => setVisaSettings(data)} />
          <div className='overflow-x-auto lg:w-full w-[50vw] mt-4'>
            <table className='table-auto w-full md:table md:w-max' data-cy='visa-portal-form-setting-table'>
              <thead>
                <tr>
                  <th>Name</th>
                  <th className='w-[12%]'>Order</th>
                  <th className='w-[35%]'>Action</th>
                  <th>Icon</th>
                  <th>Instruction</th>
                </tr>
              </thead>
              <tbody>
                {!loading && (!formData || formData.length === 0) && (
                  <tr>
                    <td colSpan={5} className='text-center'>
                      No Visa Portal Found
                    </td>
                  </tr>
                )}
                {formData
                  ?.sort((a, b) => a.order - b.order)
                  ?.map((visaPortalStep, index) => (
                    <tr key={index}>
                      <td>
                        {editData && editData.id && editData.id === visaPortalStep.id ? (
                          <div>
                            <input
                              data-cy='name'
                              type='text'
                              className={`form-input`}
                              placeholder={`Enter Name`}
                              name={'name'}
                              value={editData.name}
                              onChange={(e) => {
                                const value = e.target.value as string
                                setEditData(() => {
                                  return {
                                    ...editData,
                                    name: value
                                  }
                                })
                              }}
                            />
                            {editNameError && (
                              <div className='text-left text-red-500 text-sm' data-cy='error'>
                                {editNameError}
                              </div>
                            )}
                          </div>
                        ) : (
                          <span>{visaPortalStep.name}</span>
                        )}
                      </td>
                      <td>
                        {editData && editData.id && editData.id === visaPortalStep.id ? (
                          <div>
                            <input
                              data-cy='order'
                              type='number'
                              min={1}
                              max={30}
                              className={`form-input`}
                              placeholder={`Enter Order`}
                              name={'order'}
                              value={editData.order}
                              onChange={(e) => {
                                const value = Number(e.target.value) as number
                                if (value >= 1 && value <= 30) {
                                  setEditData(() => {
                                    return {
                                      ...editData,
                                      order: value
                                    }
                                  })
                                }
                              }}
                            />
                            {editOrderError && (
                              <div className='text-left text-red-500 text-sm' data-cy='error'>
                                {editOrderError}
                              </div>
                            )}
                          </div>
                        ) : (
                          <span>{visaPortalStep.order}</span>
                        )}
                      </td>
                      <td>
                        <div className='flex gap-3'>
                          {editData && editData.id && editData.id === visaPortalStep.id ? (
                            <>
                              <button className='btn btn-primary flex items-center gap-x-3' data-cy='save-btn' onClick={handleEdit}>
                                Save <FaSave />
                              </button>
                              <button
                                data-cy='cancel-btn'
                                className='btn btn-secondary flex items-center gap-x-3'
                                onClick={() => {
                                  setEditData(null)
                                }}
                              >
                                Cancel <FaX />
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                className='btn btn-primary flex items-center gap-x-3'
                                data-cy='edit-btn'
                                onClick={() => {
                                  setEditData(visaPortalStep)
                                }}
                              >
                                Edit <FaEdit />
                              </button>
                            </>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className='flex items-center gap-4 justify-center'>
                          <ImageSelector
                            size={'65px'}
                            imageSrc={visaPortalStep?.icon}
                            id={visaPortalStep?.id}
                            key={`${visaPortalStep?.id}-${visaPortalStep?.icon}`}
                            changeFileState={changeFileState}
                          />
                        </div>
                      </td>
                      <td>
                        <div className='flex gap-2'>
                          <button
                            data-cy='instruction-edit'
                            className='btn-primary uppercase flex gap-3 items-center p-3'
                            onClick={() => {
                              setShowEditor(true)
                              setStepData(visaPortalStep)
                              setView(false)
                            }}
                          >
                            <FaEdit />
                          </button>
                          <button
                            data-cy='instruction-view'
                            className='btn-primary uppercase flex gap-3 items-center p-3'
                            onClick={() => {
                              setShowEditor(true)
                              setStepData(visaPortalStep)
                              setView(true)
                            }}
                          >
                            <FaEye />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <>
          <div className='w-full h-[calc(100vh-200px)] min-w-[870px] md:min-w-full'>
            <CKEditors
              initialValue={stepData?.instructions || ''}
              type={'instructions'}
              label={`${stepData?.name} - Instruction`}
              callSubmit={handleInstruction}
              isView={view}
              variable={[]}
              back={() => {
                setShowEditor(false)
              }}
            />
          </div>
        </>
      )}
    </>
  )
}
