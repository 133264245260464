import Loading from 'Components/Loading'
import { AppContext } from 'Context/AppContext'
import { AppContextType } from 'Context/AppTypes'
import { useContext, useEffect, useState } from 'react'

export default function Program() {
  const { programs, getPrograms } = useContext(AppContext) as AppContextType
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    const init = async () => {
      if (programs?.length === 0) await getPrograms()
      await setLoading(false)
    }
    init()
  }, [])
  return (
    <>
      {loading && <Loading />}
      <div className='flex justify-between items-center min-h-12 mb-7 md:mb-5'>
        <h1 className='text-3xl font-bold md:text-2xl'>Academic Programs</h1>
      </div>
      <div className='w-full gap-y-4 flex flex-col lg:overflow-x-auto'>
        <table className='table-auto'>
          <thead>
            <tr>
              <th>Id</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {programs?.map((program, index) => (
              <tr key={index}>
                <td>{program?.id}</td>
                <td>{program?.name}</td>
              </tr>
            ))}
            {programs?.length === 0 && (
              <tr>
                <td colSpan={2} className='text-center'>
                  No Programs Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}
