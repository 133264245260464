interface LoadingPropsType {
  className?: string
}

export default function Loading({ className }: LoadingPropsType) {
  return (
    <div
      className={`w-full h-full fixed flex justify-center items-center bg-white/50  inset-x-0 inset-y-0 border/ z-[9999] ${className ? className : ''}`}
    >
      <div className='spinner w-14 h-14'></div>
    </div>
  )
}
