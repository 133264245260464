import { UserContext } from 'Context/UserContext'
import { useContext, useEffect, useState } from 'react'
import { StudentVisaPortalInfo, ToggleActionType, VisaFormStep } from './type'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import http from 'CommonJS/http'
import { toast } from 'Components/Toast/toast'
import Loading from 'Components/Loading'
import { StatusEnum } from 'CommonJS/StatusEnum'
import EventBus from 'CommonJS/eventBus'
import NotesFooterBlock from 'Components/Dashboard/NotesFooterBlock'
import ConsulateAppointmentBlock from './ConsulateAppointmentBlock'
import { Icon } from 'Components/Dashboard/type'
import AdvisorInfo from 'Components/Dashboard/AdvisorInfo'
import { AxiosResponse } from 'axios'
import noImagePlaceholder from '../../assets/images/no-image-placeholder.png'
import { Checkbox } from '@mui/material'
import ConfirmationAlert from 'Components/ConfirmationAlert'
import { isAdmin, isStudent, isSubAdvisor } from 'CommonJS/roleHelper'
import VisaPortalStepInstruction from './VisaPortalStepInstruction'
import { AppContextType } from 'Context/AppTypes'
import { AppContext } from 'Context/AppContext'
import StudentBanner from 'Components/StudentBanner'
import VisaPortalStepDetails from './VisaPortalStepDetails'
import { StudentMetaType } from 'Components/Layout/type'
import { VisaPortalInfoSettingType } from 'Components/FormSyncSetting/type'

export default function VisaDashboard() {
  const { data } = useContext(UserContext)

  const [visaPortalStepInfo, setVisaPortalStepInfo] = useState<StudentVisaPortalInfo | undefined>()
  const match = useLocation()
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [formStepsList, setFormStepsList] = useState<VisaFormStep[]>([])
  const [icon, setActionIcon] = useState<Icon | null>(null)
  const [visaInfoFields, setVisaInfoFields] = useState<VisaPortalInfoSettingType>()
  const [toggleAction, setToggleAction] = useState<ToggleActionType>(resetToggleAction(true))
  const [viewInstruction, setViewInstruction] = useState<{ name: VisaFormStep['name']; instructions: VisaFormStep['instructions'] } | null>(
    null
  )
  const [activeFormStep, setActiveFormStep] = useState<VisaFormStep | null>(null)
  const [activeFormStepViewOnly, setActiveFormStepViewOnly] = useState<boolean>(false)
  const navigate = useNavigate()
  const [studentInfoLoaded, setStudentInfoLoaded] = useState<boolean>(false)

  const { visaPortalSetting, getVisaPortalSetting } = useContext(AppContext) as AppContextType
  let studentId: undefined | number
  if (match.pathname.includes('/visa-dashboard') && !isStudent(data.roles)) {
    studentId = Number(id)
  } else {
    studentId = data?.student?.id
  }

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (visaPortalSetting) {
      document.documentElement.style.setProperty('--theme-color', visaPortalSetting.themeColor)
      document.documentElement.style.setProperty('--font-color', visaPortalSetting.fontColor)
      document.documentElement.style.setProperty('--menu-color', '#F5F5DC')
    }
  }, [visaPortalSetting])

  const init = async () => {
    await setLoading(true)
    await getFormStepList()
    await getVisaPortalStepInfo()
    await setLoading(false)

    if (location.pathname.includes('/visa-dashboard') && Object.keys(visaPortalSetting ?? {})?.length === 0) {
      await getVisaPortalSetting()
    }

    setStudentInfoLoaded(true)
  }

  function canPerformAction(showToast = true, defaultStepInfo = null) {
    const localStepInfo = defaultStepInfo ?? visaPortalStepInfo
    const visaPortalStatus = localStepInfo?.visaPortalStatus ? localStepInfo?.visaPortalStatus : ''
    const can =
      localStepInfo?.deletedAt === null &&
      ![StatusEnum.REJECT as string, StatusEnum.WITHDRAW as string].includes(visaPortalStatus?.toLowerCase()) &&
      !isSubAdvisor(data.roles)

    if (!can && showToast) toast('Action cannot be performed on archived students.', 'warning')

    return can
  }

  const getVisaPortalStepInfo = () => {
    return http
      .fetch({ path: `visa-dashboard/${studentId}` })
      .catch(() => {
        toast('Internal server error.', 'error')
        setLoading(false)
      })
      .then((response) => {
        if (response && response.status === 200) {
          setVisaPortalStepInfo(response.data.student)
          EventBus.$dispatch('student:info-updated', response.data.student)

          if (!canPerformAction(false, response.data.student)) {
            const styleTag = document.createElement('style')
            styleTag.classList.add('disable-action-dynamic-style')
            styleTag.textContent =
              '.main-content :has(> .MuiButtonBase-root), .main-content button.btn-primary, .main-content button.btn-secondary { opacity: 50% !important; cursor: not-allowed !important; }'
            document.head.appendChild(styleTag)
          } else {
            document.head.querySelectorAll('.disable-action-dynamic-style')?.forEach((e) => e.remove())
          }
        }
      })
  }

  const getFormStepList = () => {
    return http
      .fetch({ path: `visa-dashboard/visaPortalSteps/${studentId}` })
      .catch(({ response }: { response: void | AxiosResponse<{ success: boolean; redirect: boolean; message: string }> }) => {
        const redirect = !response?.data?.success && response?.data?.redirect
        toast(response?.data?.message ?? 'Internal server error.', redirect ? 'info' : 'error')
        setLoading(false)
        if (redirect) {
          navigate('/')
        }
      })
      .then(
        (
          response: void | AxiosResponse<{
            success: boolean
            visaPortalSteps: VisaFormStep[]
            settingIcons: Icon
            visaInfoFields: VisaPortalInfoSettingType
          }>
        ) => {
          if (response && response.status === 200) {
            setFormStepsList(response.data.visaPortalSteps.sort((a, b) => a.order - b.order))
            setActionIcon(response.data.settingIcons)
            setVisaInfoFields(response.data.visaInfoFields)
          }
        }
      )
  }

  const handleCheckboxChange = () => {
    if (!canPerformAction()) {
      resetToggleAction()
      return
    }
    const payload = {
      [toggleAction.actionColumn]: toggleAction?.value
    }

    setLoading(true)
    http
      .fetch({ method: 'put', path: `saveVisaStepInfo/${studentId}/${toggleAction.subStepId}`, data: payload })
      .catch((error) => {
        setLoading(false)
        toast(error?.response?.data?.message, 'error')
        resetToggleAction()
      })
      .then((response) => {
        setLoading(false)
        if (response && response.status === 200) {
          if (formStepsList) {
            const updatedStudentStepInformation = formStepsList.map((item) => {
              if (item.id === toggleAction.subStepId) {
                return {
                  ...item,
                  studentStepInfo: response.data.stepInfo
                }
              }
              return item
            })
            setFormStepsList(updatedStudentStepInformation)
            if (response.data.visaPortalStatus) {
              const newVisaPortalStepInfo = {
                ...visaPortalStepInfo,
                visaPortalStatus: response.data.visaPortalStatus
              }
              setVisaPortalStepInfo(newVisaPortalStepInfo as StudentVisaPortalInfo)
            }
          }
          toast(response?.data?.message, 'success')
        }
        resetToggleAction()
      })
  }

  function resetToggleAction(valueOnly = false): ToggleActionType {
    const defaultValue = {
      invoke: false,
      subStepId: 0,
      actionColumn: '',
      value: false
    }

    if (valueOnly) {
      return defaultValue
    }

    setToggleAction(defaultValue)

    return defaultValue
  }

  const handleStepDetailUpdate = (
    studentMeta: Partial<StudentMetaType>,
    formStepInformation: StudentVisaPortalInfo,
    visaPortalStatus: string
  ) => {
    const newStepInfo = {
      ...(visaPortalStepInfo ?? {}),
      studentMeta: { ...(visaPortalStepInfo?.studentMeta ?? {}), ...studentMeta },
      visaPortalStatus: visaPortalStatus
    }
    setVisaPortalStepInfo(newStepInfo as StudentVisaPortalInfo)

    const newFormList = formStepsList?.map((item) => {
      if (item.id === formStepInformation.visaPortalStepId && item.studentStepInfo?.id === formStepInformation.id) {
        return {
          ...item,
          studentStepInfo: { ...item.studentStepInfo, ...formStepInformation }
        }
      }

      return item
    })

    setFormStepsList(newFormList)
  }

  return (
    <>
      <div className={`main-content py-7 px-5 bg-[#e6e4e5] w-full h-[calc(100vh-77px)] overflow-auto md:px-4 lg:h-auto`}>
        {studentId && <StudentBanner id={studentId} />}
        <div className='mb-7 flex justify-between flex-wrap gap-1 min-h-32 md:min-h-[auto]'>
          <div className='gap-2 flex flex-col justify-between'>
            <h2 className='text-3xl font-bold text-primary mb-2'>Italian Study Visa Documents Dashboard</h2>
          </div>
          <AdvisorInfo advisor={visaPortalStepInfo?.advisor} />
        </div>
        <div className='flex justify-between mb-2 md:flex-col'>
          <div className='flex gap-3 mb-2 md:flex-col'>
            {visaInfoFields?.visaTitle && <h4 className='mr-2 text-2xl font-semibold text-primary'>{visaInfoFields?.visaTitle}</h4>}
            {visaInfoFields?.visaInstruction && (
              <button
                className='btn-secondary py-1 px-4 bg-white font-semibold border-gray-800 w-max'
                title='Visa portal instructions'
                onClick={() => {
                  setViewInstruction({
                    name: visaInfoFields?.visaTitle ?? 'Visa Portal Instructions',
                    instructions: visaInfoFields?.visaInstruction
                  })
                }}
              >
                Read Me First
              </button>
            )}
          </div>
          <div className='btn-primary bg-primary inline-block font-semibold text-white text-center w-96 lg:w-auto uppercase'>
            Status: {visaPortalStepInfo?.visaPortalStatus ?? 'New'}
          </div>
        </div>

        {loading && <Loading />}

        <ConsulateAppointmentBlock
          className='mb-8'
          student={visaPortalStepInfo}
          studentId={studentId}
          loadData={studentInfoLoaded}
          canPerformAction={(showToast, defaultStepInfo) => canPerformAction(showToast ?? true, defaultStepInfo ?? null)}
          onUpdateCallback={(updatedData) => {
            const newData = {
              ...visaPortalStepInfo,
              ...updatedData
            }
            setVisaPortalStepInfo(newData as StudentVisaPortalInfo)
          }}
        />

        {formStepsList &&
          Array.from({ length: Math.ceil(formStepsList.length / 4) }).map((_, rowIndex) => (
            <div key={`visaSubStep_${rowIndex}`} className='grid grid-cols-4 md:grid-cols-1 rounded-md bg-white p-4 mb-3 md:px-1 mt-6'>
              {formStepsList.slice(rowIndex * 4, rowIndex * 4 + 4).map((subStep, index) => {
                return (
                  <div key={`${subStep.name}_${index}`} data-row={rowIndex} data-order={subStep.order}>
                    <div className='text-center px-2 md:py-2'>
                      <div className='relative mb-2 rounded-full mx-auto flex items-center justify-center bg-primary-b w-28 h-28 lg:w-20 lg:h-20'>
                        {subStep?.studentStepInfo?.isApproved && !subStep?.studentStepInfo?.isRecheck ? (
                          <img
                            src={icon?.completeStepIcon}
                            className='w-7 h-7 object-cover lg:w-5 lg:h-5 text-secondary bg-white rounded-full absolute top-2 right-0'
                          />
                        ) : !subStep?.studentStepInfo?.isApproved && subStep?.studentStepInfo?.isRecheck ? (
                          <img
                            src={icon?.recheckStepIcon}
                            className='w-7 h-7 object-cover lg:w-5 lg:h-5 text-secondary bg-white rounded-full absolute top-2 right-0'
                          />
                        ) : (
                          subStep?.studentStepInfo?.isSubmitted && (
                            <img
                              src={icon?.readyToReviewStepIcon}
                              className='w-7 h-7 object-cover lg:w-5 lg:h-5 text-secondary bg-white rounded-full absolute top-2 right-0'
                            />
                          )
                        )}
                        {subStep?.icon ? (
                          <img src={subStep?.icon} alt='Icon' className='size-full rounded-full bg-[rgb(230,228,229)]' />
                        ) : (
                          <img src={noImagePlaceholder} alt='no-Icon' className='size-full rounded-full bg-[rgb(230,228,229)]' />
                        )}
                      </div>
                      <h3 className='text-xl lg:text-base font-medium'>{subStep.name}</h3>
                      <div
                        className='hover:underline cursor-pointer'
                        onClick={() =>
                          setViewInstruction({
                            name: subStep.name + ' Instruction',
                            instructions: subStep.instructions
                          })
                        }
                      >
                        Click for Instructions
                      </div>
                      {!isSubAdvisor(data.roles) &&
                      !(
                        ((subStep?.studentStepInfo?.isSubmitted && !subStep?.studentStepInfo?.isRecheck) ||
                          (subStep?.hasApproved && subStep?.studentStepInfo?.isApproved)) &&
                        isStudent(data.roles)
                      ) ? (
                        <div
                          className='visa-portal-font-bg cursor-pointer hover:underline underline-offset-2'
                          onClick={() => {
                            setActiveFormStepViewOnly(false)
                            setActiveFormStep(subStep)
                          }}
                        >
                          (click to submit/view)
                        </div>
                      ) : (
                        <div
                          className='visa-portal-font-bg cursor-pointer hover:underline underline-offset-2'
                          onClick={() => {
                            setActiveFormStepViewOnly(true)
                            setActiveFormStep(subStep)
                          }}
                        >
                          (click to submit/view)
                        </div>
                      )}
                    </div>

                    {isAdmin(data.roles) && (
                      <div className='flex flex-col items-start mx-auto w-full'>
                        {Boolean(subStep?.canBeDisabled) && (
                          <div className='block cursor-pointer'>
                            <Checkbox
                              id={`step_enabled_${subStep.id}`}
                              checked={subStep?.studentStepInfo?.stepEnabled == true}
                              onChange={(event) => {
                                setToggleAction({
                                  invoke: true,
                                  value: event?.target.checked,
                                  subStepId: subStep.id,
                                  actionColumn: 'stepEnabled'
                                })
                              }}
                            />
                            <label htmlFor={`step_enabled_${subStep.id}`}>Show Icon</label>
                          </div>
                        )}
                        {Boolean(subStep?.hasApproved) && (
                          <div className='block cursor-pointer'>
                            <Checkbox
                              id={`approve_${subStep.id}`}
                              checked={subStep?.studentStepInfo?.isApproved == true}
                              onChange={(event) => {
                                setToggleAction({
                                  invoke: true,
                                  value: event?.target.checked,
                                  subStepId: subStep.id,
                                  actionColumn: 'isApproved'
                                })
                              }}
                            />
                            <label htmlFor={`approve_${subStep.id}`}>Approve</label>
                          </div>
                        )}
                        {Boolean(subStep?.hasApproved) && (
                          <div className='block cursor-pointer'>
                            <Checkbox
                              id={`is_recheck_${subStep.id}`}
                              onChange={(event) => {
                                setToggleAction({
                                  invoke: true,
                                  value: event?.target.checked,
                                  subStepId: subStep.id,
                                  actionColumn: 'isRecheck'
                                })
                              }}
                              checked={subStep?.studentStepInfo?.isRecheck == true}
                            />
                            <label htmlFor={`is_recheck_${subStep.id}`}> Recheck</label>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          ))}

        <NotesFooterBlock
          canPerformAction={(showToast, defaultStepInfo) => canPerformAction(showToast ?? true, defaultStepInfo ?? null)}
          setLoading={setLoading}
          visaPortalStepInfo={visaPortalStepInfo}
          getVisaPortalStepInfo={getVisaPortalStepInfo}
        />
      </div>

      <ConfirmationAlert
        title={'Are you sure you perform this action ?'}
        isConfirmModalOpen={(toggleAction && toggleAction.invoke) ?? false}
        setConfirmModalOpen={(val: boolean) => {
          const prevVal = {
            ...(toggleAction ?? {}),
            invoke: val
          }
          setToggleAction(prevVal as ToggleActionType)
        }}
        ButtonText={'Yes I am sure'}
        onAction={() => handleCheckboxChange()}
      />

      {viewInstruction && (
        <VisaPortalStepInstruction
          name={viewInstruction.name}
          content={viewInstruction.instructions}
          onClose={() => setViewInstruction(null)}
        />
      )}

      {activeFormStep && (
        <VisaPortalStepDetails
          viewOnly={activeFormStepViewOnly}
          studentId={studentId}
          formStep={activeFormStep}
          studentMeta={visaPortalStepInfo?.studentMeta}
          canPerformAction={(showToast, defaultStepInfo) => canPerformAction(showToast ?? true, defaultStepInfo ?? null)}
          onClose={() => setActiveFormStep(null)}
          onUpdate={handleStepDetailUpdate}
        />
      )}
    </>
  )
}
