import { SetStateAction, useContext, useEffect, useState } from 'react'
import { FormStep, Setting } from './type'
import ImageSelector from 'Components/ImageSelector'
import { FaSave, FaSync } from 'react-icons/fa'
import { AppContext } from 'Context/AppContext'
import { AppContextType } from 'Context/AppTypes'
import http from 'CommonJS/http'
import { toast } from 'Components/Toast/toast'
import Loading from 'Components/Loading'

interface ColorData {
  themeColor?: string
  fontColor?: string
}

export default function GlobalSetting() {
  const [formData, setFormData] = useState<FormStep[]>([])
  const [setting, setSetting] = useState<Setting>({})
  const { uploadFile } = useContext(AppContext) as AppContextType
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    getformStep()
  }, [])

  const getformStep = async () => {
    setLoading(true)
    http
      .fetch({ path: 'setting/formStep?globalSetting=global_settings' })
      .catch(() => {
        setLoading(false)
        toast('Internal Server Error.', 'error')
      })
      .then((response) => {
        setLoading(false)
        if (response && response.status === 200) {
          setFormData(response.data.formSteps)
          setSetting(response.data.setting)
        }
      })
  }

  const changeGlobalIconFileState = async (file: SetStateAction<File | undefined>, iconId: number | string) => {
    try {
      await setLoading(true)
      if (file instanceof File && file.type) {
        const fileRes = await uploadFile(file)
        if (fileRes) {
          const iconUpload = await fileRes?.document.id
          if (iconUpload) {
            const step = await formData.find((data) => data?.isDefaultSetting)
            let payload
            if (iconId === 'complete') payload = { complete_step_icon: iconUpload }
            else if (iconId === 're_check') payload = { recheck_step_icon: iconUpload }
            else if (iconId === 'ready_to_review') payload = { ready_to_review_step_icon: iconUpload }

            http
              .fetch({ method: 'put', path: `setting/formStep/${step?.id}`, data: payload })
              .catch((error) => {
                toast(error?.response?.data?.message, 'error')
              })
              .then((response) => {
                if (response && response.status === 200) {
                  getformStep()
                  setLoading(false)
                  toast(response?.data?.message, 'success')
                }
              })
          }
        }
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const handParseGravityForm = () => {
    setLoading(true)

    http
      .fetch({ method: 'post', path: 'setting/parse-gravity-form' })
      .catch((error) => {
        setLoading(false)
        toast(error?.response?.data?.message, 'error')
      })
      .then((response) => {
        setLoading(false)
        if (response && response.status === 200) {
          toast(response?.data?.message, 'success')
        }
      })
  }

  const handleSave = (data: ColorData) => {
    setLoading(true)
    http
      .fetch({ method: 'post', path: 'setting/color-update', data })
      .catch((error) => {
        setLoading(false)
        toast(error?.response?.data?.message, 'error')
      })
      .then((response) => {
        setLoading(false)
        if (response && response.status === 200) {
          toast(response?.data?.message, 'success')
        }
      })
  }
  return (
    <>
      {loading && <Loading />}
      <div className='w-[700px] max-w-[100vw] md:w-full'>
        <div className='flex justify-between items-center min-h-12 mb-7 md:mb-5 '>
          <h1 className='text-3xl font-bold uppercase md:text-2xl'>Global Settings</h1>
        </div>
        <div className=''>
          {formData.map((step, index) => (
            <div
              className='p-4 rounded-md border border-slate-200 shadow-md shadow-primary/10 mt-7 md:p-2'
              key={`${step?.id ?? 'form_step'}_${index}`}
            >
              {/* <h3 className='text-3xl font-bold lg:text-lg'> {step?.name} </h3> */}
              {step.isDefaultSetting && (
                <div className=' contents mt-5'>
                  <div className='flex items-center justify-between max-w-[80%] gap-3'>
                    <label className='text-xl font-medium decoration-1 md:text-base w-1/3- md:w-4/6-'>Complete Step Icon</label>
                    <ImageSelector
                      size={'55px'}
                      imageSrc={setting?.completeStepIcon}
                      changeFileState={changeGlobalIconFileState}
                      id={'complete'}
                    />
                  </div>
                  <div className='flex items-center justify-between max-w-[80%] mt-3 mb-5 gap-3'>
                    <label className='text-xl font-medium decoration-1 md:text-base w-1/3- md:w-4/6-'>Re-Check Step Icon</label>
                    <ImageSelector
                      size={'55px'}
                      imageSrc={setting?.recheckStepIcon}
                      changeFileState={changeGlobalIconFileState}
                      id={'re_check'}
                    />
                  </div>
                  <div className='flex items-center justify-between max-w-[80%] mt-3 mb-5 gap-3'>
                    <label className='text-xl font-medium decoration-1 md:text-base w-1/3- md:w-4/6-'>Ready To Review Step Icon</label>
                    <ImageSelector
                      size={'55px'}
                      imageSrc={setting?.readyToReviewStepIcon}
                      changeFileState={changeGlobalIconFileState}
                      id={'ready_to_review'}
                    />
                  </div>
                  <div className='flex items-center justify-between max-w-[79%] mt-3 mb-5 gap-1'>
                    <label className='text-xl font-medium decoration-1 md:text-base'>Build gravity form advanced conditional logic.</label>
                    <button className='btn-primary uppercase flex gap-3 items-center p-3' onClick={handParseGravityForm}>
                      <FaSync />
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className='flex justify-between items-center min-h-12 mb-7 mt-7 md:mb-5 md:mt-5 '>
          <h1 className='text-3xl font-bold uppercase md:text-2xl'>Visa Portal</h1>
        </div>
        <div className=''>
          <div className='p-4 rounded-md border border-slate-200 shadow-md shadow-primary/10 mt-7 md:p-2'>
            <div className=' contents mt-5'>
              <div className='flex items-center justify-between max-w-[79%] mt-3 mb-5 gap-1'>
                <label className='text-xl font-medium decoration-1 md:text-base'>Theme Color</label>
                <div className='w-10 h-10'>
                  <input
                    className='w-full h-full cursor-pointer'
                    id='themeColor'
                    name='themeColor'
                    type='color'
                    value={setting?.themeColor || '#2d3e48'}
                    onChange={(e) => setSetting((prevSetting) => ({ ...prevSetting, themeColor: e.target.value }))}
                  />
                </div>
                <button
                  className='btn-primary uppercase flex gap-3 items-center p-3'
                  onClick={() => handleSave({ themeColor: setting?.themeColor })}
                >
                  <FaSave />
                </button>
              </div>
              <div className='flex items-center justify-between max-w-[79%] mt-3 mb-5 gap-1'>
                <label className='text-xl font-medium decoration-1 md:text-base'>Font Color</label>
                <div className='w-10 h-10 ml-5'>
                  <input
                    className='w-full h-full cursor-pointer'
                    id='fontColor'
                    name='fontColor'
                    type='color'
                    value={setting?.fontColor || '#000000'}
                    onChange={(e) => setSetting((prevSetting) => ({ ...prevSetting, fontColor: e.target.value }))}
                  />
                </div>
                <button
                  className='btn-primary uppercase flex gap-3 items-center p-3'
                  onClick={() => handleSave({ fontColor: setting?.fontColor })}
                >
                  <FaSave />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
